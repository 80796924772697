import appApi from "./index";

const endpoint = "dashboard";

const index = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getSales = (url = null, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/get-sales`, {
        startDate, endDate
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getSalesTable = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-sales-table`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getMonthlyStatus = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-monthly-status`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getSalesReport = (url = null, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/get-sales-report`, {
        startDate, endDate
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getPayment = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-payment`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getUnPayment = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-unpaid-payment`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getBulkSales = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-bulk-sales`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

export default {
  index,
  getSales,
  getSalesTable,
  getMonthlyStatus,
  getSalesReport,
  getPayment,
  getBulkSales,
  getUnPayment
};
