import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';

export default function index() {
    const antIcon = <LoadingOutlined style={{ fontSize: '1.2rem', color: '#000' }} spin />;
    return (
        <Row align="middle" justify="center" gutter={[24, 0]}>
            <Spin indicator={antIcon} />
        </Row>
    )
}
