import React, { useState } from "react";
import { RedoOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Progress, Row, Select, message } from "antd";
import VideoRecorder from "react-video-recorder";
import { useLocation } from "react-router-dom";

import EvidenceApi from "../../apis/order-management/EvidenceApi";
import { getValidOrderType } from "../../helpers/getBaseUrlByOrderId";

function getSupportedMimeTypes() {
    const possibleTypes = [
        'video/webm;codecs=av1,opus',
        'video/webm;codecs=vp9,opus',
        'video/webm;codecs=vp8,opus',
        'video/webm;codecs=h264,opus',
        'video/mp4;codecs=h264,aac',
    ];
    return possibleTypes.filter((mimeType) => {
        return MediaRecorder.isTypeSupported(mimeType);
    });
}

export default function VideoRecordable({ id, baseUrl }) {
    const [selectedFormat, setSelectedFormat] = useState(null);

    const [recordObj, setRecordObj] = useState({
        videoBlobObj: null,
        uploadProgress: 0,
        isLoading: false
    });

    const { pathname } = useLocation();

    // Function to extract file extension from MIME type
    function extractFileExtension(mimeType) {
        // Extracts everything after 'video/' and before the first semicolon if present
        const result = mimeType.match(/video\/([^;]+)/);
        if (result && result[1]) {
            // Assumes the first part of the MIME type is the file extension
            return result[1];
        }
        return 'unknown';
    }

    return (
        <Row
            justify="center"
            align="middle"
        >
            <Col
                span={24}
                className="d-inline-flex align-center mb-4"
            >
                <Select
                    defaultValue={getSupportedMimeTypes()[0]}
                    style={{ width: 280 }}
                    onChange={(value) => {
                        setSelectedFormat(value);
                    }}
                    options={getSupportedMimeTypes().map(item => ({ value: item, label: item }))}
                />
            </Col>
            <Col
                span={24}
                className="d-inline-flex justify-center align-center"
            >
                <div style={{ width: "100%", maxWidth: 1200, height: 680 }}>
                    <VideoRecorder
                        isOnInitially
                        isReplayingVideo
                        showReplayControls
                        isFlipped={false}
                        countdownTime={1000}
                        timeLimit={60000 * 3}
                        onRecordingComplete={(videoBlob) => {
                            setRecordObj(prevRecordObj => ({
                                ...prevRecordObj,
                                videoBlobObj: videoBlob
                            }));
                        }}
                        onCameraOn={() => {
                            setRecordObj(prevRecordObj => ({
                                ...prevRecordObj,
                                uploadProgress: 0,
                                videoBlobObj: null
                            }));
                        }}
                        mimeType={selectedFormat}
                        constraints={{
                            audio: false,
                            video: true
                        }}
                        dataAvailableTimeout={5000}
                    />
                </div>
            </Col>
            <Col span={24} className="justify-center align-center">
                {recordObj.uploadProgress > 0 && <div className='pt-4 px-6 mx-auto'><Progress percent={recordObj.uploadProgress} /></div>}
            </Col>
            {recordObj.videoBlobObj &&
                <Col span={24} className="d-inline-flex justify-center align-center mt-4">
                    <Button
                        className="mr-2"
                        size='large'
                        type="dashed"
                        icon={<RedoOutlined />}
                        onClick={() => setRecordObj(prevRecordObj => ({ ...prevRecordObj, videoBlobObj: null }))}
                    />
                    <Button
                        type="primary"
                        size='large'
                        icon={<UploadOutlined />}
                        onClick={async () => {
                            setRecordObj(prevRecordObj => ({
                                ...prevRecordObj,
                                isLoading: true,
                            }));

                            // const newFile = new File([recordObj.videoBlobObj], 'video-title.mp4', {
                            //     type: 'video/mp4'
                            // })
                            // var blob = new Blob([recordObj.videoBlobObj], { type: 'video/mp4' });

                            // const formData = new FormData();
                            // formData.append('file', recordObj.videoBlobObj);

                            // EvidenceApi.uploadOne(baseUrl, id, formData, (progressEvent) => {
                            //     setRecordObj(prevRecordObj => ({
                            //         ...prevRecordObj,
                            //         uploadProgress: 0,
                            //     }));
                            //     const { loaded, total } = progressEvent;
                            //     const percentage = Math.floor((loaded * 100) / total);
                            //     setRecordObj(prevRecordObj => ({
                            //         ...prevRecordObj,
                            //         uploadProgress: percentage,
                            //     }));
                            // })
                            //     .then(response => {
                            //         if (response.success) {
                            //             message.success(`Files uploaded successfully.`);
                            //         } else {
                            //             message.error(`Files upload failed.`);
                            //         }

                            //         setRecordObj(prevRecordObj => ({
                            //             ...prevRecordObj,
                            //             isLoading: false,
                            //         }));
                            //     })
                            //     .catch(error => {
                            //         message.error(`file upload failed.`);
                            //         setRecordObj(prevRecordObj => ({
                            //             ...prevRecordObj,
                            //             isLoading: false,
                            //         }));
                            //     });

                            try {
                                let orderType = getValidOrderType(pathname.split('/')[1]);
                                if (!orderType) {
                                    return;
                                }

                                const fileType = extractFileExtension(recordObj.videoBlobObj.type);

                                const { data: responseData, success: successOfPreSignUrl } = await EvidenceApi.generatePresignedUrl(baseUrl, orderType, id, fileType);
                                if (successOfPreSignUrl) {
                                    const { url } = responseData;
                                    const response = await EvidenceApi.uploadToS3(url, recordObj.videoBlobObj, (progressEvent) => {
                                        setRecordObj(prevRecordObj => ({
                                            ...prevRecordObj,
                                            uploadProgress: 0,
                                        }));
                                        const { loaded, total } = progressEvent;
                                        const percentage = Math.floor((loaded * 100) / total);
                                        setRecordObj(prevRecordObj => ({
                                            ...prevRecordObj,
                                            uploadProgress: percentage,
                                        }));
                                    });
                                    if (response) {
                                        message.success(`File uploaded successfully.`);
                                    } else {
                                        message.error(`File upload failed.`);
                                    }
                                } else {
                                    message.error('Temporary upload url failed.');
                                }

                                setRecordObj(prevRecordObj => ({
                                    ...prevRecordObj,
                                    isLoading: false,
                                }));
                            }
                            catch (error) {
                                message.error('Temporary upload url failed, Something went wrong.');
                                setRecordObj(prevRecordObj => ({
                                    ...prevRecordObj,
                                    isLoading: false,
                                }));
                            }
                        }}
                        loading={recordObj.isLoading}
                    />
                </Col>
            }
        </Row>
    )
}