import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, message, Drawer, Typography, Card, List } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import { getAllOrderStatus } from '../../Orders/util/common';
import BulkRecyleOrderApi from '../../../apis/order-management/BulkRecyleOrderApi';
import ListItem from '../../Orders/Components/ListItem';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeBulkSales, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const baseUrl = useSelector((state) => state.auth.base_url)

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('sales_status', values.status);

            try {

                const updateBulkSales = await BulkRecyleOrderApi.updateSales(baseUrl, activeBulkSales.id, formData);
                if (updateBulkSales) {
                    message.success('Bulk Sales is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeBulkSales.id]);

        try {
            const deleteBulkSales = await BulkRecyleOrderApi.deleteSales(baseUrl, formData);
            if (deleteBulkSales) {
                message.success('Bulk Sales is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeBulkSales.userOrName} - ${activeBulkSales.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }



    return (
        <Drawer
            push={false}
            key="bulk-sales-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeBulkSales?.userOrName}</Col>
                    <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={750}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeBulkSales && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    status: activeBulkSales?.sales_status ? parseInt(activeBulkSales.sales_status) : null,
                }}
            >
                <Row gutter={24} className='mb-5'>
                    <Col span={12}>
                        <Card>
                            <div className='d-flex'>
                                <Form.Item name="status" className='w-100' label="Order Status" rules={[{ required: true }]} labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}>
                                    <Select
                                        optionLabelProp="children"
                                    >
                                        {getAllOrderStatus.filter(item => [1, 2, 3, 7, 8].includes(item.value)).map((item, idx) => (
                                            <Option key={idx} value={item.value}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Button
                                    className='ml-3'
                                    form='delivery-update'
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Update
                                </Button>
                            </div>
                        </Card>
                    </Col>

                </Row>
                <Row gutter={16} >
                    <Col span={12}>
                        <List
                            size="small"
                            header={<Text>Bulk Recycle Details</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Contact Name : "
                                    valueText={`${activeBulkSales?.contact_name ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={1}
                                    keyText="Organisation Name : "
                                    valueText={`${activeBulkSales?.organisation_name ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={2}
                                    keyText="Email : "
                                    valueText={`${activeBulkSales?.email ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={3}
                                    keyText="Phone Number : "
                                    valueText={`${activeBulkSales?.phone_number ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={4}
                                    keyText="Type of Organization : "
                                    valueText={`${activeBulkSales?.type_of_organization ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={5}
                                    keyText="Interested : "
                                    valueText={`${activeBulkSales?.interested}`}
                                    valueCopyable
                                />,
                                <ListItem key={6}
                                    keyText="No. of devices : "
                                    valueText={`${activeBulkSales?.no_of_devices}`}
                                    valueCopyable
                                />,
                                <ListItem key={7}
                                    keyText="Details : "
                                    valueText={`${activeBulkSales?.details}`}
                                    valueCopyable
                                />,
                                <ListItem key={8}
                                    keyText="Created At : "
                                    valueText={`${activeBulkSales?.created_at ? dayjs(activeBulkSales?.created_at).format("DD-MM-YYYY") : "-"}`}
                                />,
                                <ListItem key={9}
                                    keyText="Updated At : "
                                    valueText={`${activeBulkSales?.updated_at ? dayjs(activeBulkSales?.updated_at).format("DD-MM-YYYY") : "-"}`}
                                />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                </Row>
            </Form>
        </Drawer>
    )
}
