import { ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Modal, Row, Skeleton, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from "../../Orders.module.scss";

import GroupOptions from './GroupOptions';
import MoreInfo from './MoreInfo';
import OrderItems from './OrderItems';
import OrderUpdate from './OrderUpdate';
import DrawerWrapper from './DrawerWrapper';
import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';
import ReceiptPrint from '../../../../components/ReceiptPrint';
import DeviceChecker from '../DeviceChecker';
import UpdatePayment from './UpdatePayment';

const { Text } = Typography;
const { confirm } = Modal;

export default function QuickEdit({ visible, handleHide, handleCancel, handleReset, isNavigable, loadingOrder, setKey, notificationTemplate, baseUrl, roles, activeOrderId, employees, services }) {
    const [modelLoading, setModelLoading] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [activeKey, setActiveKey] = useState(0);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [visiblePayment, setVisiblePayment] = useState(false);
    const [visibleDeviceChecker, setVisibleDeviceChecker] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancelModel = () => {
        setIsModalVisible(false);
    };

    const fetchOrder = async () => {
        setModelLoading(true);
        try {
            const order = await DirectOrdersApi.getOne(baseUrl, activeOrderId);
            setActiveOrder(order);
            setModelLoading(false);
        } catch (error) {
            setModelLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [activeKey]);

    const [loading, setLoading] = useState(false);

    const handleDeleteUser = async (item) => {
        setLoading(true);
        try {
            await DirectOrdersApi.deleteOne(baseUrl, item.id);
            message.success(`${item.order_no} Order is deleted`);
            setLoading(false);
            setKey(prevState => prevState + 1);
            handleCancel()
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    }

    const showConfirm = (item) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${item.order_no}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser(item);
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }


    return (
        <>
            <Drawer
                push={false}
                key="order-details-model"
                title={<Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                        <Text># {activeOrder?.order_no}</Text>

                        <>
                            {/* Add button for receipt print */}
                            <Button
                                onClick={showModal}
                                type='primary'
                                className='mr-3'>
                                <PrinterOutlined /> Receipt
                            </Button>

                            {/* <Button onClick={() => setVisibleDeviceChecker(true)} className='mr-3'>Print Label</Button> */}
                        </>
                    </Col>
                </Row>}
                open={visible}
                onClose={handleCancel}
                confirmLoading={modelLoading}
                onCancel={handleCancel}
                afterClose={handleCancel}
                centered
                destroyOnClose
                width={1000}
                footer={(
                    <Row
                        key="row"
                        align="middle"
                        justify="space-between"
                    >
                        <Col>
                            {(activeOrder && (hasRole('admin') || hasRole('super-admin'))) &&
                                <Button
                                    key="submit"
                                    danger
                                    loading={loading}
                                    onClick={() => showConfirm(activeOrder)}
                                >
                                    Delete
                                </Button>
                            }
                        </Col>

                    </Row>
                )}
            >
                {loadingOrder && <Skeleton loading={loadingOrder} avatar paragraph={{ rows: 8 }} />}

                {activeOrder &&
                    <div>
                        <Row gutter={16} className={styles.model_card_row}>
                            <Col span={8}>
                                <OrderUpdate
                                    activeOrder={activeOrder}
                                    setKey={setKey}
                                    handleReset={handleReset}
                                    isNavigable={isNavigable}
                                    baseUrl={baseUrl}
                                    setVisiblePayment={setVisiblePayment}
                                    showReceiptPrintModal={showModal}
                                    handleCancelModel={handleCancelModel}
                                />
                            </Col>
                            <Col span={16}>
                                <GroupOptions
                                    activeOrder={activeOrder}
                                    baseUrl={baseUrl}
                                    activeOrderId={activeOrderId}
                                    services={services}
                                    setKey={setKey}
                                />
                            </Col>
                        </Row>

                        <DrawerWrapper
                            activeOrder={activeOrder}
                            baseUrl={baseUrl}
                            handleReset={handleReset}
                            employees={employees}
                            notificationTemplate={notificationTemplate}
                        />
                        <MoreInfo
                            activeOrder={activeOrder}
                            baseUrl={baseUrl}
                            setActiveOrder={setActiveOrder}
                            setActiveKey={setActiveKey}
                            setVisiblePayment={setVisiblePayment}
                        />
                        <OrderItems
                            setKey={setKey}
                            setActiveKey={setActiveKey}
                            activeOrder={activeOrder}
                        />
                    </div>
                }
            </Drawer>

            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrder?.id}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }

            {visiblePayment &&
                <UpdatePayment
                    visible={visiblePayment}
                    handleCancel={() => setVisiblePayment(false)}
                    activeOrderId={activeOrder?.id}
                    setActiveKey={setActiveKey}
                    setKey={setKey}
                    activeOrder={activeOrder}
                    showReceiptPrintModal={showModal}
                />
            }

            {isModalVisible &&
                <ReceiptPrint
                    activeOrder={activeOrder}
                    isModalVisible={isModalVisible}
                    showModal={showModal}
                    handleCancel={handleCancelModel}
                    btnEnable={true}
                />
            }
        </>
    )
}
