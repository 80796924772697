import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  message
} from "antd";
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from '../Notifications.module.scss';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getAllWFCStatus } from '../../Orders/util/common';
import NotificationTemplateApi from '../../../apis/order-management/NotificationTemplateApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};


export default function CreateEditForm({ location, title, history }) {
  const [form] = Form.useForm();
  useDocumentTitle(title);
  const validateMessages = {
    required: '${label} is required!',
  };


  const [formOptions, setFormOptions] = useState({
    sources: [
      { value: 'order', label: 'Order' },
      { value: 'recycle', label: 'Recycle' },
    ],
    status: [
      { value: 'new', label: 'New' },
      { value: 'open', label: 'Open' },
      { value: 'pending', label: 'Pending' },
      { value: 'hold', label: 'Hold' },
      { value: 'solved', label: 'Solved' },
    ],
    postcodes: null
  });
  const [loading, setLoading] = useState(false)

  const baseUrl = useSelector((state) => state.auth.base_url)


  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append('type', values.source);
      formData.append('from', values.from);
      formData.append('scenario', values.scenario);
      formData.append('instruction_for', values.instruction_for ? values.instruction_for : null);
      formData.append('subject', values.subject ? values.subject : null);
      formData.append('message', values.message);
      formData.append('wfc_status', values.wfc_status);
      formData.append('wfc_note', values.wfc_note ? values.wfc_note : null);
      formData.append('status', values.status);
      formData.append('editable', values.editable);
      formData.append('allow_attachments', values.allow_attachments);
      formData.append('reasons', values.reasons ? JSON.stringify(values.reasons) : null);
      formData.append('id', null);

      try {
        const createNotification = await NotificationTemplateApi.createNotification(baseUrl, formData);
        if (createNotification) {
          message.success('Notification is created!');
          form.resetFields();
        }
      } catch (error) {
        message.error('Something went wrong!');
      }
      onReset();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong!');
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const mailFrom = useMemo(() => {
    // get host without from base url
    const host = new URL(baseUrl).host;
    return `info@${host}.co.uk`;
  }, [baseUrl]);

  return (
    <>
      <div className="tabled">
        <Row gutter={[8, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">
              <PageHeader
                ghost={false}
                className="site-page-header"
                onBack={() => history.goBack()}
                title={`${location.state ? "Edit" : "Add New"} Notification`}
              />
              <Card
                bordered={false}
                className={`criclebox tablespace mb-24 ${styles.space}`}
              >
                <Form
                  {...layout}
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    allow_attachments: false,
                    editable: true,
                  }}
                >
                  <Row gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item name="source" label="Type of Notification" labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }} rules={[{ required: true }]} initialValue={formOptions.sources[0].value}>

                        <Select
                          optionLabelProp="children"
                        >
                          {(formOptions.sources).map((item, idx) => (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="from" label="From" rules={[{ required: true }]}
                        initialValue={mailFrom}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="status" label="Status" rules={[{ required: true }]} initialValue={formOptions.status[0].value}>
                        <Select
                          optionLabelProp="children"
                        >
                          {(formOptions.status).map((item, idx) => (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                          ))}
                        </Select>


                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="wfc_status" label="WFC Status" rules={[{ required: true }]}
                        initialValue={getAllWFCStatus.slice(1)[0].value}>
                        <Select
                          optionLabelProp="children"
                        >
                          {(getAllWFCStatus.slice(1)).map((item, idx) => (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="scenario" label="Scenario" rules={[{ required: true }]} >
                        <Input placeholder="Scenario" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="instruction_for" label="Instruction for (Optional)" labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}  >
                        <Input placeholder="Instruction for" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="editable" valuePropName="checked" initialValue={true}>
                        <Checkbox>
                          Is Editable Content? (Optional)
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="allow_attachments" valuePropName="checked" >
                        <Checkbox>
                          Allow Attachments? (Optional)
                        </Checkbox>
                      </Form.Item>
                    </Col>


                    <Col span={24}>
                      <Form.Item name="subject" label="Subject (Optional)"   >
                        <Input placeholder="Subject" />
                      </Form.Item>
                    </Col>


                    <Col span={24} className='mb-5'>
                      <Form.Item name="message" label="Message" rules={[{ required: true }]}>
                        <Input.TextArea placeholder="Message" rows={6} />
                      </Form.Item>
                      <p className="form-text">
                        If you plan to include reasons, kindly paste the <span >@_type_the_reason_inside_@</span> text in the designated field. The 'Reasons' field is optional.
                        <br />
                        Example:<br />
                        "I believe that <br />
                        <span
                          className="bg-light">@_type_the_reason_inside_@</span><br />because..."
                      </p>
                    </Col>



                    <Col span={24}>
                      <Form.Item name="wfc_note" label="WFC Note (Optional)">
                        <Input.TextArea placeholder="WFC Note" rows={6} />
                      </Form.Item>

                    </Col>

                    <Col span={14}>
                      <span className='mb-4'>Reasons (Optional)</span>
                      <Form.List
                        name="reasons"
                        initialValue={[{ reason: '' }]}
                        rules={[
                          {
                            validator: async (_, names) => {
                              if (!names) {
                                return Promise.reject(new Error('At least 1 reasons'));
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <Form.Item
                                {...(fields.length === 0 ? { label: 'Reasons' } : {})}
                                label={fields.length === 0 ? 'Reasons' : ''}
                                required={false}
                                key={key}
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, 'reason']} // Access the 'reason' field
                                  fieldKey={[fieldKey, 'reason']}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Please input reason's name or delete this field.",
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    placeholder="Enter the reason"
                                    style={{ width: '60%' }}
                                  />
                                </Form.Item>
                                {fields.length > 1 && (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{ fontSize: '20px', marginLeft: '8px' }}
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '40%' }}
                                icon={<PlusOutlined />}
                              >
                                Add reason
                              </Button>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>


                  </Row>


                  <Form.Item label=" " wrapperCol={{ ...layout.wrapperCol }}>
                    <Button
                      className={styles.submitBtn}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>

                    <Link to="/notifications">
                      <Button>
                        Cancel
                      </Button>
                    </Link>
                  </Form.Item>
                </Form>

              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}