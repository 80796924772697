import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import ReasonApi from '../../../apis/order-management/ReasonApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeReason, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (activeReason) {
            form.setFieldsValue({
                title: activeReason?.title,
                description: activeReason?.description,
                optionType: activeReason?.type,
            });
        }
    }, [activeReason, form]);

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [formOptions, setFormOptions] = useState({
        type: [
            { value: 0, label: 'Customer Fault' },
            { value: 1, label: 'Company Fault' },
        ]
    });


    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('optionType', values.optionType);

            if (activeReason) {
                formData.append('id', activeReason?.id);
            }

            try {

                const updateReason = await ReasonApi.updateReason(baseUrl, formData);
                if (updateReason) {
                    message.success('Reason is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteReason = async () => {

        try {
            const deleteLogs = await ReasonApi.deleteOne(baseUrl, activeReason.id);
            if (deleteLogs) {
                message.success('Reason is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteReason();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeReason?.userOrName}</Col>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>

                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeReason ? "Update" : 'Create'}
                        </Button>
                    </div>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={800}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeReason && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>


                    <Col span={8}>
                        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="optionType" label="Fault Option" rules={[{ required: true }]} >
                            <Select
                                optionLabelProp="children"
                            >
                                {(formOptions.type).map((item, idx) => (
                                    <Option key={idx} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>


                        </Form.Item>
                    </Col>



                </Row>
            </Form>
        </Drawer>
    )
}
