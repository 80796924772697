import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { GoogleGenerativeAI } from '@google/generative-ai';

const { Text, Paragraph } = Typography;

const GeminiAiField = ({ content, setContent, setTimestamp }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

    const onFinish = async (values) => {
        try {
            setLoading(true);

            const model = genAI.getGenerativeModel({ model: 'gemini-pro' });
            const chat = model.startChat({
                history: content
            });
            const result = await chat.sendMessage(values.content);
            const modelResponse = await result.response;
            const text = modelResponse.text();
            setLoading(false);
            setContent([
                ...content,
                { role: 'user', parts: values.content },
                { role: 'model', parts: text }
            ]);
            setTimestamp(new Date().getTime() / 1000);
            form.setFieldsValue({ content: '' });
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            initialValues={{ content: "Write an email content for the price list" }}
            onFinish={onFinish}
        >
            <Form.Item>
                <Row gutter={8} justify="space-between" align="top">
                    <Col span={18}>
                        <Form.Item name="content" style={{ margin: 0 }}>
                            <Input.TextArea
                                autoSize={{ minRows: 2 }}
                                onPressEnter={(e) => {
                                    if (e.keyCode == 13 && !e.shiftKey) {
                                        form.submit(e);
                                    }
                                }}
                                disabled={loading}
                            />
                        </Form.Item>
                        <Paragraph type="secondary" className='text-right mb-0'>Powered by <Text strong>Gemini Ai</Text></Paragraph>
                    </Col>
                    <Col span={6}>
                        <Button
                            htmlType="submit"
                            loading={loading}
                        >
                            Compose the content
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default GeminiAiField;
