import appApi from "../index";
import qs from 'qs';

const endpoint = "orders";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOneWithOrderRepair = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-one/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getStatusCounts = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-status/counts`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const update = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const updateOne = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/notification/update/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const ItemsChecker = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/items-checker/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendUnpaidMail = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/notification/unpaid-mail`, { params: { id } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendResendOrderConfirmation = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/notification/resend-order-confirm`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const generateInvoiceByOrderId = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/generate-invoice-by-order/${id}`, null, {
        headers: { 'content-type': 'application/json' },
        responseType: 'blob'
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      }
      );
  });
}

const generateInvoice = (url = null, values) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/generate-invoice`, values, {
        headers: { 'content-type': 'application/json' },
        responseType: 'blob'
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      }
      );
  });
};

const sendInvoice = (url = null, values) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/send-invoice`, values, {
        headers: { 'content-type': 'application/json' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      }
      );
  });
};

const exportDelivery = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/export/delivery`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const postcodeCheck = (url = null, postcode) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/postcode/check`, { params: { postcode } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const royalMailOrder = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/royal-mail/order`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};
const dpdOrder = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/dpd-mail/order`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getRoyalMailOrder = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/royal-mail/order/${id}/tracking`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const paymentDetails = (url = null, pay_id, pay_type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/payment-details`, { payment_id: pay_id, payment_type: pay_type })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};

const paymentCaptureInKlarna = (url = null, pay_id, remaining_authorized_amount) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/payment-capture/klarna`, { payment_id: pay_id, remaining_authorized_amount })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};



const updateField = (url = null, order_id, requestBody) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/order-update`, { orderId: order_id, ...requestBody })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

export default {
  list,
  getOne,
  getOneWithOrderRepair,
  getStatusCounts,
  update,
  updateOne,
  ItemsChecker,
  deleteOne,
  sendResendOrderConfirmation,
  generateInvoiceByOrderId,
  generateInvoice,
  sendInvoice,
  sendUnpaidMail,
  exportDelivery,
  postcodeCheck,
  royalMailOrder,
  dpdOrder,
  getRoyalMailOrder,
  paymentDetails,
  updateField,
  paymentCaptureInKlarna
};
