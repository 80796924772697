import appApi from "./index";
import { API_ENDPOINT1 } from "../constants/varibales";

const endpoint = "employee"

const EmployeeApi = {
    list: (url = API_ENDPOINT1) => {
        return new Promise((resolve, reject) => {
            appApi(url)
                .get(`/${endpoint}/public/list`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        reject(error.response.data);
                    } else if (error.request) {
                        reject(error);
                    } else {
                        reject(error);
                    }
                });
        })
    },
};

export default EmployeeApi;