import React from 'react'
import { Tag } from 'antd'

export default function StatusBadge({ value, color, label, ...rest }) {
  if (value === null) return "-";

  return (
    <>
      <Tag color={color} {...rest}>{label}</Tag>
    </>
  )
}
