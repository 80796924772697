import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, message, Drawer, Card, List, Typography } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import { getAllOrderStatus } from '../../Orders/util/common';
import BulkRepairOrderApi from '../../../apis/order-management/BulkRepairOrderApi';
import ListItem from '../../Orders/Components/ListItem';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const { Text } = Typography;
const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeBulkRepair, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const baseUrl = useSelector((state) => state.auth.base_url)

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('sales_status', values.status);

            try {

                const updateBulkSales = await BulkRepairOrderApi.updateSales(baseUrl, activeBulkRepair.id, formData);
                if (updateBulkSales) {
                    message.success('Bulk Sales is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeBulkRepair.id]);

        try {
            const deleteBulkSales = await BulkRepairOrderApi.deleteSales(baseUrl, formData);
            if (deleteBulkSales) {
                message.success('Bulk Sales is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeBulkRepair.userOrName} - ${activeBulkRepair.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="bulk-repair-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeBulkRepair?.userOrName}</Col>
                    <Button key="cancel" onClick={handleCancel}>Cancel</Button>

                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={750}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeBulkRepair && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="bulk-repair-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    status: activeBulkRepair?.sales_status ? parseInt(activeBulkRepair.sales_status) : null,
                }}
            >
                <Row gutter={24} className='mb-5'>
                    <Col span={12}>
                        <Card>
                            <div className='d-flex'>
                                <Form.Item name="status" className='w-100' label="Order Status" rules={[{ required: true }]} labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}>
                                    <Select
                                        optionLabelProp="children"
                                    >
                                        {getAllOrderStatus.filter(item => [1, 2, 3, 7, 8].includes(item.value)).map((item, idx) => (
                                            <Option key={idx} value={item.value}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Button
                                    className='ml-3'
                                    form='delivery-update'
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Update
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} >
                    <Col span={12}>
                        <List
                            size="small"
                            header={<Text>Bulk Repair Details</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Full Name : "
                                    valueText={`${activeBulkRepair?.name}`}
                                    valueCopyable
                                />,
                                <ListItem key={1}
                                    keyText="Institution Name : "
                                    valueText={`${activeBulkRepair?.institution_name}`}
                                    valueCopyable
                                />,
                                <ListItem key={2}
                                    keyText="Email : "
                                    valueText={`${activeBulkRepair?.email}`}
                                    valueCopyable
                                />,
                                <ListItem key={3}
                                    keyText="Phone Number : "
                                    valueText={`${activeBulkRepair?.phone_number}`}
                                    valueCopyable
                                />,
                                <ListItem key={4}
                                    keyText="No. of devices : "
                                    valueText={`${activeBulkRepair?.no_of_devices}`}
                                    valueCopyable
                                />,
                                <ListItem key={5}
                                    keyText="Created At : "
                                    valueText={`${activeBulkRepair?.created_at ? dayjs(activeBulkRepair?.created_at).format("DD-MM-YYYY") : "-"}`}
                                />,
                                <ListItem key={6}
                                    keyText="Updated At : "
                                    valueText={`${activeBulkRepair?.updated_at ? dayjs(activeBulkRepair?.updated_at).format("DD-MM-YYYY") : "-"}`}
                                />,

                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                </Row>
            </Form>
        </Drawer>
    )
}
