import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export function ConfirmModal({ title, content, onOk, onCancel, okText = 'Yes', okType = 'danger', cancelText = 'No', ...rest }) {
  return new Promise((resolve, reject) => {
    confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      content,
      async onOk() {
        if(onOk) {
          await onOk();
        }
        resolve();
      },
      onCancel() {
        if(onCancel) {
          onCancel();
        }
        reject();
      },
      okText,
      okType,
      cancelText,
      ...rest
    });
  });
}
