import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, message, Drawer, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import UserApi from '../../../apis/UserApi';

dayjs.extend(utc)
dayjs.extend(timezone)
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickRoleCreate({ visibleRoleCreate, handleCancel, activeRole, handleReset, roles, setIndex }) {

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form2] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinishRole = async (values) => {
        try {

            const formData = new FormData();
            formData.append('name', values.name);

            if (activeRole?.id) {
                formData.append('id', activeRole?.id);
            }

            try {

                const deleteRole = await UserApi.createRole(baseUrl, formData);
                if (deleteRole) {
                    message.success('Role is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form2.resetFields();
                    handleReset();
                    handleCancel();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeRole?.id]);

        try {
            const deleteRole = await UserApi.deleteRole(baseUrl, formData);
            if (deleteRole) {
                message.success('Role is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form2.resetFields();
                handleReset();
                handleCancel();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item Delete`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    const handleClear = () => {

        form2.setFieldsValue({
            title: null,
        });
    };

    useEffect(() => {
        if (activeRole) {
            form2.setFieldsValue({
                name: activeRole?.name,
            });
        }
    }, [activeRole, form2]);

    return (
        <Drawer
            push={false}
            key="role-details-model"
            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Role Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleClear}>Clear</Button>
                        <Button
                            className='mx-2'
                            form='role-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeRole?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}

            open={visibleRoleCreate}
            onClose={handleCancel}
            onCancel={handleCancel}
            placement="left"
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={750}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {activeRole?.id &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form2}
                id="role-update"
                name="nest-messages"
                onFinish={onFinishRole}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Row gutter={[24, 0]}>
                            <Col span={10}>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Name' />
                                </Form.Item>
                            </Col>


                        </Row>
                    </Col>
                </Row>

            </Form>
        </Drawer>
    )
}
