import { Button, Card, Col, Modal, Row, Space, message } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import React, { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { MdContentCopy, MdDeleteForever } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import styles from './MediaList.module.scss';

import { ArrowsAltOutlined, ExclamationCircleOutlined, FieldTimeOutlined } from '@ant-design/icons';
import EvidenceApi from '../../apis/order-management/EvidenceApi';
import { copyToClipboard } from '../../util/helpers';
import Icon from '../Icon';
import LazyImage from '../LazyImage';
import MediaResourceViewer from '../MediaResourceViewer';
import { getValidOrderType } from '../../helpers/getBaseUrlByOrderId';

const VideoImage = require('../../assets/images/media-items/play.png');
const AudioImage = require('../../assets/images/media-items/sound.png');
const DocumentImage = require('../../assets/images/media-items/google-docs.png');

const { Meta } = Card;

const { confirm } = Modal;

dayjs.extend(relativeTime);

export default function MediaList({ id, baseUrl }) {
    let { pathname } = useLocation();
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [key, setKey] = useState(0);
    console.log(loading, data, key);

    const [activeData, setActiveData] = useState(null);

    useEffect(() => {
        (async () => {
            let orderType = getValidOrderType(pathname.split('/')[1]);
            if (!orderType) {
                return;
            }

            setLoading(true);
            const evidence = await EvidenceApi.getFiles(baseUrl, orderType, id);
            if (evidence && evidence.length > 0) {
                evidence.sort((a, b) => {
                    return new Date(b.last_modified) - new Date(a.last_modified);
                });
                setData(evidence);
                setLoading(false);
            }
        })();
    }, [key, id]);

    function renderThumbnailMedia(type, url, cb) {
        switch (type) {
            case 'image':
                return (
                    <LazyImage
                        src={url}
                        placeholder={require('../../assets/images/media-items/transparent.png')}
                        alt={type}
                        width="300px"
                        height="120px"
                        className={styles.imageItem}
                    />
                );
            case 'video':
                return (
                    <img
                        src={VideoImage}
                        alt={type}
                        className={styles.imageIcon}
                        onClick={cb}
                    />
                );
            case 'audio':
                return (
                    <img
                        src={AudioImage}
                        alt={type}
                        className={styles.imageIcon}
                        onClick={cb}
                    />
                );
            case 'document':
                return (
                    <img
                        src={DocumentImage}
                        alt={type}
                        className={styles.imageIcon}
                        onClick={cb}
                    />
                );
            default:
                return (
                    <img
                        src={`https://placehold.co/800?text=${type}&font=roboto`}
                        alt={type}
                        className={styles.imageItem}
                        onClick={cb}
                    />
                );
        }
    }

    const showConfirmDeleteAll = () => {
        confirm({
            title: 'Are you sure delete this directory?',
            icon: <ExclamationCircleOutlined />,
            content: `${id}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                let orderType = getValidOrderType(pathname.split('/')[1]);
                if (!orderType) {
                    return;
                }

                const deleteList = await EvidenceApi.deleteDirectory(baseUrl, orderType, id)
                if (deleteList) {
                    messageApi.open({
                        type: 'success',
                        content: 'A Directory has been deleted!',
                    });
                    setKey(prevKey => prevKey + 1);
                    setActiveData(null)
                }
            },
            onCancel() {
                //
            },
        });
    }

    const showConfirmDeleteOne = (item) => {
        confirm({
            title: 'Are you sure delete this file?',
            icon: <ExclamationCircleOutlined />,
            content: `${item.title}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const deleteList = await EvidenceApi.deleteFile(baseUrl, { file: item.path })
                if (deleteList) {
                    messageApi.open({
                        type: 'success',
                        content: 'A File has been deleted!',
                    });
                    setKey(prevKey => prevKey + 1);
                    if (activeData && (activeData.path === item.path)) {
                        setActiveData(null);
                    }
                }
            },
            onCancel() {
                //
            },
        });
    }

    const videoUrlByBaseUrl = (url, type) => {
        const siteUrl =  baseUrl.split('/api')[0];
        return type === 'video' ? `${siteUrl.split('/api')[0]}/video-assets?url=${url}` : url;
    }

    return (
        <>
            {contextHolder}
            <Row>
                <Col span={24}>
                    {data &&
                        data.length > 1 &&
                        <Button
                            size='small'
                            danger
                            className="float-right mb-4 p-1"
                            onClick={() => showConfirmDeleteAll()}
                        >
                            Delete All
                        </Button>
                    }
                </Col>

                <Col span={24}>
                    {activeData &&
                        <MediaResourceViewer
                            title={
                                <Space className='space-between'>
                                    {activeData.title}
                                    <Space>
                                        <Icon
                                            key="delete"
                                            component={MdDeleteForever}
                                            size='small'
                                            onClick={() => showConfirmDeleteOne(activeData)}
                                        />
                                        <Icon
                                            key="link"
                                            component={FiExternalLink}
                                            size='small'
                                            onClick={() => {
                                                window.open(videoUrlByBaseUrl(activeData.url, activeData.type), '_blank', 'noreferrer');
                                            }}
                                        />
                                        <Icon
                                            key="copy"
                                            component={MdContentCopy}
                                            size='small'
                                            onClick={() => {
                                                copyToClipboard(videoUrlByBaseUrl(activeData.url, activeData.type))
                                                messageApi.open({
                                                    type: 'success',
                                                    content: 'File path has been copied!',
                                                });
                                            }}
                                        />
                                    </Space>
                                </Space>}
                            url={activeData.url}
                            type={activeData.type}
                            activeData={activeData}
                            setActiveData={setActiveData}
                        />
                    }
                </Col>

                <Col span={24}>
                    <Row gutter={16}>
                        {data.map((item, index) => (
                            <Col key={index} className="gutter-row" span={6}>
                                <Card
                                    loading={loading}
                                    className='mb-4 cursor-pointer'
                                    size='small'
                                    actions={[
                                        <MdContentCopy
                                            key="copy"
                                            onClick={() => {
                                                copyToClipboard(videoUrlByBaseUrl(item.url, item.type))
                                                messageApi.open({
                                                    type: 'success',
                                                    content: 'File path has been copied!',
                                                });
                                            }}
                                            className={styles.icon}
                                        />,
                                        <FiExternalLink
                                            key="link"
                                            onClick={() => {
                                                window.open(videoUrlByBaseUrl(item.url, item.type), '_blank', 'noreferrer');
                                            }}
                                            className={styles.icon}
                                        />,
                                        // <FiDownload
                                        //     key="download"
                                        //     component={FiDownload}
                                        //     onClick={() => {
                                        //         EvidenceApi.downloadFile(getMediaUrl(item.url, baseUrl), item.title)
                                        //             .then(() => {
                                        //                 messageApi.open({
                                        //                     type: 'success',
                                        //                     content: 'File downloaded successfully',
                                        //                 });
                                        //             })
                                        //             .catch(error => {
                                        //                 messageApi.open({
                                        //                     type: 'error',
                                        //                     content: 'Failed to download file',
                                        //                 });
                                        //             });
                                        //     }}
                                        // />,
                                        <MdDeleteForever
                                            key="delete"
                                            onClick={() => showConfirmDeleteOne(item)}
                                            className={styles.icon}
                                        />,
                                    ]}
                                    cover={
                                        !loading ?
                                            renderThumbnailMedia(item.type, item.url, () => setActiveData(item)) :
                                            null
                                    }
                                >
                                    <Meta
                                        title={<small className="text-black pb-0">{item.title}</small>}
                                        description={
                                            <Space className='justify-between w-100 fs-12'>
                                                <Col className='px-0'>
                                                    <ArrowsAltOutlined /> {item.size}
                                                </Col>
                                                <Col className='px-0' title={dayjs(item.last_modified).format('MMMM Do YYYY, h:mm:ss a')}>
                                                    <FieldTimeOutlined /> {dayjs(item.last_modified).fromNow()}
                                                </Col>
                                            </Space>
                                        }
                                        onClick={() => setActiveData(item)}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </>
    )
}
