import appApi from "../../index";
import qs from 'qs';

const endpoint = "recycle-revised-offers";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const listConditions = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/create`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
}

const createOffer = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
}

const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const updateOne = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
}

const deleteOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendOfferReminder = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/notification/reminder`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      })
  })
}

export default {
  list,
  listConditions,
  createOffer,
  getOne,
  updateOne,
  deleteOne,
  sendOfferReminder
};
