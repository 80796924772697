import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  Typography
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { get } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

import styles from './WaitingForConformation.module.scss';

import EmployeeApi from "../../apis/EmployeeApi";
import WaitingForConformationApi from "../../apis/WaitingForConformationApi";
import DateRangeFilter from "../../components/Dropdown/DateRangeFilter";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { loop, textSlice } from "../../util/helpers";
import ExpandedRowRender from "./Components/ExpandedRowRender";
import MoreOptions from "./Components/MoreOptions";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import StatusBadge from "./Components/StatusBadge";
import { getAllActualStatus, getAllSource, getAllStatus } from "./util/common";
import { useSelector } from 'react-redux';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

const { Title, Text } = Typography;

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function WaitingForConformation(props) {
  useDocumentTitle(props.title);
  const { roles } = useSelector((state) => state.auth.user[0])

  const [tableTab, setTableTab] = useState(0);
  const [waitingForConformationData, setWaitingForConformation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterWaitingForConformation, setFilterWaitingForConformation] = useState(null);
  const [key, setKey] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const [visible, setVisible] = useState(false);
  const [activeWaitingForConfirmation, setActiveWaitingForConfirmation] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetSearch = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetSearch(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "USER/NAME",
      dataIndex: "userOrName",
      key: "userOrName",
      render: (text) => <Title className={styles.blueText} level={5}>{text}</Title>,
      sorter: (a, b) => a.userOrName.length - b.userOrName.length,
      ...getColumnSearchProps('userOrName'),
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      key: "source",
      render: text => <Title level={5}>{text}</Title>,
      filters: getAllSource.map((item) => ({ text: item, value: item })),
      onFilter: (value, record) => (value === record.source),
      sorter: (a, b) => a.source.length - b.source.length,
      ...getColumnSearchProps('source'),
    },
    {
      title: "ARRIVAL",
      key: "dateOfArrival",
      dataIndex: "dateOfArrival",
      render: text => <Text>{text ? dayjs.tz(text, "Europe/London").format("DD-MM-YYYY") : null}</Text>,
      sorter: (a, b) => dayjs(a.dateOfArrival).unix() - dayjs(b.dateOfArrival).unix(),
      filterDropdown: (props) => <DateRangeFilter {...props} />,
      onFilter: (value, item) => {
        const [from, to] = value;
        if (!from || !to) return true;
        return dayjs(item.dateOfArrival).isBetween(from, to, 'days', true);
      }
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: value => <StatusBadge status={value} />,
      filters: getAllActualStatus.map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.status),
    },
    {
      title: "Handle By",
      dataIndex: "handle_by",
      key: "handle_by",
      render: text => <Title level={5}>{text}</Title>,
      filters: getAllSource.map((item) => ({ text: item, value: item })),
      onFilter: (value, record) => (value === record.handle_by),
      sorter: (a, b) => a.handle_by.length - b.handle_by.length,
      ...getColumnSearchProps('handle_by'),
    },
    {
      title: "WFC Note",
      dataIndex: "note",
      key: "note",
      className: styles.textWrap,
      render: text => textSlice(text, 50),
      sorter: (a, b) => a.note.length - b.note.length,
      ...getColumnSearchProps('note'),
      width: 400
    }
  ];

  const onChange = (e) => setTableTab(e.target.value);

  useEffect(() => {
    setLoading(true);
    const waitingForConformationData = [];
    get(WaitingForConformationApi.list('status', tableTab))
      .then((snapshot) => {
        const data = snapshot.val();
        if (data) {
          loop(data, (item, key) => {
            waitingForConformationData.push({ ...item, key });
          });
          setWaitingForConformation(waitingForConformationData.reverse());
          setLoading(false);
          return;
        }
        setWaitingForConformation([]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [key, tableTab]);

  useEffect(() => {
    EmployeeApi.list()
      .then((employeeList) => {
        setEmployees(employeeList);
      })
      .catch(err => {
        //
      });
  }, []);

  const showModal = (item) => {
    setVisible(true);
    setActiveWaitingForConfirmation(item);
  };

  const handleReset = () => {
    setActiveWaitingForConfirmation(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveWaitingForConfirmation(null);
  };

  const search = value => {
    const filterTable = waitingForConformationData.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    setFilterWaitingForConformation(filterTable);
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">
              <PageHeader
                ghost={false}
                title="Waiting For Conformation"
                extra={[
                  <Button key="1" type="primary">
                    <Link to="/waiting-for-conformation/create">Create</Link>
                  </Button>,
                ]}
              />

              <Card
                bordered={false}
                className={`criclebox tablespace mb-24 ${styles.table}`}
                title={
                  <Input.Search
                    placeholder="Search by USER/NAME, SOURCE, HANDLE BY"
                    className={styles.search}
                    onSearch={search}
                    size="large"
                    allowClear
                  />
                }
                extra={
                  <Radio.Group
                    onChange={onChange}
                    defaultValue="0"
                    size='small'
                  >
                    {getAllStatus.map((item, idx) => (
                      <Radio.Button key={idx} value={item.value}>
                        <Badge
                          status={item.color}
                          text={item.label}
                          className={styles.font_weight_normal}
                        />
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                }
                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <div className="table-responsive">
                  <MoreOptions
                    setIndex={setKey}
                    loading={loading}
                  />

                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.key}
                    rowSelection={rowSelection}
                    dataSource={filterWaitingForConformation === null ? waitingForConformationData : filterWaitingForConformation}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [100, 75, 50, 20, 10],
                      defaultPageSize: 100,
                      position: ["bottomRight"]
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    expandable={{
                      expandedRowRender: (record) => <ExpandedRowRender record={record} />,
                      rowExpandable: record => record.userOrName !== 'Not Expandable',
                    }}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        {visible &&
          <QuickEdit
            visible={visible}
            handleCancel={handleCancel}
            activeWaitingForConfirmation={activeWaitingForConfirmation}
            handleReset={handleReset}
            employees={employees}
            roles={roles}
          />
        }
      </div>
    </>
  );
}