import React, { useState } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

import 'react-html5-camera-photo/build/css/index.css';

import ImagePreview from '../ImagePreview';

export default function ImageCapture({ id, baseUrl }) {
    const [captureObj, setCaptureObj] = useState({
        imageObj: null,
        uploadProgress: 0,
        isLoading: false
    });

    function handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto', dataUri);
    }

    function handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto', dataUri);
        setCaptureObj(prevCaptureObj => ({ ...prevCaptureObj, imageObj: dataUri }));
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }

    function handleCameraStart(stream) {
        console.log('handleCameraStart', stream);
    }

    function handleCameraStop() {
        console.log('handleCameraStop');
    }

    return (
        <>
            {captureObj.imageObj ?
                <ImagePreview
                    captureObj={captureObj}
                    setCaptureObj={setCaptureObj}
                    isFullscreen={false}
                    baseUrl={baseUrl}
                    id={id}
                />
                :
                <Camera
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                    onCameraError={(error) => { handleCameraError(error); }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{ width: 640, height: 480 }}
                    imageType={IMAGE_TYPES.JPG}
                    imageCompression={0.97}
                    isMaxResolution={true}
                    isImageMirror={false}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    onCameraStart={(stream) => { handleCameraStart(stream); }}
                    onCameraStop={() => { handleCameraStop(); }}
                />
            }
        </>
    );
}
