import React, { useState } from 'react';
import { Alert, Button, Form, Input, Typography, message } from 'antd';

import OrdersApi from '../../../../apis/order-management/OrdersApi';

const { Text } = Typography;

export default function PaymentIdUpdateFrm({ paymentId, activeOrderId, baseUrl, setPaymentDetails }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Alert
        message={<>Sometimes this order may not contain the 'order_id' of Klarna Payment. <br />You can find out order_id from the Klarna payment dashboard, then please update here. <br />You can search with <Text copyable strong>{paymentId}</Text>.</>}
        type="info"
        className='mt-5'
        showIcon />

      <Form
        className='pt-3 pb-5'
        form={form}
        name="paymentIdUpdateFrm"
        layout="vertical"
        initialValues={{
          payment_id: paymentId,
        }}
        onFinish={async (values) => {
          setLoading(true);
          try {
            const response = await OrdersApi.update(baseUrl, activeOrderId, values);
            if (response) {
              setPaymentDetails(prev => prev + 1);
              message.success(response.success);
              setLoading(false);
            }
          }
          catch (error) {
            message.error(error.message);
            setLoading(false);
          }
        }}
      >
        <Form.Item
          label="Payment Id"
          name="payment_id"
          rules={[{ required: true, message: 'Please input payment id!' }]}
          hidden
        >
          <Input type='hidden' />
        </Form.Item>

        <Form.Item
          label="Order ID"
          name="order_id"
          rules={[{ required: true, message: 'Please input order id!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
