import React from 'react';

import './App.css';

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import './styles/Web.scss';

import { AuthProvider } from './contexts/AuthContext';
import ComponentRenderer from './routes/ComponentRenderer';

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <ComponentRenderer />
      </div>
    </AuthProvider>
  );
}

export default App;
