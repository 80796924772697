import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, message, Drawer, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import WorkforceApi from '../../../apis/WorkforceApi';

dayjs.extend(utc)
dayjs.extend(timezone)
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickDesignationCreate({ visibleDesignationCreate, handleCancel, activeDesignations, handleReset, roles, setIndex }) {

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form2] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinishDesignations = async (values) => {
        try {

            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);

            if (activeDesignations?.id) {
                formData.append('id', activeDesignations?.id);
            }

            try {

                const createDesignation = await WorkforceApi.createDesignation(baseUrl, formData);
                if (createDesignation) {
                    message.success('Designation is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form2.resetFields();
                    handleReset();
                    handleCancel();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeDesignations?.id]);

        try {
            const deleteDesignation = await WorkforceApi.deleteDesignation(baseUrl, formData);
            if (deleteDesignation) {
                message.success('Designation is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form2.resetFields();
                handleReset();
                handleCancel();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item Delete`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    const handleClear = () => {

        form2.setFieldsValue({
            title: null,
            description: null,
        });
    };

    useEffect(() => {
        if (activeDesignations) {
            form2.setFieldsValue({
                title: activeDesignations?.title,
                description: activeDesignations?.description,
            });
        }
    }, [activeDesignations, form2]);

    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Designation Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='designation-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeDesignations?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}

            open={visibleDesignationCreate}
            onClose={handleCancel}
            onCancel={handleCancel}
            placement="left"
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={750}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {activeDesignations?.id &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                    <Col>
                        <Button key="cancel" onClick={handleClear}>Clear</Button>
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form2}
                id="designation-update"
                name="nest-messages"
                onFinish={onFinishDesignations}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Row gutter={[24, 0]}>
                            <Col span={10}>
                                <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Title' />
                                </Form.Item>
                            </Col>

                            <Col span={14}>
                                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Description' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Form>
        </Drawer>
    )
}
