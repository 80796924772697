import { LoadingOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  message
} from "antd";
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from './Orders.module.scss';

import EmployeeApi from '../../apis/EmployeeApi';
import NotificationTemplateApi from "../../apis/order-management/NotificationTemplateApi";
import OrdersApi from "../../apis/order-management/recycle/RecycleOrdersApi";
import PayTypeBadgeAlt from '../../components/PayTypeBadgeAlt';
import StatusBadge from '../../components/StatusBadge';
import { SiteOptions, dropdownConditionals } from '../../components/layout/util/common';
import SearchContext from '../../contexts/SearchContext';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useLogin from '../../hooks/useLogin';
import EditableCell from './Components/Editable/EditableCell';
import EditableRow from './Components/Editable/EditableRow';
import ExpandedRowRender from "./Components/ExpandedRowRender";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import { filteredOrderStatus, getAllOrderStatus, getAllPayStatus, getAllWFCStatus, getOrderStatusByValue, getPayStatusByValue, getWFCStatusByValue } from "./util/common";
import BulkRecycleDC from './Components/BulkRecycleDC';
import ServiceApi from '../../apis/order-management/ServiceApi';

const { Text } = Typography;

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function Orders(props) {
  useDocumentTitle(props.title);

  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)

  let { pathname } = useLocation();
  const { searchText: searchableOrderId, setSearchText: setSearchableOrderId } = useContext(SearchContext);
  const shippingOrders = useSelector(state => state.shippingOrdersRecyle?.shippingOrdersRecycle);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [notificationTemplate, setNotificationTemplate] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [orderStatusCounts, setOrderStatusCounts] = useState(null);
  const [visibleBulkAction, setVisibleBulkAction] = useState(false);
  const [services, setServices] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_paid: null
  });

  const queryRef = useRef('');
  const [key, setKey] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const [visible, setVisible] = useState(false);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetSearch = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetSearch(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => { if (record[dataIndex]) return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const defaultColumns = [
    {
      title: "Order #",
      dataIndex: "order_no",
      key: "order_no",
      render: text => <Text>{text ? text : ""}</Text>,
      sorter: (a, b) => a.order_no > b.order_no,
      onFilter: (value, { order_no }) => (value === order_no),
      ...getColumnSearchProps('order_no'),
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    },
    {
      title: "Full Name",
      key: "full_name",
      dataIndex: "full_name",
      ...getColumnSearchProps('full_name'),
      width: 300,
      onFilter: (value, { user }) => {
        let fullName = `${user.first_name} ${user.last_name ? user.last_name : ""}`;
        if (fullName) return fullName.toString().toLowerCase().includes(value.toLowerCase())
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (_, { user }) => {
        let fullName = `${user.first_name} ${user.last_name ? user.last_name : ""}`;
        return (searchedColumn === 'full_name' ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={fullName ? fullName.toString() : ''}
          />
        ) : (
          fullName
        ))
      },
    },
    {
      title: "Postcode",
      key: "postal_code",
      dataIndex: "postal_code",
      ...getColumnSearchProps('postal_code'),
      onFilter: (value, { user }) => {
        return user.postal_code.toString().toLowerCase().includes(value.toLowerCase())
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (_, { user }) => <Text>{user.postal_code ? user.postal_code : ""}</Text>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => {
        const statusObj = getOrderStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllOrderStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.status),
      editable: true,
    },
    {
      title: "WFC",
      key: "wfc_status",
      dataIndex: "wfc_status",
      render: (value) => {
        const statusObj = getWFCStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllWFCStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.wfc_status),
      editable: true,
    },
    {
      title: "Pay Status",
      key: "is_paid",
      dataIndex: "is_paid",
      render: (value) => {
        const statusObj = getPayStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.color} label={statusObj?.label} />);
      },
      filters: getAllPayStatus.map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.is_paid),
    },
    {
      title: "Pay Type",
      key: "payment_type",
      dataIndex: "payment_type",
      render: (_, { payment_details }) => (<PayTypeBadgeAlt type={payment_details?.paypal_username ? 'bank' : 'paypal'} />),
    },
  ];

  const onChange = (e) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      query: "",
      status: null,
      wfc_status: null,
      [e.target.name]: e.target.value
    }));
  };

  const changeBaseUrlToFMBS = async () => {
    const tradeBaseUrl = SiteOptions[2].value;
    if (baseUrl !== tradeBaseUrl) return;
    await handleBaseUrlChange(tradeBaseUrl);
  }

  useLayoutEffect(() => {
    let isCancelled = false
    changeBaseUrlToFMBS(); // change to fmbs baseurl
    mountData(isCancelled);

    return () => {
      isCancelled = true
    }
  }, []);

  useLayoutEffect(() => {
    let isCancelled = false
    fetchOrderStatusCount(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key]);

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchOrders = useCallback((isCancelled) => {
    setLoading(true);
    OrdersApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: orderData }) => {
        if (!isCancelled) {
          setOrders(orderData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query, searchableOrderId),
            pagination: {
              ...prevTableParams.pagination,
              current: orderData.current_page,
              pageSize: orderData.per_page,
              total: orderData.total,
            },
          }));
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, searchableOrderId, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchOrders(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams), searchableOrderId, baseUrl]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOrders([]);
    }
  };

  const showModal = (id) => {
    setActiveOrderId(id);
    setVisible(true);
  };

  const handleReset = () => {
    setActiveOrderId(null);
    setVisible(false);
    setVisibleBulkAction(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveOrderId(null);
  };

  const handleHide = () => {
    setVisible(false);
    setVisibleBulkAction(true);
  };

  const search = value => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      status: null,
      wfc_status: null,
      query: value
    }));
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }

  const mountData = async (isCancelled) => {
    const { data: list } = await NotificationTemplateApi.getAll(baseUrl, 'recycle');
    const employeeList = await EmployeeApi.list(baseUrl);
    const serviceList = await ServiceApi.getServices(baseUrl);
    if (!isCancelled) {
      if (list) setNotificationTemplate(list);
      if (employeeList) setEmployees(employeeList);
      if (serviceList) setServices(serviceList);
    }
  }

  const fetchOrderStatusCount = async (isCancelled) => {
    const counts = await OrdersApi.getStatusCounts(baseUrl);
    if (!isCancelled) {
      if (counts) {
        setOrderStatusCounts(counts);
      }
    }
  }

  // Table - Editable Cell
  const handleSaveOrderStatus = async ({ id, status, order_no }) => {
    setLoading(true);
    try {
      if (!id)
        throw new Error('Something went wrong!');

      const updatedOrder = await OrdersApi.updateOne(baseUrl, id, {
        status: status,
        is_active: 1,
        order_type: 'recycle'
      });

      if (updatedOrder) {
        message.success(`${order_no} - Order is updated!`);
        setKey(prevState => prevState + 1);
        setLoading(false);
      }
    } catch (e) {
      message.error(e.message ?? 'Something went wrong!');
      setLoading(false);
    }
  }

  const handleSaveWFCStatus = async ({ id, wfc_status, order_no }) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('wfc_status', wfc_status);
      formData.append('type', 'recycle');
      const wfcUpdated = await NotificationTemplateApi.wfcUpdate(baseUrl, id, formData);

      message.success(`${order_no} - ${wfcUpdated.message}!`);
      setKey(prevState => prevState + 1);
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          onClick: (_) => {
            showModal(record.id)
          }
        })
      };
    }

    if (col.dataIndex === 'wfc_status') {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSaveWFCStatus,
        }),
      };
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveOrderStatus,
      }),
    };
  });

  const handleBaseUrlChange = async (value) => {
    const tradeBaseUrl = SiteOptions.find(site => site.brand === 'BUSINESS').value;
    if (baseUrl === tradeBaseUrl) return;

    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <div className="site-page-header-ghost-wrapper">
            <Card
              bordered={false}
              className={`criclebox tablespace pb-0 mb-0 ${styles.table}`}
            >
              <Row className="mb-6" justify="space-between" align="top">
                <Col md={24} className="mb-4">
                  <Row justify="space-between" align="top">
                    <Col md={12} className='d-inline-flex'>
                      <Button
                        type="dashed"
                        icon={<SyncOutlined />}
                        loading={loading}
                        onClick={() => {
                          setKey(prevKey => prevKey + 1)
                        }}
                        className="mr-2"
                      />
                      <Select
                        value={baseUrl}
                        onChange={handleBaseUrlChange}
                        className="mr-4"
                        style={{ width: 260 }}
                        options={SiteOptions.map(option => ({
                          ...option,
                          disabled: option.brand === 'BUSINESS' 
                        }))}
                        disabled={loading}
                      />
                      <Input.Search
                        ref={queryRef}
                        placeholder="Search by Order Id, Created At, Name, E-Mail, Ph. No, Postcode"
                        className={`w-100 ${styles.search}`}
                        onSearch={search}
                        allowClear
                        disabled={loading}
                      />
                    </Col>
                    <Col md={12} className='text-right'>
                      <div className='d-inline-flex flex-column align-self-start'>
                        <Tag color='cyan' className='m-0'>WFC</Tag>
                        <Radio.Group
                          size='small'
                          onChange={onChange}
                          defaultValue="null"
                          value={tableParams.wfc_status}
                          name='wfc_status'
                          id='wfc_status'
                          className="float-right"
                          disabled={loading}
                        >
                          {getAllWFCStatus.map((item, idx) => (
                            <Radio.Button key={idx} value={item.value}>
                              <Badge
                                color={item.color}
                                text={item.label + ` ${orderStatusCounts?.wfcStatusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.wfcStatusCounts[item.value] + ")" : ""}`}
                                className={styles.font_weight_normal}
                              />
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </div>
                    </Col>
                    <Col md={24}>
                      <Radio.Group
                        size='small'
                        onChange={onChange}
                        defaultValue="null"
                        value={tableParams.status}
                        name='status'
                        id='status'
                        disabled={loading}
                      >
                        {filteredOrderStatus.map((item, idx) => (
                          <Radio.Button key={idx} value={item.value}>
                            <Badge
                              color={item.color}
                              text={item.label + ` ${orderStatusCounts?.statusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.statusCounts[item.value] + ")" : ""}`}
                              className={styles.font_weight_normal}
                            />
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Col>
                    <Col md={6} className="text-right d-flex">

                    </Col>
                    <Col md={10} className="text-right d-flex">

                      <BulkRecycleDC
                        key='1'
                        orders={orders}
                        setKey={setKey}
                        setVisible={visibleBulkAction}
                        setVisibleBulkAction={setVisibleBulkAction}
                      />

                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="table-responsive">
                <SelectedOptions
                  selectedRowKeys={selectedValues.selectedRowKeys}
                  selectedRows={selectedValues.batchRecord}
                  setIndex={setKey}
                  rowsUnCheckHandler={rowsUnCheckHandler}
                  baseUrl={baseUrl}
                />

                <Table
                  components={components}
                  columns={columns}
                  rowKey={(record) => record.id}
                  rowSelection={rowSelection}
                  dataSource={orders}
                  loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                    showQuickJumper: true,
                    pageSizeOptions: [100, 200, 300, 400, 500],
                    defaultPageSize: tableParams.pagination.pageSize,
                    position: ["bottomRight"],
                    ...tableParams.pagination
                  }}
                  className="ant-border-space"
                  rowClassName={() => 'editable-row'}
                  size="small"
                  ellipsis
                  responsive
                  expandable={{
                    expandedRowRender: (record) => <ExpandedRowRender record={record} />,
                    rowExpandable: record => record.order_no !== 'Not Expandable',
                  }}
                  onChange={handleTableChange}
                  scroll={{ y: 600 }}
                />
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      {visible &&
        <QuickEdit
          visible={visible}
          handleHide={handleHide}
          handleCancel={handleCancel}
          activeOrderId={activeOrderId}
          handleReset={handleReset}
          setKey={setKey}
          notificationTemplate={notificationTemplate}
          baseUrl={baseUrl}
          roles={roles}
          employees={employees}
          services={services}
        />
      }
    </div>
  );
}