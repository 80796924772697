import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shippingOrders: []
};

const shippingOrdersSlice = createSlice({
    name: 'shippingOrders',
    initialState,
    reducers: {
        addShippingOrder: (state, action) => {
            state.shippingOrders.push(action.payload);
        },
        deleteShippingOrder: (state, action) => {
            state.shippingOrders = state.shippingOrders.filter(order => order.id !== action.payload);
        }
    }
});

export const { addShippingOrder, deleteShippingOrder } = shippingOrdersSlice.actions;

export default shippingOrdersSlice.reducer;
