import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

import styles from './loading.module.scss'

const antIcon = <LoadingOutlined style={{ fontSize: '3rem' }} spin />;

export default function LoadingBig() {
  return (
    <div className={styles.loadingWrap}>
      <Spin indicator={antIcon} />
    </div>
  )
}