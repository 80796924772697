const getAllStatus = [
    { value: 0, label: "All", color: "" },
    { value: 1, label: "Processing", color: "warning" },
    { value: 2, label: "Complete", color: "success" },
    { value: 3, label: "Cancel", color: "error" },
    { value: 4, label: "Return", color: "default" },
];

const getStatusByValue = (id) => (getAllStatus.find(({ value }) => (value === id)));

const getAllActualStatus = getAllStatus.slice(1);

const getAllSource = ['FMBS', 'IWATCH', 'IPAD', 'MACBOOK', 'BUSINESS', 'RE', 'SE'];
const getAllDevice = ['S1/38MM', 'S1/42MM', 'S2/38MM', 'S2/42MM', 'S3/38MM', 'S3/42MM', 'S4/40MM', 'S4/44MM', 'S5/40MM', 'S5/44MM', 'SE/40MM', 'SE/44MM', 'S6/40MM', 'S6/44MM', 'S7/41MM', 'S7/45MM'];

export {
    getAllStatus,
    getStatusByValue,
    getAllActualStatus,
    getAllSource,
    getAllDevice
};