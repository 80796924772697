import React from 'react'
import { FcSearch } from "react-icons/fc";
import { FaCcStripe } from 'react-icons/fa';
import { FaCcPaypal } from 'react-icons/fa';
import { SiKlarna } from 'react-icons/si';

import styles from './PayTypeBadge.module.scss';
import { getPayTypeByValue } from '../../pages/Orders/util/common';

export default function PayTypeBadge({ type }) {
    return (
        <>
            {
                (type === 0 ? <FcSearch className={styles.icon} title={getPayTypeByValue(type).label} /> :
                    (type === 1 ? <FaCcPaypal className={styles.icon_paypal} title={getPayTypeByValue(type).label} /> :
                        (type === 2 ? <SiKlarna className={styles.icon_klarna} title={getPayTypeByValue(type).label} /> : <FaCcStripe className={styles.icon_stripe} title={getPayTypeByValue(type).label} />)))
            }
        </>
    )
}
