import { SiteOptions } from '../components/layout/util/common';
import { ZENDESK_FMBS_BRAND_ID, ZENDESK_FMBS_BRAND2_ID, ZENDESK_IWATCH_BRAND_ID, ZENDESK_IWATCH_BRAND2_ID, ZENDESK_TRADE_BRAND_ID } from '../constants/varibales';

export const getOrderTypes = ['orders', 'recycle-orders', 'direct-orders'];

export const getValidOrderType = (type) => getOrderTypes.includes(type) ? type : null;

const getBrandByPrefix = (prefix) => {
    const prefixMap = {
        OR: 'FMBS', SD: 'FMBS', PR: 'FMBS', F: 'FMBS',
        I: 'IWATCH',
        A: 'IPAD',
        M: 'MACBOOK',
        T: 'BUSINESS'
    };

    return prefixMap[prefix.toUpperCase()] || null;
};

export const getBaseUrlByOrderId = (currentUrl, orderId) => {
    const prefix = orderId.substring(0, 2);
    const brand = getBrandByPrefix(prefix) || getBrandByPrefix(orderId[0]);

    return brand ? SiteOptions.find(site => site.brand === brand).value : currentUrl;
};

export const getBaseUrlByRecycleOrderId = getBaseUrlByOrderId;

export const getZendeskBrandIdByBaseUrl = (currentUrl, isRecycle) => {
    if (currentUrl === SiteOptions[0].value) {
        return !isRecycle ? ZENDESK_FMBS_BRAND_ID : ZENDESK_FMBS_BRAND2_ID
    } else if (currentUrl === SiteOptions[1].value) {
        return !isRecycle ? ZENDESK_IWATCH_BRAND_ID : ZENDESK_IWATCH_BRAND2_ID;
    } else if (currentUrl === SiteOptions[2].value) {
        return ZENDESK_TRADE_BRAND_ID;
    }

    throw new Error('Cannot find Zendesk brand id for the given base url');
}