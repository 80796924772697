import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shippingOrdersRecycle: []
};

const shippingOrdersRecycleSlice = createSlice({
    name: 'shippingOrdersRecycle',
    initialState,
    reducers: {
        addShippingOrder: (state, action) => {
            state.shippingOrdersRecycle.push(action.payload);
        },
        deleteShippingOrder: (state, action) => {
            state.shippingOrdersRecycle = state.shippingOrdersRecycle.filter(order => order.id !== action.payload);
        }
    }
});

export const { addShippingOrder, deleteShippingOrder } = shippingOrdersRecycleSlice.actions;

export default shippingOrdersRecycleSlice.reducer;
