import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// routes config
import { routes } from './routes';

import Main from "../components/layout/Main";
import { useSelector } from "react-redux";

const ComponentRenderer = () => {
    const unProtectedRoutes = routes.filter(route => !route.protected);
    const protectedRoutes = routes.filter(route => !!route.protected);

    const { isLoggedIn } = useSelector((state) => state.auth);

    return (
        <Switch>
            {unProtectedRoutes && unProtectedRoutes.map((route, idx) => {
                return (route.component && !route.protected) && (
                    <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                            <route.component
                                title={route.title}
                                {...props} />
                        )} />
                )
            })}
            {isLoggedIn ?
                <Main>
                    {isLoggedIn && protectedRoutes && protectedRoutes.map((route, idx) => {
                        return (route.component && route.protected) && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component
                                        title={route.title}
                                        {...props} />
                                )} />
                        )
                    })}
                </Main>
                :
                <Redirect to="/sign-in" />
            }
        </Switch>
    );
};

export default ComponentRenderer;