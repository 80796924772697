import React from 'react';
import { Badge } from 'antd';

export default function StatusBadge(props) {

    let status = "warning"; // process
    let text = "Pending";

    switch (props.status) {
        case 1:
            status = "warning"; // process
            text = "Pending";
            break;
        case 2:
            status = "success"; // complete
            text = "Approved";
            break;
        case 3:
            status = "error"; // cancel
            text = "Cancel";
            break;
        case 4:
            status = "default"; // return
            text = "Return";
            break;
        case 5:
            status = "processing"; // return
            text = "Solved";
            break;
        default:
            status = "warning";
            text = "Pending";
    }

    return (
        <span>
            <Badge
                status={status}
                text={text} />
        </span>
    )
}
