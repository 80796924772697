import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  message,
  Radio,
  Typography,
  List,
} from "antd";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CopyOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from './Invoice.module.scss';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import ListItem from '../DirectOrder/Components/ListItem';
import InvoiceApi from '../../apis/invoice/InvoiceApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};

export default function CreateEditForm({ location, title, history }) {
  const [form] = Form.useForm();
  useDocumentTitle(title);
  const validateMessages = {
    required: '${label} is required!',
  };

  const [loading, setLoading] = useState(false)
  const [invoice, setInvoice] = useState(null)
  const [subTotal, setSubTotal] = useState(0);



  const baseUrl = useSelector((state) => state.auth.base_url)
  const [loadingObj, setLoadingObj] = useState({
    generateInvoice: false
  });

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('customer_name', values?.customer);
      formData.append('invoice_memo', values?.memo);
      formData.append('payment_type', values.payment_type);
      values.devices.forEach((value) => {
        formData.append('service[]', value.service);
        formData.append('qty[]', value.qty);
        formData.append('amount[]', value.amount);
        formData.append('note[]', value.note);
      });
      const invoice = await InvoiceApi.createInvoice(baseUrl, formData);
      if (invoice) {
        console.log(invoice?.data?.share_link);
        
        message.success(invoice.message);
        setInvoice(invoice?.data?.share_link);
        setLoading(false);
        setSubTotal(0);
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong!');
    }
  };


  const handleFieldsChange = (_, allFields) => {
    let newSubTotal = 0;
    if (allFields.devices) {
      allFields.devices.forEach(field => {
        const { amount, qty } = field;
        newSubTotal += amount * qty;
      });
    }
    setSubTotal(newSubTotal);
  };

  const copyToClipboard = () => {
    if (invoice) {
      navigator.clipboard.writeText(invoice)
        .then(() => {
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[8, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">
              <PageHeader
                ghost={false}
                className="site-page-header"
                onBack={() => history.goBack()}
                title={`${location.state ? "Edit" : "Add New"} Invoice`}
              />
              <Card
                bordered={false}
                className={`criclebox tablespace mb-24 ${styles.space}`}
              >
                <Form
                  {...layout}
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    allow_attachments: false,
                    devices: [{ qty: 1, amount: 0 }],
                    editable: true,
                  }}
                  onValuesChange={handleFieldsChange}
                >
                  <Row gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item name="customer" label="Customer Name" rules={[{ required: true }]} >
                        <Input placeholder="Name" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="memo" label="Invoice Memo" labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}  >
                        <Input placeholder="Memo" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="payment_type" initialValue="paypal">
                        <Radio.Group>
                          <Radio value="stripe">Stripe</Radio>
                          <Radio value="paypal">Paypal</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={20}>
                      <Form.List name="devices">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <>
                                <Row gutter={24} key={key} className='pt-2'>
                                  <Col span={7}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'service']}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing Item Name',
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Item Name" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'qty']}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing qty',
                                        },
                                      ]}
                                    >
                                      <InputNumber className='w-100' size="large" min={1} placeholder="Qty" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'amount']}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing amount',
                                        },
                                      ]}
                                    >
                                      <InputNumber className='w-100' size="large" placeholder="Amount" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'note']}
                                      label=""
                                    >
                                      <Input.TextArea placeholder="Note" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1} className='d-flex align-center justify-start mb-3'>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Col>
                                </Row>
                              </>
                            ))}

                            <Form.Item label="">
                              <Button
                                size='middle'
                                shape="round"
                                type="dashed"
                                onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                icon={<PlusOutlined />}
                              >
                                Add field
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                    <Col span={4}>
                      <List
                        size="small"
                        header={<Text>Invoice Summery</Text>}
                        bordered
                        dataSource={[
                          <ListItem key={1} keyText="Subtotal : " valueText={'£' + subTotal.toFixed(2)} />,
                          <ListItem key={2} keyText="Total : " valueText={'£' + subTotal.toFixed(2)} />,
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                      />
                    </Col>
                    {invoice &&
                      <Col span={24} >
                        <div className={styles.invoiceDiv} >
                          {invoice} <CopyOutlined className='mx-3'  onClick={copyToClipboard}/>
                        </div>
                      </Col>
                    }
                  </Row>

                  <Form.Item label=" " wrapperCol={{ ...layout.wrapperCol }}>
                    <Button
                      className={styles.submitBtn}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Generate Invoice
                    </Button>

                    <Link to="/invoice">
                      <Button>
                        Cancel
                      </Button>
                    </Link>
                  </Form.Item>
                </Form>

              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}