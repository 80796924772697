import { ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Drawer, Modal, Row, Skeleton, Typography, message, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/OrdersApi';
import GroupOptions from './GroupOptions';
import MoreInfo from './MoreInfo';
import OrderItems from './OrderItems';
import OrderUpdate from './OrderUpdate';
import DrawerWrapper from './DrawerWrapper';
import { store } from '../../../../store';
import { addShippingOrder } from '../../../../store/slices/shippingOrdersSlice';

const { Text } = Typography;
const { confirm } = Modal;

export default function QuickEdit({ visible, handleHide, handleCancel, handleReset, isNavigable, loadingOrder, setKey, notificationTemplate, baseUrl, roles, activeOrderId, employees, services }) {
    const [modelLoading, setModelLoading] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);

    const [openPaymentDetails, setOpenPaymentDetails] = useState(false);

    const fetchOrder = async () => {
        setModelLoading(true);
        try {
            const order = await OrdersApi.getOne(baseUrl, activeOrderId);
            setActiveOrder(order);
            setModelLoading(false);
        } catch (error) {
            setModelLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    const repairTotal = useMemo(() => {
        if (activeOrder?.order_repair?.length > 0) {
            return activeOrder.order_repair.reduce((repairTotal, item) => repairTotal + (parseFloat(item.amount) * parseInt(item.qty)), 0);
        }
        return 0;
    }, [activeOrder]);

    const shippingCharge = useMemo(() => activeOrder?.order_shipment?.is_shipping === 1 ? parseFloat(activeOrder.order_shipment.amount) : 0, [activeOrder]);

    const priorityCharge = useMemo(() => activeOrder?.order_priority?.is_priority === 1 ? parseFloat(activeOrder.order_priority.amount) : 0, [activeOrder]);

    const subtotal = useMemo(() => repairTotal + shippingCharge + priorityCharge, [repairTotal, shippingCharge, priorityCharge]);

    const discount = useMemo(() => activeOrder?.coupon ? parseFloat(activeOrder.coupon.reward || activeOrder.coupon.details.discount) : 0, [activeOrder]);

    const netTotal = useMemo(() => subtotal - discount, [subtotal, discount]);

    const [loading, setLoading] = useState(false);

    const handleDeleteUser = async (item) => {
        setLoading(true);
        try {
            await OrdersApi.deleteOne(baseUrl, item.id);
            message.success(`${item.order_no} Order is deleted`);
            setLoading(false);
            setKey(prevState => prevState + 1);
            handleCancel()
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    }

    const showConfirm = (item) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${item.order_no}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser(item);
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }
    const openNotification = () => {
        notification.info({
            message: `An item has been added!`,
            description: (
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    handleHide();
                }} style={{ textDecoration: 'underline' }}>
                    View Bulk Actions
                </a>
            ),
            placement: 'bottomRight',
            duration: 4
        });
    };

    const addShippingOrderFunction = async () => {
        const state = store.getState();
        const existingOrders = state.shippingOrders.shippingOrders;
        const orderExists = existingOrders.some(order => order.id === activeOrder.id);

        if (!orderExists) {
            store.dispatch(addShippingOrder({ id: activeOrder.id }));
        }
        openNotification();
    }

    return (
        <Drawer
            push={false}
            key="order-details-model"
            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Flex
                    gap="small"
                    align="center"
                    justify='space-between'
                    className='w-100'
                >
                    <Text># {activeOrder?.order_no}</Text>

                    {activeOrder &&
                        <Button
                            size='small'
                            primary
                            onClick={addShippingOrderFunction}
                            icon={<PlusCircleOutlined />}
                        >
                            Bulk Actions
                        </Button>
                    }
                </Flex>
            </Row>}
            open={visible}
            onClose={handleCancel}
            confirmLoading={modelLoading}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={(
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeOrder && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button
                                key="submit"
                                danger
                                loading={loading}
                                onClick={() => showConfirm(activeOrder)}
                            >
                                Delete
                            </Button>
                        }
                    </Col>
                    <Col>
                        <Button
                            key="back"
                            onClick={handleCancel}
                        >
                            Back
                        </Button>
                    </Col>
                </Row>
            )}
        >
            {loadingOrder && <Skeleton loading={loadingOrder} avatar paragraph={{ rows: 8 }} />}
            {activeOrder &&
                <div>
                    <Row gutter={16} className={styles.model_card_row}>
                        <Col span={8}>
                            <OrderUpdate
                                activeOrder={activeOrder}
                                setKey={setKey}
                                handleReset={handleReset}
                                isNavigable={isNavigable}
                                baseUrl={baseUrl}
                            />
                        </Col>
                        <Col span={16}>
                            <GroupOptions
                                activeOrder={activeOrder}
                                baseUrl={baseUrl}
                                activeOrderId={activeOrderId}
                                services={services}
                                setKey={setKey}
                            />
                        </Col>
                    </Row>

                    <DrawerWrapper
                        activeOrder={activeOrder}
                        baseUrl={baseUrl}
                        handleReset={handleReset}
                        employees={employees}
                        notificationTemplate={notificationTemplate}
                    />
                    <MoreInfo
                        activeOrder={activeOrder}
                        shippingCharge={shippingCharge}
                        priorityCharge={priorityCharge}
                        discount={discount}
                        baseUrl={baseUrl}
                        setActiveOrder={setActiveOrder}
                    />
                    <OrderItems
                        activeOrder={activeOrder}
                        repairTotal={repairTotal}
                        subtotal={subtotal}
                        discount={discount}
                        netTotal={netTotal}
                        shippingCharge={shippingCharge}
                        priorityCharge={priorityCharge}
                        baseUrl={baseUrl}
                        setOpenPaymentDetails={setOpenPaymentDetails}
                        openPaymentDetails={openPaymentDetails}
                    />
                </div>
            }
        </Drawer>
    )
}
