import React from 'react'
import { Tabs } from 'antd'
import { CloudUploadOutlined, FileImageOutlined, UnorderedListOutlined, VideoCameraAddOutlined } from '@ant-design/icons';

import MediaList from '../MediaList';
import MediaUpload from '../MediaUpload';
import VideoRecordable from '../VideoRecordable';
import ImageCapture from '../ImageCapture';

export default function OrderEvidence({ id, baseUrl }) {
    return (
        <Tabs
            className='my-0 py-0'
            defaultActiveKey="1"
            destroyInactiveTabPane
            items={[
                {
                    label: 'List',
                    key: 1,
                    icon: <UnorderedListOutlined />,
                    children: <MediaList id={id} baseUrl={baseUrl} />,
                },
                {
                    label: 'Take Photo',
                    key: 2,
                    icon: <FileImageOutlined />,
                    children: <ImageCapture id={id} baseUrl={baseUrl} />,

                },
                {
                    label: 'Record a video',
                    key: 3,
                    icon: <VideoCameraAddOutlined />,
                    children: <VideoRecordable id={id} baseUrl={baseUrl} />,
                },
                {
                    label: 'Upload Files',
                    key: 4,
                    icon: <CloudUploadOutlined />,
                    children: <MediaUpload id={id} baseUrl={baseUrl} />,
                },
            ]}
        />
    )
}
