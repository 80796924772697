import React from "react";
import { DatePicker, Button } from "antd";
import { useHistory } from 'react-router'

import styles from "./DateRangeFilter.module.scss";

const DateRangeFilter = (props) => {

    const { setSelectedKeys, confirm, clearFilters } = props;
    const history = useHistory();

    const handleChange = (value) => {
        if (!value) return handleResetSearch(clearFilters);

        setSelectedKeys([value]);
        // NOTE: the problem is that setState is async so calling confirm here doesn't work
        confirm();
        // works if you set timeout
        // setTimeout(() => this.props.confirm(), 10);
    };

    const handleResetSearch = (clearFilters) => {
        clearFilters();
        history.go(0)
    };

    return (
        <div className={styles.wrap}>
            <DatePicker.RangePicker
                format="DD-MM-YYYY"
                placeholder={["from", "to"]}
                onChange={handleChange}
            />
            <Button
                onClick={() => clearFilters && handleResetSearch(clearFilters)}
                size="small"
                className={styles.button}
            >
                Reset
            </Button>
        </div>
    );
}

export default DateRangeFilter;