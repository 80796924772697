import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, List, Row, Space, Switch, Typography, message } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from "uuid";

import OrdersApi from '../../../apis/order-management/OrdersApi';
import ListItem from './ListItem';
import { TicketApi } from '../../../apis/zendesk/TicketApi';

const { Text } = Typography;
const { useForm } = Form;

const PRIORITY_CHARGE = 9.99;
const RETURN_SHIPPING = 4.99;

const CollectionCreateForm = ({ visible, onCancel }) => {
    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();

    const [loadingObj, setLoadingObj] = useState({
        previewLoading: false,
        sendLoading: false,
    });

    const initialTotal = {
        orderTotal: 0,
        discountLessTotal: 0,
        subTotal: 0,
        netTotal: 0,
    };
    const [totals, setTotals] = useState(initialTotal);

    const processedFieldsChange = (_, allFields) => {
        let newOrderTotal = 0;
        let newDiscountLessTotal = 0;
        let newSubTotal = 0;
        let newNetTotal = 0;
        if (allFields.devices) {
            allFields.devices.forEach(field => {
                const { amount, qty } = field;
                newOrderTotal += amount * +qty;
            });
            newDiscountLessTotal = newOrderTotal;
            newSubTotal = newOrderTotal;
            newNetTotal = newOrderTotal;
        }

        if (allFields.priority_change) {
            newSubTotal = newOrderTotal + PRIORITY_CHARGE;
            newNetTotal = newSubTotal;
        }

        if (allFields.return_shipping) {
            newSubTotal += newOrderTotal + RETURN_SHIPPING;
            newNetTotal = newSubTotal;
        }

        if (allFields.has_discount && allFields.discount < newOrderTotal) {
            newDiscountLessTotal = newOrderTotal - allFields.discount;
            newNetTotal = newSubTotal - allFields.discount;
        }

        setTotals({
            orderTotal: newOrderTotal,
            discountLessTotal: newDiscountLessTotal,
            subTotal: newSubTotal,
            netTotal: newNetTotal,
        });
    };

    const handlePdfPreview = async (values, isDownload) => {
        setLoadingObj(prevState => ({ ...prevState, previewLoading: true }));
        try {
            const response = await OrdersApi.generateInvoice(baseUrl, values);
            const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
            filePreviewOrDownload(url, isDownload);
            setLoadingObj(prevState => ({ ...prevState, previewLoading: false }));
        } catch (error) {
            message.error('Failed to generate invoice');
            setLoadingObj(prevState => ({ ...prevState, previewLoading: false }));
        }
    }

    const filePreviewOrDownload = (url, isDownload) => {
        const link = document.createElement('a');
        link.href = url;
        if (isDownload)
            link.setAttribute('download', uuid() + '.pdf');
        else
            link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Drawer
            open={visible}
            title="Generate a VAT invoice - Direct Customer"
            placement='left'
            onClose={onCancel}
            width={1000}
            destroyOnClose
            extra={
                <Space>
                    <Button
                        type="default"
                        onClick={() => {
                            form.resetFields();
                            setTotals(initialTotal);
                        }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        loading={loadingObj.previewLoading}
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    handlePdfPreview(values, true);
                                })
                                .catch((info) => {
                                    message.error(info.errorFields[0].errors[0]);
                                });
                        }}>
                        Download
                    </Button>
                    <Button
                        type="primary"
                        loading={loadingObj.sendLoading}
                        onClick={() => {
                            setLoadingObj(prevState => ({ ...prevState, sendLoading: true }));
                            form
                                .validateFields()
                                .then(async (values) => {
                                    const fileResponse = await OrdersApi.generateInvoice(baseUrl, values);
                                    const file = new Blob([fileResponse], { type: 'application/json' });
                                    file.name = 'invoice.pdf';
                                    file.uid = uuid() + '.pdf';

                                    const { data: { upload: { token } } } = await TicketApi.upload(file);
                                    if (token) {
                                        const sendInvoiceRequest = {
                                            ticket_id: values.ticket_id,
                                            customer: values.customer,
                                            email: values.email,
                                            upload_token: token,
                                        };

                                        const response = await OrdersApi.sendInvoice(baseUrl, sendInvoiceRequest);
                                        message.success(response.data);
                                        form.resetFields();
                                        setTotals(initialTotal);
                                    }
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                })
                                .catch((info) => {
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                    if (info.errorFields)
                                        message.error(info.errorFields[0].errors[0]);
                                    else
                                        message.error('Failed to send invoice');
                                });
                        }}>
                        Send
                    </Button>
                </Space>
            }
        >
            <Row gutter={16}>
                <Col span={18}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            devices: [{ product: '', service: '', qty: 1, amount: 0 }],
                            priority_change: false,
                            return_shipping: false,
                            has_discount: false,
                            new_ticket: false,
                            discount: 0,
                        }}
                        onValuesChange={processedFieldsChange}
                    >
                        <Row gutter={16}>
                            <Col span={18}>
                                <Form.Item name="customer" label="Customer Name:" rules={
                                    [
                                        {
                                            required: true,
                                            message: 'Please input customer name!',
                                        },
                                    ]
                                }>
                                    <Input placeholder="Customer Name" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <p className='mb-2'>Devices:</p>
                                <Form.List name="devices">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        marginBottom: 8,
                                                    }}
                                                    align="start"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'service']}
                                                        label=""
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Missing Service Name',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Service Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'product']}
                                                        label=""
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Missing Product Name',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Product Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'qty']}
                                                        label=""
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Missing qty',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber size="large" min={1} placeholder="Qty" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'amount']}
                                                        label=""
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Missing amount',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber size="large" placeholder="Amount" />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item label="">
                                                <Button
                                                    size='middle'
                                                    shape="round"
                                                    type="dashed"
                                                    onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>


                            <Col span={12}>
                                <Form.Item label={`Priority Change (£${PRIORITY_CHARGE})`} name="priority_change" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={`Return Shipping (£${RETURN_SHIPPING})`} name="return_shipping" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Has Discount?" name="has_discount" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                {form.getFieldValue('has_discount') &&
                                    <Form.Item name="discount">
                                        <InputNumber size="large" min={0} placeholder="Discount (Optional)" />
                                    </Form.Item>
                                }
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Send New Ticket?" name="new_ticket" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                {form.getFieldValue('new_ticket') ?
                                    <Form.Item
                                        name="email"
                                        label="Customer Email:"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input customer email!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Customer Email" />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        name="ticket_id"
                                        label="Ticket Id (Zendesk):"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input ticket id!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Follow-up ticket id" />
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>Invoice Summery</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={1} keyText="Order Total : " valueText={'£' + totals.orderTotal.toFixed(2)} />,
                            <ListItem key={5} keyText={<Text type='secondary' italic>VAT Tax Inc. (20%)</Text>} valueText={<Text type='secondary' italic>{'£' + (totals.discountLessTotal - totals.discountLessTotal / 1.2).toFixed(2)}</Text>} />,
                            <ListItem key={2} keyText="Priority Change : " valueText={'£' + (form.getFieldValue('priority_change') ? PRIORITY_CHARGE.toFixed(2) : 0)} />,
                            <ListItem key={3} keyText="Return Shipping : " valueText={'£' + (form.getFieldValue('return_shipping') ? RETURN_SHIPPING.toFixed(2) : 0)} />,
                            <ListItem key={4} keyText="Sub Total : " valueText={'£' + totals.subTotal.toFixed(2)} />,
                            <ListItem key={5} keyText="Discount : " valueText={'-£' + (form.getFieldValue('has_discount') ? form.getFieldValue('discount').toFixed(2) : 0)} />,
                            <ListItem key={2} keyText="Total : " valueText={'£' + totals.netTotal.toFixed(2)} />,
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />

                    <Button
                        type="dashed"
                        loading={loadingObj.previewLoading}
                        danger
                        className='my-3'
                        size='large'
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    handlePdfPreview(values, false);
                                })
                                .catch((info) => {
                                    message.error(info.errorFields[0].errors[0]);
                                });
                        }}
                    >
                        Preview Invoice
                    </Button>
                </Col>
            </Row>
        </Drawer>
    );
};

const VATInvoiceForDC = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Button
                type="primary"
                ghost
                onClick={() => setOpen(true)}
            >
                VAT Invoice
            </Button>
            <CollectionCreateForm
                visible={open}
                onCancel={() => {
                    setOpen(false);
                }}
            />
        </div>
    );
};
export default VATInvoiceForDC;