import appApi from "../index";
import qs from 'qs';

const endpoint = "notification-templates";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getAll = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-all`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createTicket = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/make-notification`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const createNotification = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/create-notification`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};



const deleteNotification = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/delete-notification`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const wfcUpdate = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/wfc/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const wfcNoteUpdate = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/wfc-note/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

export default {
  list,
  getAll,
  getOne,
  createTicket,
  createNotification,
  deleteNotification,
  wfcUpdate,
  wfcNoteUpdate,
};
