import React from 'react'

import './AnimationWrapper.scss'

export default function AnimationWrapper({ children }) {
    return (
        <div className="animation">
            {children}
            <div className="animation__circle-left circle">
                <div className="animation__circle-mini mini-left-1"></div>
                <div className="animation__circle-mini mini-left-2"></div>
                <div className="animation__circle-mini mini-left-3"></div>
                <div className="animation__circle-mini mini-left-4"></div>
                <div className="animation__circle-mini mini-left-5"></div>
            </div>
            <div className="animation__circle-right circle">
                <div className="animation__circle-mini mini-right-1"></div>
                <div className="animation__circle-mini mini-right-2"></div>
                <div className="animation__circle-mini mini-right-3"></div>
                <div className="animation__circle-mini mini-right-4"></div>
            </div>
            <div className="animation__circle-bottom circle">
                <div className="animation__circle-mini mini-bottom-1"></div>
                <div className="animation__circle-mini mini-bottom-2"></div>
                <div className="animation__circle-mini mini-bottom-3"></div>
            </div>
        </div>
    )
}
