import { Col, Descriptions, Divider, Row, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react'
import { RxCross2 } from 'react-icons/rx';
import { TiTick } from 'react-icons/ti';

import styles from "../../Orders.module.scss";

const columns = [
    {
        title: 'Model',
        dataIndex: 'model',
    },
    {
        title: 'Service',
        dataIndex: 'service',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        render: (price) => "£" + price
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        render: (_, record) => "£" + record.price * record.qty
    },
];

export default function ReturnItems({ activeOrder }) {
    return (
        <>
            {activeOrder.return_request && activeOrder.return_request.length > 0 &&
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={24}>
                        {activeOrder.return_request.map((request, index) => (
                            <>
                                <Descriptions title={"Return #" + (index + 1)}>
                                    <Descriptions.Item label="Created At">{dayjs(request.created_at).format("DD-MM-YYYY")}</Descriptions.Item>
                                    <Descriptions.Item label="Return Item(s)">{request.return_items ? request.return_items.length : 0}</Descriptions.Item>
                                    {request.issues &&
                                        <>
                                            <Descriptions.Item label="Is Water Damage">{request.issues.water_damage ? <TiTick className={styles.iconSuccess} /> : <RxCross2 className={styles.iconError} />}</Descriptions.Item>
                                            <Descriptions.Item label="Is Physical Damage">{request.issues.physical_damage ? <TiTick className={styles.iconSuccess} /> : <RxCross2 className={styles.iconError} />}</Descriptions.Item>
                                            <Descriptions.Item label="Turn off security" span={2}>
                                                {request.issues.turn_off_security ? <TiTick className={styles.iconSuccess} /> : <RxCross2 className={styles.iconError} />}
                                            </Descriptions.Item>
                                        </>
                                    }
                                    <Descriptions.Item label="Problem in brief" span={3}>
                                        <div dangerouslySetInnerHTML={{ __html: request.note.replace(/\n/g, "<br>") }} />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Table
                                    key={request.id}
                                    size='small'
                                    columns={columns.slice(0, -1)}
                                    dataSource={request.return_items.map(item => ({
                                        key: item.id,
                                        model: item?.product.model,
                                        service: item?.service.name,
                                        price: item.amount,
                                        qty: item.qty,
                                    }))}
                                    pagination={false}
                                />
                                <Divider dashed className={styles.blackBorder} />
                            </>
                        ))}
                    </Col>
                </Row>
            }
        </>
    )
}
