import React from 'react'
import { Button, message, Modal } from 'antd'
import { CloseCircleOutlined, CopyOutlined, DiffOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import WaitingForConformationApi from '../../../apis/WaitingForConformationApi';

import styles from '../WaitingForConformation.module.scss';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler }) {

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSelectedRows()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteSelectedRows = async () => {
        const updates = {}
        selectedRowKeys.forEach(key => { updates[`waiting-for-conformation/${key}`] = null })
        await WaitingForConformationApi.updateMany(updates)
        setIndex(prevIndex => prevIndex + 1)
        rowsUnCheckHandler();
    }

    const showConfirmDeleteOne = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteOneSelectedRow()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteOneSelectedRow = async () => {
        try {
            const selectFirstItem = selectedRows[0];
            await WaitingForConformationApi.delete(selectFirstItem.key);
            message.success(`${selectFirstItem.userOrName} Waiting For Confirmation is deleted`);
            setIndex(prevIndex => prevIndex + 1)
            rowsUnCheckHandler();
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const copyHandler = async () => {
        if (selectedRows.length <= 0)
            return;

        const copyData = selectedRows[0]
        delete copyData.key
        await WaitingForConformationApi.create(copyData)
        setIndex(prevIndex => prevIndex + 1)
    }

    const duplicateHandler = async () => {
        if (selectedRows.length <= 0)
            return;

        selectedRows.forEach(async (item) => {
            delete item.key
            await WaitingForConformationApi.create(item)
        })
        setIndex(prevIndex => prevIndex + 1)
    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        primary
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>
                    {/* <Button
                        danger
                        onClick={showConfirm}
                        className={styles.button}
                    >
                        <CloseCircleOutlined />
                        Delete
                    </Button> */}
                    {selectedRowKeys.length === 1 &&
                        <>
                            <Button
                                danger
                                onClick={showConfirmDeleteOne}
                                className={styles.button}
                            >
                                <CloseCircleOutlined />
                                Delete One
                            </Button>
                            <Button
                                onClick={copyHandler}
                                className={styles.button}
                            >
                                <CopyOutlined />
                                Copy
                            </Button>
                        </>
                    }
                    <Button
                        onClick={duplicateHandler}
                        className={styles.button}
                    >
                        <DiffOutlined />
                        Duplicates
                    </Button>

                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
