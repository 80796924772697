import { CloseCircleOutlined, CopyOutlined, DiffOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PrinterTwoTone } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import dayjs from 'dayjs';
dayjs.locale('en-gb');
import React, { useState } from 'react';

import DeliveriesApi from '../../../apis/DeliveriesApi';

import { checkExtensionInstalled, doPrint } from '../../../util/doPrint';
import styles from '../Deliveries.module.scss';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        testPrintLabel: false,
    });

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSelectedRows()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteSelectedRows = async () => {
        const updates = {}
        selectedRowKeys.forEach(id => { updates[`deliveries/${id}`] = null })
        await DeliveriesApi.updateMany(updates)
        setIndex(prevIndex => prevIndex + 1)
        rowsUnCheckHandler()
    }

    const showConfirmDeleteOne = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteOneSelectedRow()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteOneSelectedRow = async () => {
        try {
            const selectFirstItem = selectedRows[0];
            await DeliveriesApi.delete(selectFirstItem.key);
            message.success(`${selectFirstItem.userOrName} Delivery is deleted`);
            setIndex(prevIndex => prevIndex + 1)
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const copyHandler = async () => {
        if (selectedRows.length <= 0)
            return;

        const copyData = selectedRows[0]
        delete copyData.key
        await DeliveriesApi.create(copyData)
        setIndex(prevIndex => prevIndex + 1)
    }

    const duplicateHandler = async () => {
        if (selectedRows.length <= 0)
            return;

        selectedRows.forEach(async (item) => {
            delete item.key
            await DeliveriesApi.create(item)
        })
        setIndex(prevIndex => prevIndex + 1)
    }

    const testPrintAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            testPrintLabel: true
        });

        try {
            for (let selectedItem of selectedRows) {
                await doPrint({
                    source: selectedItem.source,
                    dArrival: dayjs(selectedItem.dateOfArrival).format("DD-MM-YYYY"),
                    username: selectedItem.userOrName + ' / ' + selectedItem.repairsService,
                    device: selectedItem.device,
                    qrCode: selectedItem.userOrName,
                }, 1);
            }

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
            rowsUnCheckHandler()
        }

        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
        }
    }

    const printAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            printLabel: true
        });

        try {
            for (let selectedItem of selectedRows) {
                await doPrint({
                    source: selectedItem.source,
                    dArrival: dayjs(selectedItem.dateOfArrival).format("DD-MM-YYYY"),
                    username: selectedItem.userOrName + ' / ' + selectedItem.repairsService,
                    device: selectedItem.device,
                    qrCode: selectedItem.userOrName,
                }, 3);
            }

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
            rowsUnCheckHandler()
        }

        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
        }
    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        type="primary"
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>

                    <Button
                        type="dashed"
                        danger
                        onClick={testPrintAFile}
                        loading={loadingObj.testPrintLabel}
                        className={styles.button}
                    >
                        <PrinterTwoTone twoToneColor='#ff4d4f' />
                        Test
                    </Button>

                    <Button
                        type="primary"
                        ghost
                        onClick={printAFile}
                        loading={loadingObj.printLabel}
                        className={styles.button}
                    >
                        <PrinterTwoTone />
                        Label
                    </Button>

                    {/* <Button
                        danger
                        onClick={showConfirm}
                        className={styles.button}
                    >
                        <CloseCircleOutlined />
                        Delete
                    </Button> */}

                    {selectedRowKeys.length === 1 &&
                        <>
                            <Button
                                danger
                                onClick={showConfirmDeleteOne}
                                className={styles.button}
                            >
                                <CloseCircleOutlined />
                                Delete One
                            </Button>
                            <Button
                                onClick={copyHandler}
                                className={styles.button}
                            >
                                <CopyOutlined />
                                Copy
                            </Button>
                        </>
                    }

                    <Button
                        onClick={duplicateHandler}
                        className={styles.button}
                    >
                        <DiffOutlined />
                        Duplicates
                    </Button>

                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
