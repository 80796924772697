import appApi from "../index";

const endpoint = "bulk-sales";

const list = (url = null, type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, {
        params: {
          type
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

 

const deleteSales = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/delete-bulk-sales`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const updateSales = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/${id}`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


export default {
  list,
  getOne,
  deleteSales,
  updateSales,
};
