import { CopyOutlined, ExclamationCircleOutlined, InfoCircleOutlined, PrinterTwoTone } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Modal, Row, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import DeliveriesApi from '../../../apis/DeliveriesApi';
import ProductApi from '../../../apis/ProductApi';
import { checkExtensionInstalled, doPrint } from '../../../util/doPrint';
import { getAllActualStatus, getAllSource, getStatusByValue } from '../util/common';
import { copyToClipboard } from '../../../util/helpers';
import PostcodeApi from '../../../apis/PostcodeApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeDelivery, handleReset, roles }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();
    const [formOptions, setFormOptions] = useState({
        sources: getAllSource.map(item => ({ value: item })),
        devices: null,
        postcodes: null
    });
    const [loading, setLoading] = useState(false);
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        testPrintLabel: false,
    });

    useEffect(() => {
        const fetchProducts = async () => {
            const products = await ProductApi.listAlias();
            setFormOptions(prevOptions => ({
                ...prevOptions,
                devices: products ? products.map(product => ({ label: product.model, value: product.alias })) : null,
            }));
        };
        fetchProducts();

        return () => {
            setFormOptions(prevOptions => ({
                ...prevOptions,
                devices: null,
            }));
        };
    }, []);


    const getPostcodeAutocomplete = async (searchText) => {
        if (!searchText) return;
        const { result } = await PostcodeApi.autocomplete(searchText);
        if (!result) return;

        if (typeof result === 'object') {
            setFormOptions(prevFormOptions => ({
                ...prevFormOptions,
                postcodes: result.map(r => ({ value: r })),
            }));
        }
    };

    const onFinish = async (values) => {
        console.log(values);
        setLoading(true);
        try {
            await DeliveriesApi.update(activeDelivery.key, {
                source: values.source,
                userOrName: values.userOrName,
                repairService: values.repairService,
                device: values.device,
                dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival, "MM-DD-YYYY").valueOf() : null,
                dateOfDispatch: values.dateOfDispatch ? dayjs(values.dateOfDispatch, "MM-DD-YYYY").valueOf() : null,
                postcode: values.postcode,
                note: values.note ? values.note : null,
                label: values.label,
                cancelled: values.cancelled ? values.cancelled : null,
                status: values.status.value ? values.status?.value : values.status
            });

            message.success(`${activeDelivery?.userOrName} Delivery is updated`);
            setLoading(false);
            handleReset();
        } catch (error) {
            console.log(error)
            setLoading(false);
            if (error.message) {
                message.error(error.message);
            } else
                message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        try {
            await DeliveriesApi.delete(activeDelivery.key);
            message.success(`${activeDelivery.userOrName} Delivery is deleted`);
            handleReset();
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeDelivery.userOrName} - ${activeDelivery.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    const testPrintAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            testPrintLabel: true
        });

        try {
            await doPrint({
                source: activeDelivery.source,
                dArrival: dayjs.tz(activeDelivery.dateOfArrival, "Europe/London").format("DD-MM-YYYY"),
                username: activeDelivery.userOrName + ' / ' + activeDelivery.repairService,
                device: activeDelivery.device,
                qrCode: activeDelivery.userOrName,
            }, 1);

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
        }

        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
        }
    }

    const printAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            printLabel: true
        });

        try {
            await doPrint({
                source: activeDelivery.source,
                dArrival: dayjs.tz(activeDelivery.dateOfArrival, "Europe/London").format("DD-MM-YYYY"),
                username: activeDelivery.userOrName + ' / ' + activeDelivery.repairService,
                device: activeDelivery.device,
                qrCode: activeDelivery.userOrName,
            }, 3);

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
        }

        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
        }
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Modal
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeDelivery?.userOrName}</Col>
                    <Col className="mr-8">
                        <Button
                            onClick={() => {
                                copyToClipboard(`${activeDelivery.userOrName + ' / ' + activeDelivery.repairService} | ${activeDelivery.source} | ${activeDelivery.device} | ${activeDelivery.dateOfArrival ? dayjs.tz(activeDelivery.dateOfArrival, "Europe/London").format("DD-MM-YYYY") : '-'} | ${activeDelivery.dateOfDispatch ? dayjs.tz(activeDelivery.dateOfDispatch, "Europe/London").format("DD-MM-YYYY") : '-'} | ${activeDelivery.postcode} | ${getStatusByValue(activeDelivery.status).label}`)
                            }}
                            className="mr-3"
                        >
                            <CopyOutlined />
                        </Button>
                        <Button
                            type="dashed"
                            danger
                            className="mr-3"
                            size="small"
                            key="test-print"
                            onClick={testPrintAFile}
                            loading={loadingObj.testPrintLabel}
                        >
                            <PrinterTwoTone twoToneColor='#ff4d4f' />
                            Check
                        </Button>
                        <Button
                            type="primary"
                            ghost
                            key="print"
                            onClick={printAFile}
                            loading={loadingObj.printLabel}
                        >
                            <PrinterTwoTone />
                            Label
                        </Button>
                    </Col>
                </Row>
            ]}
            open={visible}
            onOk={onFinish}
            confirmLoading={loading}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            width={1000}
            destroyOnClose
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeDelivery && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                    <Col>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    source: activeDelivery?.source ? activeDelivery.source : null,
                    userOrName: activeDelivery?.userOrName ? activeDelivery.userOrName : null,
                    repairService: activeDelivery?.repairService ? activeDelivery.repairService : null,
                    device: activeDelivery?.device ? activeDelivery.device : null,
                    dateOfArrival: activeDelivery?.dateOfArrival ? dayjs.tz(activeDelivery.dateOfArrival, "Europe/London") : null,
                    dateOfDispatch: activeDelivery?.dateOfDispatch ? dayjs.tz(activeDelivery.dateOfDispatch, "Europe/London") : null,
                    postcode: activeDelivery?.postcode ? activeDelivery.postcode : null,
                    note: activeDelivery?.note ? activeDelivery.note : null,
                    label: activeDelivery?.label ? activeDelivery.label : 'L',
                    cancelled: activeDelivery?.cancelled ? activeDelivery.cancelled : null,
                    status: activeDelivery?.status ? getStatusByValue(activeDelivery.status) : getStatusByValue(1),
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={4}>
                        <Form.Item label="Source" name="source" rules={[{ required: true }]}>
                            <AutoComplete
                                allowClear
                                options={formOptions.sources}
                                filterOption
                            />
                        </Form.Item>
                    </Col>

                    <Col span={20} className='border mb-4'>
                        <Row gutter={[24, 0]}>
                            <Col span={12} className='mb-3'>
                                <Form.Item
                                    className='mb-0'
                                    label="Order Id / User Name"
                                    name="userOrName"
                                    rules={[{ required: true, message: 'Please input Order Id / User Name!' }]}
                                >
                                    <Input size='small' />
                                </Form.Item>
                                <Text type="secondary" strong>Eg: OR5411234</Text>
                            </Col>

                            <Col span={12} className='mb-3'>
                                <Form.Item
                                    className='mb-0'
                                    label="Repair Service"
                                    name="repairService"
                                    rules={[{ required: true, message: 'Please input Repair Service!' }]}
                                >
                                    <Input size='small' />
                                </Form.Item>
                                <Text type="secondary" strong>Eg: FG + LCD + RG + DHRS</Text>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Device" name="device" rules={[{ required: true }]}>
                            <AutoComplete
                                allowClear
                                options={formOptions.devices}
                                placeholder="Select device"
                                loading={!formOptions.devices}
                                showSearch
                                filterOption
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Date of Arrival" name="dateOfArrival" rules={[{ required: true }]}>
                            <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Date of Dispatch" name="dateOfDispatch">
                            <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Post Code" name="postcode" rules={[{ required: true }]}>
                            <AutoComplete
                                allowClear
                                options={formOptions.postcodes}
                                onSearch={getPostcodeAutocomplete}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Note" name="note">
                            <Input.TextArea autoSize />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
                            <Select>
                                {['L', 'S', 'E', 'D'].map((item, idx) => (
                                    <Select.Option key={idx} value={item}>{item}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Cancelled" name="cancelled" tooltip={{ title: 'Order of cancel note', icon: <InfoCircleOutlined /> }}>
                            <Input.TextArea autoSize />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                            <Select allowClear>
                                {getAllActualStatus.map((item, idx) => (
                                    <Option key={idx} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
