import React from "react";
import { Card, Typography, List, Button, DatePicker, Col } from 'antd';
import ReactApexChart from "react-apexcharts";
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Title, Text, Paragraph } = Typography;

const SalesStatus = ({ salesData, dateRange, handleDateChange }) => {
    const history = useHistory();

    const paidOrder = Array.isArray(salesData?.sales) ? salesData.sales.find(sale => sale.key === 'paid') : null;
    const unpaidOrder = Array.isArray(salesData?.sales) ? salesData.sales.find(sale => sale.key === 'unpaid') : null;


    const lineChart = {
        series: [paidOrder?.value || 0, unpaidOrder?.value || 0],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ["Paid Orders (£)", "Unpaid Orders (£)"],
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    };

    const orderDetails = paidOrder ? [
        { label: 'Paid Orders', value: `${paidOrder.count} Order(s)` },
        { label: 'Amount', value: `£ ${paidOrder.value}` },
        { label: 'Priority Order Count', value: paidOrder.priorityOrderCount },
        { label: 'Priority Charge', value: `£ ${paidOrder.priorityCharge}` },
        { label: 'Shipping Order Count', value: paidOrder.shippingOrderCount },
        { label: 'Shipping Charge', value: `£ ${paidOrder.shippingCharge}` },
        { label: 'Net Amount', value: `£ ${paidOrder.netTotal}` },
    ] : [];

    const unpaidOrderDetails = unpaidOrder ? [
        { label: 'Count', value: `${unpaidOrder.count} Order(s)` },
        { label: 'Amount', value: `£ ${unpaidOrder.value}` },
    ] : [];

    const handleOrderTypeChange = (type) => {
        if (type === 'paid') {
            history.push('/orders');
        } else if (type === 'unpaid') {
            history.push('/orders');
        }
    };

    return (
        <>
            {salesData &&
                <Col xs={24} xl={24} sm={24} md={24}>
                    <Card bordered={false} className="circlebox">
                        <div className='d-flex justify-space-between'>
                            <Title level={4} strong>Sales Status</Title>
                            <div>
                                <Button type="primary" style={{ marginRight: '10px' }} onClick={() => handleOrderTypeChange('paid')}>Paid</Button>
                                <Button type="danger" onClick={() => handleOrderTypeChange('unpaid')}>Unpaid</Button>
                            </div>
                        </div>
                        <Paragraph className="lastweek">
                            {dateRange.length === 2 && dateRange.every(date => date && date.isValid()) && (
                                <Text>
                                    {`${dateRange[0].format('YYYY/MM/DD')} - ${dateRange[1].format('YYYY/MM/DD')}`}
                                </Text>
                            )}
                        </Paragraph>
                        <div className="mt-2 mb-4">
                            <RangePicker
                                value={dateRange}
                                onChange={handleDateChange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%', marginTop: '50px' }}>
                                <ReactApexChart options={lineChart.options} series={lineChart.series} type="donut" />
                            </div>

                            <div className="w-100">
                                {paidOrder &&
                                    <>
                                        <Title level={4}>Paid Orders</Title>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={orderDetails}
                                            renderItem={item => (
                                                <List.Item>
                                                    <Text>{item.label} - </Text><Text strong>{item.value}</Text>
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                }
                                {unpaidOrder &&
                                    <>
                                        <Title level={4} style={{ marginTop: '20px' }}>Unpaid Orders</Title>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={unpaidOrderDetails}
                                            renderItem={item => (
                                                <List.Item>
                                                    <Text>{item.label} - </Text><Text strong>{item.value}</Text>
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </Card>
                </Col>
            }
        </>
    );
};

export default SalesStatus;
