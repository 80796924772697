import React from 'react';
import { Avatar, Button } from 'antd';
import { Comment } from '@ant-design/compatible';
import { ClockCircleOutlined, CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import styles from './ChatBubble.module.scss';

import { isRoleAvailable } from './util/isRoleAvailable';

dayjs.extend(relativeTime);

const ChatBubble = ({ message, sender, timestamp }) => {

    const handleCopyClick = () => {
        const el = document.createElement('textarea');
        el.value = message;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <Comment
            actions={[<>{sender === 'assistant' || sender === 'model' && <Button key="comment-nested-reply-to" onClick={handleCopyClick} className="cursor-pointer"><CopyOutlined /> Copy</Button>}</>]}
            avatar={<Avatar src={`https://eu.ui-avatars.com/api/?name=${sender}&size=120`} alt={sender} />}
            author={sender}
            content={<p className={styles['chat-bubble' + (isRoleAvailable && '-' + sender)]}>
                {message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </p>}
            datetime={
                <span>
                    <ClockCircleOutlined style={{ marginRight: 8 }} />
                    {dayjs.unix(timestamp).fromNow()}
                </span>
            }
            className={styles['chat-bubble-container' + (isRoleAvailable && '-' + sender)]}
        />
    );
};

export default ChatBubble;