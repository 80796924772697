import React from 'react';
import { CloseCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import dayjs from 'dayjs';
dayjs.locale('en-gb');
import { useSelector } from "react-redux";

import styles from '../Invoice.module.scss';

import InvoiceApi from '../../../apis/invoice/InvoiceApi';
import PaypalApi from '../../../apis/invoice/PaypalApi';
import StripeApi from '../../../apis/invoice/StripeApi';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler, type }) {
    const baseUrl = useSelector((state) => state.auth.base_url);

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSelectedRows()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteSelectedRows = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', selectedRowKeys);

        try {
            for (const key of selectedRowKeys) {
                try {
                    await InvoiceApi.deleteOne(baseUrl, key);

                } catch (error) {
                    message.error('Something went wrong!');
                }
            }
            message.success('Invoice is Deleted!');
            setIndex(prevIndex => prevIndex + 1)
            rowsUnCheckHandler()
        } catch (error) {
            message.error('Something went wrong!');
        }

    }

    const reminderSelectedRows = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', selectedRowKeys);

        try {
            let hasError = false;
            for (const key of selectedRowKeys) {
                try {
                    if (type === "stripe") {
                        await StripeApi.reminder(baseUrl, key);
                    } else {
                        await PaypalApi.reminder(baseUrl, key);
                    }
                } catch (error) {
                    hasError = true;
                    message.error('Something went wrong!');
                    break;
                }
            }

            if (!hasError) {
                message.success('Reminder successfully!');
                setIndex(prevIndex => prevIndex + 1);
                rowsUnCheckHandler();
            }


        } catch (error) {
            message.error('Something went wrong!');
        }
    }


    const cancelSelectedRows = async () => {
        confirm({
            title: 'Are you sure cancel this Invoice(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Invoice(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {

                const formData = new FormData();
                formData.append('selectedRowIds', selectedRowKeys);

                try {
                    let hasError = false;

                    for (const key of selectedRowKeys) {
                        try {
                            if (type === "stripe") {
                                await StripeApi.cancelInvoice(baseUrl, key);
                            } else {
                                await PaypalApi.cancelInvoice(baseUrl, key);
                            }
                        } catch (error) {
                            hasError = true;
                            message.error(error?.message);
                            break;
                        }
                    }

                    if (!hasError) {
                        message.success('Invoice canceled successfully!');
                        setIndex(prevIndex => prevIndex + 1);
                        rowsUnCheckHandler();
                    } else {
                        setIndex(prevIndex => prevIndex + 1);
                    }
                } catch (error) {
                    message.error('Something went wrong!');
                }
            },
            onCancel() {
                //
            },
        });
    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        type="primary"
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>


                    {selectedRowKeys.length === 1 &&
                        <>
                            {type === 'paypal' || type === 'stripe' ?
                                <>
                                    <Button
                                        type="dashed" danger
                                        onClick={reminderSelectedRows}
                                        className={styles.button}
                                    >
                                        Reminder Mail
                                    </Button>

                                    {(type === 'stripe' && selectedRows[0]?.status !== 'void') ||
                                        (type === 'paypal' && selectedRows[0]?.status !== 'CANCELLED') && (
                                            <Button
                                                danger
                                                onClick={cancelSelectedRows}
                                                className={styles.button}
                                            >
                                                {type === 'stripe' ? 'Void' : 'Cancel'} Invoice
                                            </Button>

                                        )}
                                </>
                                :
                                <>
                                    <Button
                                        danger
                                        onClick={showConfirm}
                                        className={styles.button}
                                    >
                                        <CloseCircleOutlined />
                                        Delete
                                    </Button>

                                </>
                            }
                        </>

                    }

                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
