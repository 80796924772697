import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Form,
  Typography,
  Select,
  message,
  Input
} from "antd";
import qs from 'qs';
import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.locale('en-gb');

import styles from './Invoice.module.scss';

import { useSelector } from 'react-redux';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import SelectedOptions from "./Components/SelectedOptions";
import { SiteOptions } from '../../components/layout/util/common';
import useLogin from '../../hooks/useLogin';
import InvoiceApi from '../../apis/invoice/InvoiceApi';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Europe/London');

const { Text } = Typography;

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function Notifications(props) {
  useDocumentTitle(props.title);
  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [form] = Form.useForm();
  const [tableTab, setTableTab] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [filterNotifications, setFilterNotifications] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_paid: null
  });
  const queryRef = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const [visible, setVisible] = useState(false);
  const [activeNotification, setActiveNotification] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm:ss A')
    },
    {
      title: "Order #",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Type",
      key: "pay_type",
      dataIndex: "pay_type",
    },
    {
      title: "Invoice No.",
      key: "invoice_no",
      dataIndex: "invoice_no",

    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
    },
  ];

  const fetchNotification = useCallback((isCancelled) => {
    console.log(isCancelled);
    setLoading(true);
    InvoiceApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: reasonData }) => {
        console.log(reasonData);

        if (!isCancelled) {
          setNotifications(reasonData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query),
            pagination: {
              ...prevTableParams.pagination,
              current: reasonData.current_page,
              pageSize: reasonData.per_page,
              total: reasonData.total,
            },
          }));
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchNotification(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams)]);

  const showModal = (item) => {
    setVisible(true);
    setActiveNotification(item);
  };

  const handleReset = () => {
    setActiveNotification(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveNotification(null);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setNotifications([]);
    }
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }
  const pageLoading = () => {
    setTableTab(0);
    setKey(prevKey => prevKey + 1)
    setFilterNotifications(null);
    form.setFieldsValue({
      filterTable: null,
    });
  };

  const handleBaseUrlChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };


  const search = value => {
    const filterTable = notifications.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    console.log(value);
    console.log(filterTable);

    setFilterNotifications(filterTable);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">

              <Card
                bordered={false}
                className={`criclebox tablespace ${styles.table}`}
                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <Row justify="space-between" align="top">
                  <Col md={18} className='d-inline-flex'>
                    <Button
                      icon={<SyncOutlined />}
                      loading={loading}
                      onClick={pageLoading}
                      className="mr-2 px-2"
                    />
                    <Select
                      value={baseUrl}
                      onChange={handleBaseUrlChange}
                      className="mr-4"
                      style={{ width: 260 }}
                      options={SiteOptions}
                      disabled={loading}
                    />
                    <Form form={form}>
                      <Form.Item name="filterTable"  >
                        <Input.Search
                          ref={queryRef}
                          placeholder="Search by Scenario, Instruction for"
                          className={`w-100 ${styles.search}`}
                          onSearch={search}
                          allowClear
                          disabled={loading}
                        />
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col md={6} className='d-flex'>
                    <Button.Group>
                      <Link to="/invoice/stripe">
                        <Button key="2">
                          Stripe
                        </Button>
                      </Link>
                      <Link to="/invoice/paypal">
                        <Button key="3">
                          Paypal
                        </Button>
                      </Link>
                      <Link to="/invoice">
                        <Button key="1" type='primary' ghost>
                          All
                        </Button>
                      </Link>
                    </Button.Group>

                    <Link to="/invoice/create">
                      <Button
                        key="1"
                        type="primary"
                        className='ml-3'
                      >
                        Create Invoice
                      </Button>
                    </Link>
                  </Col>
                </Row>

                <div className="table-responsive">
                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    dataSource={filterNotifications === null ? notifications : filterNotifications}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [100, 200, 300, 400, 500],
                      defaultPageSize: tableParams.pagination.pageSize,
                      position: ["bottomRight"],
                      ...tableParams.pagination
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    onChange={handleTableChange}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>


      </div>
    </>
  );
}