import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Typography, message, Drawer, Table, Spin } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LoadingOutlined } from "@ant-design/icons";
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import WorkforceApi from '../../../apis/WorkforceApi';
import QuickDesignationCreate from './QuickDesignationCreate';

dayjs.extend(utc)
dayjs.extend(timezone)
const { Text } = Typography;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickModelDesignation({ visibleDesignation, handleCancel, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [designations, setWorkforce] = useState(null);
    const [activeDesignations, setActiveDesignations] = useState(null);
    const [key, setKey] = useState(0);
    const [selectedValues, setSelectedValues] = useState({
        selectedRowKeys: [],
        batchRecord: []
    });
    const [visibleDesignationCreate, setVisibleDesignationCreate] = useState(false);

    const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;
    const mountData = async () => {
        const { data: list } = await WorkforceApi.DesignationsList(baseUrl);
        setWorkforce(list.reverse());

    }
    useLayoutEffect(() => {
        mountData();
    }, [key]);

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
        },
        {
            title: "Description",
            key: "description",
            dataIndex: "description",
        }
    ];

    const rowSelection = {
        selectedRowKeys: selectedValues.selectedRowKeys,
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE
        ]
    }

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [selectedValues?.selectedRowKeys]);

        try {
            const deleteDesignation = await WorkforceApi.deleteDesignation(baseUrl, formData);
            if (deleteDesignation) {
                message.success('Designation is Deleted!');
                setKey(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedValues?.selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    const createDesignation = () => {
        setActiveDesignations(null);
        setVisibleDesignationCreate(true);
    };

    const showModal = (item) => {
        setActiveDesignations(item);
        setVisibleDesignationCreate(true);
    };

    const handleCancelDesignationCreate = () => {
        setVisibleDesignationCreate(false);
    };
    return (
        <>
            <Drawer
                push={false}
                key="notification-details-model"
                title={<Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                        <Text>Designations</Text>

                        <div>
                            <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                            <Button
                                className='mx-2'
                                form='delivery-update'
                                type="primary"
                                onClick={createDesignation}
                                loading={loading}
                            >
                                Create Designation
                            </Button>
                        </div>
                    </Col>
                </Row>}

                open={visibleDesignation}
                onClose={handleCancel}
                onCancel={handleCancel}
                afterClose={handleCancel}
                centered
                destroyOnClose
                width={750}
                footer={[
                    <Row
                        key="row"
                        align="middle"
                        justify="space-between"
                    >
                        <Col>
                            {selectedValues?.selectedRowKeys.length > 0 &&
                                <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                            }
                        </Col>
                    </Row>
                ]}
            >
                <Form
                    {...layout}
                    form={form}
                    id="delivery-update"
                    name="nest-messages"
                    validateMessages={validateMessages}
                >

                    <Row gutter={[24, 0]} >

                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                rowSelection={rowSelection}
                                dataSource={designations === null ? designations : designations}
                                loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                                pagination={{
                                    showSizeChanger: true,
                                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                                    showQuickJumper: true,
                                    pageSizeOptions: [100, 75, 50, 20, 10],
                                    defaultPageSize: 100,
                                    position: ["bottomRight"]
                                }}
                                className="ant-border-space"
                                size="small"
                                ellipsis
                                responsive
                                onRow={(record) => {
                                    return {
                                        onClick: (_) => {
                                            showModal(record)
                                        }
                                    };
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            {visibleDesignationCreate &&
                <QuickDesignationCreate
                    visibleDesignationCreate={visibleDesignationCreate}
                    handleCancel={handleCancelDesignationCreate}
                    activeDesignations={activeDesignations}
                    handleReset={handleReset}
                    roles={roles}
                    setIndex={setKey}
                />
            }
        </>

    )
}

