import { Typography } from 'antd';
import React from 'react'

import styles from '../Orders.module.scss';

const { Paragraph } = Typography;

export default function ListItem({ keyText, keyCopyable, valueText, valueCopyable, strikethrough }) {
    const classNames = [styles.paragraph];
    if (strikethrough) {
        classNames.push(styles.strikethrough);
    }

    return (
        <div className={styles.ListItemWrap}>
            {keyText && <Paragraph className={classNames.join(' ')} copyable={keyCopyable}>{keyText}</Paragraph>}
            {valueText && <Paragraph className={classNames.join(' ')} copyable={valueCopyable}>{valueText}</Paragraph>}
        </div>
    )
}
