import { store } from "./index";

export function getBaseUrlFromStore() {
    return store.getState().auth.base_url;
}

export function getUserDataFromStore() {
    // Get the user data from the state using optional chaining (?.)
    const userData = store.getState()?.auth?.user;
    const userInfo = Array.isArray(userData) ? userData[0] : null;

    // Use nullish coalescing (??) to provide default values in case userData is null or undefined
    const email = userInfo?.email ?? '';
    const roles = userInfo?.roles ?? [];

    return {
        email,
        roles,
    };
}