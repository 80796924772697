import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, message, Select } from 'antd';

import EditableContext from './EditableContext';
import { getAllOrderStatus, getAllWFCStatus } from '../../util/common'

const { Option } = Select;

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSaveOrderStatus,
    handleSaveWFCStatus,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [loadingObj, setLoadingObj] = useState({
        updateOrder: false,
    });
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        let isCancelled = false

        if (editing) {
            inputRef.current.focus();
        }

        return () => {
            isCancelled = true
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        setLoadingObj({ ...loadingObj, updateOrder: true });
        try {
            const values = await form.validateFields();
            toggleEdit();

            if (dataIndex === 'status') {
                await handleSaveOrderStatus({
                    id: record.id,
                    order_no: record.order_no,
                    ...values,
                });
            }

            if (dataIndex === 'wfc_status') {
                await handleSaveWFCStatus({
                    id: record.id,
                    order_no: record.order_no,
                    ...values,
                });
            }

            setLoadingObj({ ...loadingObj, updateOrder: false });
        } catch (errInfo) {
            setLoadingObj({ ...loadingObj, updateOrder: false });
            message.error('Something went wrong!');
        }
    };

    let field = (
        <>
            {dataIndex === 'status' &&
                <Form.Item
                    className='m-0'
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Select
                        ref={inputRef}
                        onChange={save}
                    >
                        {getAllOrderStatus.slice(1).map((item, idx) => (
                            <Option key={idx} value={item.value} disabled={record.status === 6 || item.value === 6}>{item.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            }

            {dataIndex === 'wfc_status' &&
                <Form.Item
                    className='m-0'
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Select
                        ref={inputRef}
                        onChange={save}
                    >
                        {(getAllWFCStatus.slice(1)).map((item, idx) => (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            }
        </>
    );

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <>
                {field}
            </>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;