import appApi from "./index";
import qs from 'qs';
import { API_ENDPOINT1 } from "../constants/varibales";

const endpoint = "workforce"


const EmployeeList = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const list = (url = null, type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, {
        params: {
          type
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createEmployee = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/create-employee`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteEmployee = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/delete-employee`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const DesignationsList = (url = null, type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/designations-list`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createDesignation = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/designations-list/create`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteDesignation = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/designations-list/delete`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

export default {
  list,
  EmployeeList,
  createEmployee,
  deleteEmployee,
  DesignationsList,
  createDesignation,
  deleteDesignation
};