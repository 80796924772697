import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, List, message, Radio, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import InvoiceApi from '../../../../apis/order-management/InvoiceApi';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Text } = Typography;

export default function Invoice({ activeOrder, baseUrl }) {
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [subTotal, setSubTotal] = useState(0);
    const [invoiceLink, setInvoiceLink] = useState(null);

    const [loadingObj, setLoadingObj] = useState({
        generateInvoice: false
    });

    const onFinish = async (values) => {
        setLoadingObj({ ...loadingObj, generateInvoice: true });
        setInvoiceLink(null);

        try {
            const formData = new FormData();
            formData.append('ticket_id', values?.ticket_id ?? "");
            formData.append('orderId', activeOrder.id);
            values.devices.forEach((value) => {
                formData.append('service[]', value.service);
                formData.append('qty[]', value.qty);
                formData.append('amount[]', value.amount);
                formData.append('note[]', value.note);
            });
            const invoice = await InvoiceApi.create(baseUrl, values.payment_type, formData);
            if (invoice) {
                message.success(invoice.message);
                setLoadingObj({ ...loadingObj, generateInvoice: false });
                setInvoiceLink(invoice.data?.share_link);
                setSubTotal(0);
                form.resetFields();
            }
        } catch (error) {
            setLoadingObj({ ...loadingObj, generateInvoice: false });
            setInvoiceLink(null);
            message.error('Something went wrong!');
        }
    };

    const handleFieldsChange = (_, allFields) => {
        let newSubTotal = 0;
        if (allFields.devices) {
            allFields.devices.forEach(field => {
                const { amount, qty } = field;
                newSubTotal += amount * qty;
            });
        }
        setSubTotal(newSubTotal);
    };

    return (
        <>
            {activeOrder.order_repair &&
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={18}>
                        <Form
                            {...layout}
                            form={form}
                            name="nest-messages"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            initialValues={{
                                devices: [{ service: '', amount: 0, qty: 1, note: '' }],
                                payment_type: (activeOrder?.payment_type === 3 ? 'stripe' : 'paypal')
                            }}
                            onValuesChange={handleFieldsChange}
                        >
                            <Form.Item name="payment_type" className="collection-create-form_last-form-item">
                                <Radio.Group>
                                    <Radio value="stripe">Stripe</Radio>
                                    <Radio value="paypal">Paypal</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.List name="devices">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    marginBottom: 8,
                                                }}
                                                align="start"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'service']}
                                                    label=""
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing Item Name',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Item Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    label=""
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing qty',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber size="large" min={1} placeholder="Qty" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'amount']}
                                                    label=""
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing amount',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber size="large" placeholder="Amount" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'note']}
                                                    label=""
                                                >
                                                    <Input.TextArea placeholder="Note" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item label="">
                                            <Button
                                                size='middle'
                                                shape="round"
                                                type="dashed"
                                                onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                                icon={<PlusOutlined />}
                                            >
                                                Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Col span={8}>
                                <Form.Item name="ticket_id">
                                    <Input placeholder="Follow-up ticket id (Optional)" />
                                </Form.Item>
                            </Col>

                            <div className={styles.modelBtn}>
                                <Button
                                    className={styles.submitBtn}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadingObj.generateInvoice}
                                >
                                    Generate Invoice
                                </Button>
                            </div>

                            {invoiceLink &&
                                <div style={{ marginTop: '1rem' }}>
                                    <a
                                        href={invoiceLink}
                                        target='_blank'
                                        rel="noreferrer">
                                        {invoiceLink}
                                    </a>
                                </div>
                            }
                        </Form>
                    </Col>
                    <Col span={6}>
                        <List
                            size="small"
                            header={<Text>Invoice Summery</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={1} keyText="Sub Total : " valueText={'£' + subTotal.toFixed(2)} />,
                                <ListItem key={2} keyText="Total : " valueText={'£' + subTotal.toFixed(2)} />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                </Row>
            }
        </>
    )
}
