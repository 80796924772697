const endpoint = "postcodes";

const PostcodeApi = {
    autocomplete: (postcode) => {
        return new Promise((resolve, reject) => {
            fetch(`https://api.postcodes.io/${endpoint}/${postcode}/autocomplete`)
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(error => {
                    if (error.response) {
                        reject(error.response.data);
                    } else if (error.request) {
                        reject(error);
                    } else {
                        reject(error);
                    }
                })
        })
    },
};

export default PostcodeApi;