import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Space, message } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';

const { useForm } = Form;

export default function UpdateOrderItem({ visible, handleCancel, activeOrderId, setKey, setActiveKey }) {
    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();

    const [loadingObj, setLoadingObj] = useState({
        previewLoading: false,
        sendLoading: false,
    });

    return (
        <Drawer
            open={visible}
            title="Add New Item"
            placement='left'
            onClose={handleCancel}
            width={1200}
            destroyOnClose
            extra={
                <Space>
                    <Button
                        type="default"
                        onClick={() => {
                            form.resetFields();
                        }}>
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        loading={loadingObj.sendLoading}
                        onClick={() => {
                            setLoadingObj(prevState => ({ ...prevState, sendLoading: true }));
                            form
                                .validateFields()
                                .then(async (values) => {
                                    values.OrderId = activeOrderId;
                                    const response = await DirectOrdersApi.createNewItem(baseUrl, values);
                                    handleCancel();
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                    message.success(response.success);

                                    // Refresh the orders list
                                    setKey(prevKey => prevKey + 1);

                                    // Refresh the order details
                                    setActiveKey(prevKey => prevKey + 1);

                                    form.resetFields();
                                })
                                .catch((info) => {
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                });
                        }}>
                        Save
                    </Button>
                </Space>
            }
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            devices: [{ qty: 1, amount: 0 }],
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.List name="devices">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Row gutter={24} key={key} className='pt-2'>
                                                        <Col span={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'model']}
                                                                label={`Model ${key + 1}`}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing Model Name',
                                                                        },
                                                                    ]}
                                                            >
                                                                <Input placeholder="Model Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'imei_no']}
                                                                label="Imei No"
                                                            >
                                                                <Input placeholder="Imei No" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3} className='d-none'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'qty']}
                                                                label="Qty"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing qty',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber size="large" min={1} placeholder="Qty" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'fault_note']}
                                                                label="Service Required"
                                                            >
                                                                <Input.TextArea placeholder="Service Required" rows={3} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'password']}
                                                                label="Password"
                                                            >
                                                                <Input placeholder="Password" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'collection_time']}
                                                                label="Collection Time"
                                                            >
                                                                <Input placeholder="Collection Time" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3} >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'note']}
                                                                label="Note" >
                                                                <Input.TextArea placeholder="Note" rows={3} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="Amount"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing amount',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    size="large"
                                                                    className='w-100'
                                                                    placeholder="Amount"
                                                                    min={0}
                                                                    formatter={(value) => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={1} className='d-flex align-center justify-start'>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Col>

                                                    </Row>
                                                </>
                                            ))}
                                            <Form.Item label="">
                                                <Button
                                                    size='middle'
                                                    shape="round"
                                                    type="dashed"
                                                    className='mt-4'
                                                    onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Drawer>
    );
}
