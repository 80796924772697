import React, { useState } from 'react'
import { Button, message, Modal, notification } from 'antd'
import { CloseCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import styles from '../Orders.module.scss';

import OrdersApi from '../../../apis/order-management/OrdersApi';
import { checkExtensionInstalled, doPrint } from '../../../util/doPrint';
import { store } from '../../../store';
import { addShippingOrder } from '../../../store/slices/shippingOrdersSlice';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler, baseUrl, handleHide }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        testPrintLabel: false,
    });

    const showConfirmDeleteOne = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteOneSelectedRow()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteOneSelectedRow = async () => {
        try {
            const selectFirstItem = selectedRows[0];
            await OrdersApi.deleteOne(baseUrl, selectFirstItem.id);
            message.success(`${selectFirstItem.order_no} - Order is deleted`);
            setIndex(prevIndex => prevIndex + 1)
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const testPrintAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            testPrintLabel: true
        });

        const activeOrder = await OrdersApi.getOneWithOrderRepair(baseUrl, selectedRows[0].id);
        try {
            if (!activeOrder || !activeOrder.order_repair)
                throw new Error('Something went wrong!');

            let productIds = [];
            let products = [];
            activeOrder.order_repair.forEach((repair) => {
                let repairServices = [];
                if (!productIds.includes(repair.products.id)) {
                    repairServices['product'] = repair.products;
                    repairServices['product']['order_qty'] = repair.qty;

                    let specServices = [];
                    specServices.push(repair.service);
                    repairServices['product']['services'] = specServices;

                    productIds.push(repair.products.id);
                    products.push(repairServices);
                } else {
                    let key = productIds.indexOf(repair.products.id);
                    let specServices = products[key]['product']['services'];
                    specServices.push(repair.service);
                    products[key]['product']['services'] = specServices;
                }
            });

            for (const product of products) {
                for (let i = 1; i <= product['product'].order_qty; i++) {
                    await doPrint({
                        source: "FMBS",
                        dArrival: dayjs(activeOrder.updated_at).format("DD-MM-YYYY"),
                        username: (activeOrder.order_no + ' / ' + product['product']['services'].map((item) => item.alias).join(' + ')),
                        device: (product['product'].alias.length > 0 ? product['product'].alias : product['product'].model),
                        qrCode: activeOrder.order_no,
                    }, 1);
                }
            }

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                testPrintLabel: false
            });
        }
    }

    const addShippingOrderFunction = async () => {

        const state = store.getState();
        const existingOrders = state.shippingOrders.shippingOrders;

        selectedRowKeys.forEach(orderId => {
            const orderExists = existingOrders.some(order => order.id === orderId);

            if (!orderExists) {
                store.dispatch(addShippingOrder({ id: orderId }));                
            }
        });
        notification.info({
            message: `Items added!`,
            description: (
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    handleHide();
                }} style={{ textDecoration: 'underline' }}>
                    View Bulk Actions
                </a>
            ),
            placement: 'bottomRight',
            duration: 3
        });
        rowsUnCheckHandler();
    }
    const printAFile = async () => {
        if (!checkExtensionInstalled()) return;

        setLoadingObj({
            ...loadingObj,
            printLabel: true
        });

        const activeOrder = await OrdersApi.getOneWithOrderRepair(baseUrl, selectedRows[0].id);
        try {
            if (!activeOrder || !activeOrder.order_repair)
                throw new Error('Something went wrong!');

            let productIds = [];
            let products = [];
            activeOrder.order_repair.forEach((repair) => {
                let repairServices = [];
                if (!productIds.includes(repair.products.id)) {
                    repairServices['product'] = repair.products;
                    repairServices['product']['order_qty'] = repair.qty;

                    let specServices = [];
                    specServices.push(repair.service);
                    repairServices['product']['services'] = specServices;

                    productIds.push(repair.products.id);
                    products.push(repairServices);
                } else {
                    let key = productIds.indexOf(repair.products.id);
                    let specServices = products[key]['product']['services'];
                    specServices.push(repair.service);
                    products[key]['product']['services'] = specServices;
                }
            });

            for (const product of products) {
                for (let i = 1; i <= product['product'].order_qty; i++) {
                    await doPrint({
                        source: "FMBS",
                        dArrival: dayjs(activeOrder.updated_at).format("DD-MM-YYYY"),
                        username: (activeOrder.order_no + ' / ' + product['product']['services'].map((item) => item.alias).join(' + ')),
                        device: (product['product'].alias.length > 0 ? product['product'].alias : product['product'].model),
                        qrCode: activeOrder.order_no,
                    }, 3);
                }
            }

            message.success('Label is printed!');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Label printing is failed, something went wrong.');
            setLoadingObj({
                ...loadingObj,
                printLabel: false
            });
        }
    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        primary
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>

                    <Button
                        primary
                        onClick={addShippingOrderFunction}
                        className={styles.button}
                    >
                        <PlusCircleOutlined />
                        Add Bulk Actions
                    </Button>

                    {selectedRowKeys.length === 1 &&
                        <>
                            <Button
                                danger
                                onClick={showConfirmDeleteOne}
                                className={styles.button}
                            >
                                <CloseCircleOutlined />
                                Delete One
                            </Button>
                            <Button
                                type="primary"
                                ghost
                                onClick={printAFile}
                                loading={loadingObj.printLabel}
                                className={styles.button}
                            >
                                Print All Label
                            </Button>
                            <Button
                                type="dashed"
                                danger
                                onClick={testPrintAFile}
                                loading={loadingObj.testPrintLabel}
                                className={styles.button}
                            >
                                Test Print
                            </Button>
                        </>
                    }

                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
