import { Menu, Tooltip } from "antd";
import _ from 'lodash';
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { SiteOptions } from "./util/common";

import logo from "../../assets/images/logo.png";
import menu from "./util/menu";

function Sidenav({ color, setVisible }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const baseUrl = useSelector((state) => state.auth.base_url);

  const generateMenuItem = (name, text = null, icon) => {
    return {
      label: (
        <NavLink to={`/${name}`} onClick={() => setVisible(false)} title={_.startCase(_.toLower(name))}>
          <Tooltip title={text ?? _.startCase(_.toLower(name))}>
            <span
              className="icon"
              style={{
                background: page === name ? color : "",
              }}
            >
              {icon}
            </span>
            <span className="label">{text ?? _.startCase(_.toLower(name))}</span>
          </Tooltip>
        </NavLink>
      ),
      key: name,
    };
  };

  function removeMenuItems(removedMenuItems, menuItems) {
    return menuItems.filter(item => !removedMenuItems.includes(item.key));
  }

  const generateMenuItems = useCallback(() => {
    const fmbsBaseUrl = SiteOptions.find(site => site.brand === 'FMBS').value;
    const tradeBaseUrl = SiteOptions.find(site => site.brand === 'BUSINESS').value;

    const baseMenuItems = menu.map(item => {
      const Icon = item.icon;
      return generateMenuItem(item.key, item.label, <Icon />);
    });

    let filteredMenuItems = baseMenuItems;
    if (baseUrl === tradeBaseUrl) {
      const removedMenuItems = ['recycle-orders', 'bulk-repair', 'bulk-recycle'];
      filteredMenuItems = removeMenuItems(removedMenuItems, filteredMenuItems);
    }

    if (baseUrl !== fmbsBaseUrl) {
      const removedMenuItems = ['direct-orders'];
      filteredMenuItems = removeMenuItems(removedMenuItems, filteredMenuItems);
    }
    return filteredMenuItems;
  }, [baseUrl]);

  const memoizedMenuItems = useMemo(() => generateMenuItems(), [generateMenuItems]);

  return (
    <>
      <div className="brand">
        <NavLink to="/" onClick={() => setVisible(false)}>
          <img src={logo} alt="FMBS CRM" />
          <span>FMBS CRM</span>
        </NavLink>
      </div>
      <hr />
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['1']}
        items={memoizedMenuItems}
      />
    </>
  );
}

export default Sidenav;
