import { Typography, Input, Button, Flex, message } from 'antd';
import React, { useState } from 'react';
import styles from '../Orders.module.scss';

const { Paragraph } = Typography;

export default function ListItem({ keyText, keyCopyable, valueText, valueCopyable, icon, onUpdate, fieldType, showPayment, showCost, onToggleDetails, activeOrder, type, bgcolor }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(valueText);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const validateValue = (value) => {
        switch (fieldType) {
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return 'Invalid email format.';
                }
                break;
            case 'phone_number':
                if (!/^\d{10,12}$/.test(value)) {
                    return 'Invalid phone number format.';
                }
                break;
            default:
                return null;
        }
    };

    const handleSaveClick = () => {
        const validationError = validateValue(editValue);
        if (validationError) {
            message.error(validationError);
            return;
        }

        setIsEditing(false);
        if (onUpdate) {
            onUpdate(fieldType, editValue);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditValue(valueText);
    };

    return (
        <div className={styles.ListItemWrap} >
            {keyText && <Paragraph className={styles.paragraph} copyable={keyCopyable}>
                {onToggleDetails && (
                    <span style={{ cursor: 'pointer' }} className='pr-2' type="link" onClick={onToggleDetails}>
                        {(showCost || showPayment) ? '-' : '+'}
                    </span>
                )}
                {keyText}</Paragraph>}
            {isEditing ? (
                <div className={` ${styles.valueContainer}`}>
                    <Input
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        style={{ marginRight: '8px' }}
                    />
                    <div className={`d-flex mt-2 ${styles.valueContainer}`}>
                        <Button onClick={handleSaveClick} type="primary">Save</Button>
                        <Button onClick={handleCancelClick} style={{ marginLeft: '8px' }}>Cancel</Button>
                    </div>
                </div>
            ) : (
                <div className={`d-flex  ${styles.valueContainer}`} style={{ alignItems: 'center' }}>
                    <Paragraph className={`${styles.paragraph}`} copyable={valueCopyable}>
                        {type === "cost" ? `£${activeOrder?.repair_cost}` : type === "payment" ? `£${activeOrder?.deposit_amount}` : valueText}
                    </Paragraph>

                    {icon && (
                        <span
                            className={`pl-1 cursor-pointer`}
                            style={{ color: '#1677ff' }}
                            onClick={handleEditClick}
                        >
                            {icon}
                        </span>
                    )}
                </div>
            )}
            {(showCost && activeOrder) && (
                <>
                    {(type === "cost") && (
                        activeOrder?.costs.map((item, index) => (
                            <Flex
                                key={index}
                                className={`${styles.orderSummary} w-100 pb-1 ${index === activeOrder?.costs.length - 1 ? 'border-0' : 'border-bottom-dashed'}`}
                                justify='space-between'
                            >
                                <div>
                                    <p>{item?.description}</p>
                                </div>
                                <div>
                                    <p>£{item?.amount}</p>
                                </div>
                            </Flex>
                        ))
                    )}
                </>

            )}
            {(showPayment && activeOrder) && (
                <>
                    {(type === "payment") && (
                        activeOrder?.payments.map((item, index) => (
                            <Flex
                                key={index}
                                className={`${styles.orderSummary} w-100 pb-1 ${index === activeOrder?.payments.length - 1 ? 'border-0' : 'border-bottom-dashed'}`}
                                justify='space-between'
                            >
                                <div>
                                    <p>{item?.description}</p>
                                </div>
                                <div>
                                    <p>£{item?.amount}</p>
                                </div>
                            </Flex>
                        ))
                    )}
                </>

            )}
        </div>
    );
}
