import React from 'react'

import styles from './Icon.module.scss'

export default function Icon({ component, size, className, ...rest }) {
    const IconElement = component;
    return (
        <IconElement
            {...rest}
            className={(size === 'large' ? styles.icon : styles.iconAlt) + ' ' + className}
        />
    )
}
