import appApi from "../index";

const endpoint = "auth";

const login = (url = null, email, password) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/login`, { email, password })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const refreshToken = (url = null) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/refresh-token`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
}

const logout = (url = null) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/logout`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const me = (url = null) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/user`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

export default {
    login,
    refreshToken,
    logout,
    me
};