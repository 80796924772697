import { Card, Col, Tooltip, Button, Row, Table, Typography } from 'antd';
import React, { useState, useMemo, useCallback } from 'react';

import styles from "../../Orders.module.scss";

import UpdateOrderItem from './UpdateOrderItem';

const { Text } = Typography;

const columns = [
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price) => "£" + price
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => "£" + record.price * record.qty
    },
];

export default function OrderItems({ setKey, setActiveKey, activeOrder }) {

    const [visibleAddOrder, setVisibleAddOrder] = useState(false);

    const expandedRowRender = useCallback((record) => {
        const expandedColumns = [
            {
                title: 'IMEI No',
                key: 'imei',
                dataIndex: "imei",
                render: (text) => (text ? text : "-"),
            },
            {
                title: 'Service Required',
                dataIndex: 'fault_note',
                key: 'fault_note',
                render: (text) => (text ? text : "-"),
            },
            {
                title: 'Collection Time',
                dataIndex: 'est_collection_time',
                key: 'est_collection_time',
                render: (text) => (text ? text : "-"),
            },
            {
                title: 'Password',
                dataIndex: 'password',
                key: 'password',
                render: (text) => (text ? text : "-"),
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                render: (text) => (text ? text : "-"),
            },
        ];

        return (
            <Table
                columns={expandedColumns}
                dataSource={[record]}
                pagination={false}
                size="small"
                rowKey="key"
            />
        );
    }, []);

    const dataSource = useMemo(() =>
        activeOrder.items.map(item => ({
            key: item?.id ?? 'N/A',
            model: item?.model_name ?? '-',
            imei: item?.imei_no ?? '-',
            fault_note: item?.fault_note ?? '-',
            password: item?.password ?? '-',
            price: item?.unit_price ?? 0,
            qty: item?.quantity ?? 1,
            note: item?.note ?? '-',
            est_collection_time: item?.est_collection_time ?? '-',
        })),
        [activeOrder.items]
    );

    return (
        <>
            <Card
                title={<Text>Order Details</Text>}
                extra={
                    <Tooltip title="Add an Order Item">
                        <Button
                            type='primary'
                            ghost
                            onClick={() => setVisibleAddOrder(true)}
                        >
                            Add an Item
                        </Button>
                    </Tooltip>
                }
            >
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={24}>
                        {activeOrder.items &&
                            <Table
                                key="id"
                                size='small'
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                rowClassName={(record) => (record.strikeThrough ? 'strike-through-row' : '')}
                                expandable={{
                                    expandedRowRender,
                                    rowExpandable: () => true,
                                    defaultExpandedRowKeys: dataSource.length > 0 ? [dataSource[0].key] : [],
                                }}
                                rowKey={(record) => record.key}
                            />
                        }
                    </Col>
                </Row>
            </Card>

            {visibleAddOrder &&
                <UpdateOrderItem
                    visible={visibleAddOrder}
                    handleCancel={() => setVisibleAddOrder(false)}
                    activeOrderId={activeOrder?.id}
                    setKey={setKey}
                    setActiveKey={setActiveKey}
                />
            }
        </>
    )
}
