import React, { useEffect, useRef, useState } from 'react';

import styles from './Chat.module.scss';

import ChatBubble from '../../components/ChatBubble';
import GeminiAiField from '../../components/components/fields/gemini-ai-field';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { getBaseUrlFromStore, getUserDataFromStore } from '../../store/util';

const Chat = ({ title }) => {
    useDocumentTitle(title);
    const bottomRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [timestamp, setTimestamp] = useState(null);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const { email, roles } = getUserDataFromStore();
    const baseUrl = getBaseUrlFromStore();

    return (
        <div className={styles.wrapper}>
            <div className={styles["chat-window"]}>
                <div ref={bottomRef} />
                <div className={["chat-messages"]}>
                    {messages &&
                        messages.length > 0 &&
                        <>
                            {messages.map((message, idx) => (
                                <ChatBubble
                                    key={idx}
                                    message={message.content || message.parts}
                                    sender={message.role}
                                    timestamp={timestamp}
                                />
                            ))}
                        </>
                    }
                </div>
            </div>

            <div className={styles.chatFieldWrapper}>
                <GeminiAiField
                    content={messages}
                    setContent={setMessages}
                    setTimestamp={setTimestamp}
                />
            </div>
        </div>
    );
};

export default Chat;
