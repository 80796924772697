import React from 'react'
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import styles from "../WaitingForConformation.module.scss";

export default function MoreOptions({ index, setIndex, loading }) {
    return (
        <>
            <div className={styles.tableHeader}>
                <Button
                    type="dashed"
                    icon={<SyncOutlined />}
                    loading={loading}
                    onClick={() => setIndex(prevIndex => prevIndex + 1)}
                    className={styles.button}
                >
                    Sync with WFC
                </Button>
            </div>
        </>
    )
}
