import React from 'react'
import { Card, Table, Typography } from 'antd';

const { Title } = Typography;
export default function UnpaidTable({ unPaidPaymentData }) {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Stripe (Card)',
            dataIndex: 'stripe',
            key: 'stripe',
        },
        {
            title: 'Paypal',
            dataIndex: 'paypal',
            key: 'paypal',
        },
        {
            title: 'Klarna',
            dataIndex: 'klarna',
            key: 'klarna',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    return (
        <Card bordered={false} className="criclebox p-20">
            <Title level={4}>Unpaid Orders Status</Title>
            <Table dataSource={unPaidPaymentData} columns={columns} pagination={false} />
        </Card>
    )
}
