import { ref, query, orderByChild, update, remove, push, equalTo } from "firebase/database";

import { db } from "../util/firebase";

const DeliveriesApi = {
    create: (formData) => push(ref(db, 'deliveries'), formData),

    list: (filterColumn = null, filterValue = null) => {
        if (!filterValue || filterValue === 0)
            return query(ref(db, 'deliveries'))

        return query(ref(db, 'deliveries'), orderByChild(filterColumn), equalTo(filterValue));
    },

    update: (id, formData) => update(ref(db, 'deliveries/' + id), formData),
    updateMany: (values) => update(ref(db), values),

    delete: (id) => remove(ref(db, 'deliveries/' + id)),
    deleteAll: () => remove(ref(db, 'deliveries')),
}

export default DeliveriesApi;