import { API_ENDPOINT1 } from "../constants/varibales";

const endpoint = "products";

const ProductApi = {
    listAlias: () => {
        return new Promise((resolve, reject) => {
            fetch(`${API_ENDPOINT1}/${endpoint}/alias/list`)
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(error => {
                    if (error.response) {
                        reject(error.response.data);
                    } else if (error.request) {
                        reject(error);
                    } else {
                        reject(error);
                    }
                })
        })
    },
};

export default ProductApi;