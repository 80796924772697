import React, { useState, useEffect } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message as messageBox,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import styles from "./SignIn.module.scss";

import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useAuth } from "../../contexts/AuthContext";

import { login } from "../../store/slices/auth";
import { clearMessage } from "../../store/slices/message";
import AnimationWrapper from "../../components/AnimationWrapper";

import logo from "../../assets/images/site-logo.svg";

function onChange(checked) {
  //
}

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function SignIn(props) {
  useDocumentTitle(props.title);

  const history = useHistory();
  const { login: loginWithFirebase } = useAuth();
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const onFinish = async (values) => {
    const { email, password } = values;
    try {
      setLoading(true);
      const isFirebaseAuth = await loginWithFirebase(process.env.REACT_APP_FIREBASE_DEFAULT_AUTH_EMAIL, process.env.REACT_APP_FIREBASE_DEFAULT_AUTH_PASSWORD);
      if (isFirebaseAuth) {
        await dispatch(login({ url: undefined, email, password })).unwrap();
        setLoading(false);
        history.push("/dashboard")
        messageBox.success({
          content: 'Login Successfully',
          transitionName: 'move-down',
        });
      }
    } catch (err) {
      setLoading(false);
      messageBox.error({
        content: 'Login Failed',
        transitionName: 'move-down',
      });
    }
  }

  const onFinishFailed = (errorInfo) => {
    //
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand mx-auto">
            <h3>FMBS CRM</h3>
          </div>
        </Header>
        <Content className="signin" style={{ minHeight: '86vh' }}>
          <Row gutter={[24, 0]} justify="space-around" align="middle">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-6">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item label="Email">
                  <Form.Item
                    className="username"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Form.Item>

                <Form.Item label="Password">
                  <Form.Item
                    className="username"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password className={styles.password} placeholder="Password" />
                  </Form.Item>
                </Form.Item>

                <Form.Item label="">
                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked onChange={onChange} />
                    Remember me
                  </Form.Item>
                </Form.Item>

                <Form.Item label="">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>

              {message && <Alert message={message} type="error" showIcon />}
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <AnimationWrapper>
                <img
                  src={logo}
                  className={styles.animationImage}
                  alt="Fixmybrokenscreen.co.uk"
                />
              </AnimationWrapper>
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © {new Date().getFullYear()} FMBS CRM by <a href="https://fixmybrokenscreen.co.uk" target="_blank" rel="noreferrer">Fixmybrokenscreen.co.uk</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  )
}