import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Form,
  DatePicker,
  Select,
  message,
  Input,
  Flex
} from "antd";
import qs from 'qs';
import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import PaypalApi from "../../apis/invoice/PaypalApi";
dayjs.locale('en-gb');

import styles from './Invoice.module.scss';
const { RangePicker } = DatePicker;
const { Option } = Select;

import { useSelector } from 'react-redux';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import SelectedOptions from "./Components/SelectedOptions";
import { SiteOptions } from '../../components/layout/util/common';
import useLogin from '../../hooks/useLogin';
import StatusBadge from '../../components/StatusBadge';
import { __local__ } from '../../constants/varibales';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Europe/London');


const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function Paypal(props) {
  useDocumentTitle(props.title);
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [form] = Form.useForm();

  const [selectedFilter, setSelectedFilter] = useState('name');
  const [inputValue, setInputValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [paypalList, setPaypalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [filterPaypalList, setFilterPaypalList] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_paid: null
  });

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const [visible, setVisible] = useState(false);
  const [activePaypal, setActivePaypal] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  const columns = [
    {
      title: "Number",
      dataIndex: "detail",
      key: "detail",
      render: (detail) => detail?.invoice_number,
    },
    {
      title: "Invoice Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        const shareLink = `https://www.${__local__ ? 'sandbox.' : ''}paypal.com/invoice/p/#${record.id}`;
        return <a href={shareLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>{text}</a>;
      },
    },
    {
      title: "Date",
      dataIndex: "detail",
      key: "detail",
      render: (detail) => {

        return detail?.invoice_date;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => {
        return (<StatusBadge value={value} color={value === "SENT" ? "blue" : "red"} label={value} />);
      },
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (amount) => {

        return amount?.currency_code + " " + amount?.value;
      },
    },
  ];

  const fetchPaypalList = useCallback((isCancelled) => {
    setLoading(true);
    PaypalApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: PaypalList }) => {

        if (!isCancelled) {
          setPaypalList(PaypalList.invoices);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            pagination: {
              ...prevTableParams.pagination,
              current: PaypalList.current_page,
              pageSize: PaypalList.pageSize,
              total: PaypalList.total,
            },
          }));
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, getQueryParams]);


  useEffect(() => {
    let isCancelled = false
    fetchPaypalList(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams)]);

  const showModal = (item) => {
    setVisible(true);
    setActivePaypal(item);
  };


  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setPaypalList([]);
    }
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }
  const pageLoading = () => {
    setKey(prevKey => prevKey + 1)
    setFilterPaypalList(null);
    form.setFieldsValue({
      filterTable: null,
    });
  };

  const handleBaseUrlChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      form.resetFields(); // Reset the filter form
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };

  const handleFilterChange = value => {
    setSelectedFilter(value);
    setInputValue('');
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);

  };
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('filterBy', values.filterBy);
      formData.append('name', values.name);
      formData.append('startDate', startDate ?? null);
      formData.append('endDate', endDate ?? null);

      const filterByType = await PaypalApi.filterByType(baseUrl, formData);
      if (filterByType) {
        setFilterPaypalList(filterByType?.data?.items);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message || 'An error occurred');
    }
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">

              <Card
                bordered={false}
                className={`criclebox tablespace ${styles.table}`}
                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <Row justify="space-between" align="top">
                  <Col md={18} className='d-inline-flex mb-3'>
                    <Button
                      icon={<SyncOutlined />}
                      loading={loading}
                      onClick={pageLoading}
                      className="mr-2 px-2"
                    />
                    <Select
                      value={baseUrl}
                      onChange={handleBaseUrlChange}
                      className="mr-4"
                      style={{ width: 260 }}
                      options={SiteOptions.map(option => ({
                        ...option,
                        disabled: option.id === 3,
                      }))}
                      disabled={loading}
                    />

                  </Col>

                  <Col md={6} className='d-flex mb-3'>
                    <Button.Group>
                      <Link to="/invoice/stripe">
                        <Button key="2">
                          Stripe
                        </Button>
                      </Link>
                      <Link to="/invoice/paypal">
                        <Button key="3" type='primary' ghost>
                          Paypal
                        </Button>
                      </Link>
                      <Link to="/invoice">
                        <Button key="1">
                          All
                        </Button>
                      </Link>
                    </Button.Group>

                    <Link to="/invoice/create">
                      <Button
                        key="1"
                        type="primary"
                        className='ml-3'
                      >
                        Create Invoice
                      </Button>
                    </Link>
                  </Col>

                  <Col md={24}>
                    <Form
                      form={form}
                      onFinish={onFinish}
                    >
                      <Flex>
                        <Form.Item label="Filter By" name="filterBy">
                          <Select
                            placeholder="Select filter"
                            onChange={handleFilterChange}
                            style={{ width: 200 }}
                            defaultValue="name"
                          >
                            <Option value="name">Customer Name</Option>
                            <Option value="invoice">Invoice Number</Option>
                            <Option value="email">Customer Email</Option>
                            <Option value="dateRange">Date Range</Option>
                          </Select>
                        </Form.Item>

                        {selectedFilter !== 'dateRange' && (
                          <Form.Item name="name" rules={[
                            {
                              type: selectedFilter === 'email' ? 'email' : '',
                              message: 'Please enter a valid email address!',
                            },
                            {
                              required: true,
                              message: selectedFilter + ' is required!',
                            },
                          ]}>
                            <Input
                              placeholder={`Enter ${selectedFilter}`}
                              className='mx-4'
                              value={inputValue}
                              style={{ width: 250 }}
                              size='small'
                            />
                          </Form.Item>
                        )}

                        {selectedFilter === 'dateRange' && (
                          <Form.Item name="daterange">
                            <RangePicker
                              className='mx-4'
                              onChange={handleDateChange}
                              format="YYYY-MM-DD"
                            />
                          </Form.Item>
                        )}
                        <Button
                          className={styles.submitBtn}
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          Search
                        </Button>
                      </Flex>
                    </Form>
                  </Col>
                </Row>

                <div className="table-responsive">
                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                    type="paypal"
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    dataSource={filterPaypalList === null ? paypalList : filterPaypalList}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [10, 20, 50, 75, 100],
                      defaultPageSize: tableParams.pagination.pageSize,
                      position: ["bottomRight"],
                      ...tableParams.pagination
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    onChange={handleTableChange}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}