// Orders
const getAllOrderStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "New", status: "purple", color: 'purple' },
    { value: 2, label: "Processing", status: "gold", color: 'gold' },
    { value: 6, label: "WFC", status: "cyan", color: 'cyan' },
    { value: 3, label: "Complete", status: "blue", color: 'blue' },
    { value: 4, label: "Review Requested", status: "success", color: 'green' },
    { value: 5, label: "Archive", status: "error", color: 'red' },
    { value: 7, label: "Cancelled", status: 'default', color: '#ccc' },
    { value: 8, label: "Return", status: "default", color: '#000' },
];

const statusRemovableList = [3, 4, 5, 7];
const filteredOrderStatus = getAllOrderStatus.filter(element => !statusRemovableList.includes(element.value));

// WFC
const getAllWFCStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "Open", status: "error", color: "red" },
    { value: 2, label: "Pending", status: "gold", color: "gold" },
    { value: 3, label: "Offer Send", status: "purple", color: "purple" },
    { value: 4, label: "Send Payment", status: "default", color: "#000" },
    { value: 5, label: "Return", status: "blue", color: "blue" },
    { value: 6, label: "Close", status: "success", color: "green" },
];

// Pay
const getAllPayStatus = [
    { value: 0, label: "Un Paid", color: "error" },
    { value: 1, label: "Paid", color: "success" },
];

// Pay type
const getAllPayType = [
    { value: 1, label: "Bank" },
    { value: 2, label: "Paypal" },
];

// revised offer
const getAllRevisedOfferStatus = [
    { value: 0, label: "New", color: 'purple' },
    { value: 1, label: "Accepted", color: 'green' },
    { value: 2, label: "Rejected", color: 'red' },
    { value: 3, label: "Reminded", color: 'gold' },
    { value: 4, label: "Void", color: 'default' },
];

const getAllConditions = [
    { value: 1, label: "Good" },
    { value: 2, label: "Glass Cracked" },
    { value: 3, label: "Faulty" },
];

const getOrderStatusByValue = (id) => ((getAllOrderStatus.slice(1)).find(({ value }) => (value === id)));
const getWFCStatusByValue = (id) => (getAllWFCStatus.find(({ value }) => (value === id)));
const getPayStatusByValue = (id) => (getAllPayStatus.find(({ value }) => (value === id)));
const getPayTypeByValue = (id) => (getAllPayType.find(({ value }) => (value === id)));
const getRevisedOfferStatusByValue = (id) => (getAllRevisedOfferStatus.find(({ value }) => (value === id)));

export {
    getAllOrderStatus,
    filteredOrderStatus,
    getOrderStatusByValue,
    getAllPayStatus,
    getPayStatusByValue,
    getAllPayType,
    getPayTypeByValue,
    getAllWFCStatus,
    getWFCStatusByValue,
    getAllRevisedOfferStatus,
    getRevisedOfferStatusByValue,
    getAllConditions
};