import { Button, Col, Drawer, Form, Input, InputNumber, message, Row, Select, Space, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import RevisedOfferApi from '../../../../apis/order-management/recycle/RevisedOfferApi';
import ProductApi from '../../../../apis/ProductApi';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { useForm } = Form;

export default function CreateRevisedOffer(
    {
        openCreateRevisedOffer,
        setOpenCreateRevisedOffer,
        selectedRowKeys,
        activeOrder,
        baseUrl,
        setRevisedOfferKey,
        setKey,
        setActiveOrderKey
    }
) {
    const [form] = useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [conditions, setConditions] = useState([]);

    const onCloseRevisedOffer = () => {
        setOpenCreateRevisedOffer(false);
    }

    const preFetch = async () => {
        const productList = await ProductApi.listAlias();
        const conditionList = await RevisedOfferApi.listConditions(baseUrl);
        setProducts(productList);
        setConditions(conditionList);
    }

    useEffect(() => {
        preFetch();
    }, []);

    const orderItems = useMemo(() => {
        return activeOrder.order_items
            .filter(orderItem => selectedRowKeys.includes(orderItem.id))
            .map(item => ({
                id: item.id,
                product_id: item.product_id,
                condition_id: item.condition_id,
                unit_price: item.unit_price,
                quantity: item.quantity
            }));
    }, [activeOrder, selectedRowKeys]);

    useEffect(() => {
        form.setFieldsValue({ reasons: '', order_items: orderItems });
    }, [orderItems]);

    const onFinish = async (values) => {
        setLoading(true);
        values.send_mail_confirmation = true;
        values.status = 0;
        values.recycle_order_id = activeOrder.id;

        try {
            const response = await RevisedOfferApi.createOffer(baseUrl, values);
            setLoading(false);
            form.setFieldsValue({ reasons: '', order_items: orderItems });
            setRevisedOfferKey(prevKey => prevKey + 1);
            setActiveOrderKey(prevKey => prevKey + 1);
            setKey(prevKey => prevKey + 1);
            onCloseRevisedOffer();
            message.success(response.success);
        } catch (error) {
            setLoading(false);
            message.error(error.message);
        }
    }

    return (
        <Drawer
            title="Create Revised Offer"
            placement="left"
            size="large"
            onClose={onCloseRevisedOffer}
            open={openCreateRevisedOffer}
            extra={
                <Space>
                    <Button onClick={onCloseRevisedOffer}>Cancel</Button>
                    <Button
                        type="primary"
                        onClick={form.submit}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </Space>
            }
            destroyOnClose
        >
            {(activeOrder && products && conditions) &&
                <Form
                    {...layout}
                    form={form}
                    id="revised-offer-form"
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    initialValues={{
                        reasons: "",
                    }}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name='reasons'
                                label="Reason for Revised Offer"
                                rules={[{ required: true, message: 'Missing reason' }]}
                            >
                                <TextArea autoSize={{ minRows: 3, maxRows: 15 }} placeholder={"- reason #1"} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Space direction="vertical" span={24}>
                                <Text>Order Items:</Text>
                                <Form.List name='order_items'>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(field => (
                                                <Space key={field.key} align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'id']}
                                                        hidden
                                                    >
                                                        <Input type='hidden' />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'product_id']}
                                                        rules={[{ required: true, message: 'Missing product' }]}
                                                    >
                                                        <Select
                                                            placeholder="Select a product"
                                                            allowClear
                                                            showSearch
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );
                                                            }}
                                                            optionLabelProp="children"
                                                        >
                                                            {products.map(({ id, model }) => (
                                                                <Option title={model} key={model} value={id}
                                                                    id={id}>{model}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'condition_id']}
                                                        rules={[{ required: true, message: 'Missing condition' }]}
                                                    >
                                                        <Select
                                                            placeholder="Select a condition"
                                                        >
                                                            {conditions.map(condition => (
                                                                <Option key={condition.id} value={condition.id}>{condition.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'unit_price']}
                                                        rules={[{ required: true, message: 'Missing unit price' }]}
                                                    >
                                                        <InputNumber
                                                            placeholder="Unit Price"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'quantity']}
                                                        rules={[{ required: true, message: 'Missing quantity' }]}
                                                    >
                                                        <InputNumber
                                                            placeholder="Quantity"
                                                        />
                                                    </Form.Item>
                                                </Space>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
        </Drawer>
    )
}
