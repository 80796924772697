import {
  HomeOutlined
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Row,
  Select,
  message
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";


import { useAuth } from "../../contexts/AuthContext";
import useLogin from '../../hooks/useLogin';
import { logout } from "../../store/slices/auth";
import { SiteOptions, dropdownConditionals } from "./util/common";

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({
  name,
  subName,
  onPress,
  setLoading
}) {
  const history = useHistory();
  let { pathname } = useLocation();

  const { logout: firebaseLogout } = useAuth()
  const dispatch = useDispatch();
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [messageApi, contextHolder] = message.useMessage();

  const menu = {
    items: [
      {
        key: '1',
        label: (
          <Link to="/profile" className="btn-sign-in">
            Profile
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={() => { handleLogout(true); }}>
            Sign Out
          </a>
        ),
      },
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  // useEffect(() => window.scrollTo(0, 0));

  async function handleLogout(isFirebaseLogout = false) {
    try {
      await messageApi
        .open({
          type: 'loading',
          content: 'Logout in progress..',
          duration: 2.5,
        });
      if (isFirebaseLogout) {
        await firebaseLogout();
      }
      await dispatch(logout()).unwrap();
      history.push("/sign-in")
      message.success({
        content: 'Logout Successfully',
        transitionName: 'move-down',
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    catch (err) {
      message.error({
        content: 'Logout Failed',
        transitionName: 'move-down',
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      history.push("/sign-in")
    }
  }

  const handleChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="header-control-alt">
          <Breadcrumb style={{ alignItems: 'center' }}
            items={[
              {
                title: <HomeOutlined />,
                href: "/",
              },
              { title: name },
            ]}
          />
          {dropdownConditionals.some(page => pathname === page) &&
            <Select
              size="middle"
              defaultValue={baseUrl}
              onChange={handleChange}
              style={{ width: 260, marginLeft: '1rem' }}
              options={SiteOptions}
            />
          }
        </Col>
        <Col span={24} md={12} className="header-control">
          <Button
            type="link"
            className="sidebar-togglerr"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Dropdown menu={menu} placement="bottomRight">
            <Button type="default">{profile}</Button>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
