import { Button, Col, Flex, Modal, Row } from 'antd';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import styles from './MediaResourceViewer.module.scss';

import Icon from '../Icon';

const DocumentImage = require('../../assets/images/media-items/google-docs.png');

const MediaResourceViewer = ({ title, url, type, activeData, setActiveData }) => {
    const mediaContent = () => {
        switch (type) {
            case 'video':
                return (
                    <video key={url} width="840" height="480" controls autoPlay>
                        <source src={url} />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'image':
                return (
                    <img src={url} alt={title} className={styles.image} />
                );
            case 'document':
                return (
                    <Flex
                        gap='middle'
                        vertical
                        justify='center'
                        align='center'
                    >
                        <img
                            src={DocumentImage}
                            alt={type}
                            className={styles.imageIcon}
                        />
                        <Button type='primary' href={url} target="_blank" rel="noopener noreferrer">Open Document</Button>
                    </Flex>
                );
            case 'audio':
                return (
                    <audio key={url} controls>
                        <source src={url} />
                        Your browser does not support the audio element.
                    </audio>
                );
            default:
                return (
                    <Flex
                        gap='middle'
                        vertical
                        justify='center'
                        align='center'
                    >
                        <img
                            src={DocumentImage}
                            alt={type}
                            className={styles.imageIcon}
                        />
                        <Button type='primary' href={url} target="_blank" rel="noopener noreferrer">Open Document</Button>
                    </Flex>
                );
        }
    };

    return (
        <Modal
            title={title}
            extra={
                <Icon
                    key="link"
                    component={AiFillCloseCircle}
                    size='small'
                    onClick={() => setActiveData(null)}
                />
            }
            width={1000}
            open={activeData}
            onCancel={() => setActiveData(null)}
            footer={null}
        >
            <Row
                justify='space-between'
                align='middle'
                className='py-4'
            >
                <Col span={24} className="d-inline-flex justify-center align-center">
                    {mediaContent()}
                </Col>
            </Row>
        </Modal>
    );
};

export default MediaResourceViewer;