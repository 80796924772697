import React from 'react';
import {
  Row,
  Col,
  Card,
} from "antd";

import styles from "./Blank.module.scss";

import useDocumentTitle from '../../hooks/useDocumentTitle';

export default function CreateEditForm(props) {

  useDocumentTitle(props.title);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={<>Add New Blank</>}
            >
              {/*  */}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}