import { Button, Card, Col, Form, message, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from "../../Orders.module.scss";

import { getAllOrderStatus } from '../../util/common';
import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Option } = Select;

export default function OrderUpdate({ activeOrder, handleReset, isNavigable, baseUrl, setVisiblePayment, showReceiptPrintModal, setKey }) {
    let history = useHistory();

    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [loadingObj, setLoadingObj] = useState({
        updateOrder: false,
    });

    const onFinish = async (values) => {
        setLoadingObj({ ...loadingObj, updateOrder: true });
        try {
            if (!activeOrder.id)
                throw new Error('Something went wrong!');

            if (parseInt(values.status) === 4) {
                if (parseInt(activeOrder?.due_amount) !== 0) {
                    // open payment modal
                    setVisiblePayment(true);

                    message.warning('Please clear the due amount first!');
                    setLoadingObj({ ...loadingObj, updateOrder: false });
                    return;
                }
            }

            const updatedOrder = await DirectOrdersApi.updateOne(baseUrl, activeOrder.id, {
                status: parseInt(values.status)
            });

            if (updatedOrder) {
                message.success(`${activeOrder?.order_no} - Order is updated!`);
                setKey((prevKey) => prevKey + 1);

                if (parseInt(values.status) === 4) {
                    // open receipt print modal
                    showReceiptPrintModal(true)
                } else {
                    handleReset();
                }

                if (isNavigable) {
                    history.goBack();
                }
            }

            setLoadingObj({ ...loadingObj, updateOrder: false });

        } catch (error) {
            setLoadingObj({ ...loadingObj, updateOrder: false });
            message.error('Something went wrong!');
        }
    }

    return (
        <Card>
            <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    status: parseInt(activeOrder.status)
                }}
            >
                <Row gutter={16} align="middle">
                    <Col span={16}>
                        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                            <Select>
                                {getAllOrderStatus.slice(1).map((item, idx) => (
                                    <Option key={idx} value={item.value} disabled={(activeOrder?.wfc_status && activeOrder?.wfc_status !== 5) || item.value === 2}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={styles.modelBtn}>
                            <Button size='small' className={styles.submitBtn} type="primary" htmlType="submit" loading={loadingObj.updateOrder}>
                                Update
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
