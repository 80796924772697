import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shippingDirectOrder: []
};

const shippingDirectOrderSlice = createSlice({
    name: 'shippingDirectOrder',
    initialState,
    reducers: {
        addShippingDirectOrder: (state, action) => {
            state.shippingDirectOrder.push(action.payload);
        },
        deleteShippingDirectOrder: (state, action) => {
            state.shippingDirectOrder = state.shippingDirectOrder.filter(order => order.id !== action.payload);
        }
    }
});

export const { addShippingDirectOrder, deleteShippingDirectOrder } = shippingDirectOrderSlice.actions;

export default shippingDirectOrderSlice.reducer;
