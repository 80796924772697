import { Table, Typography, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import RevisedOfferApi from '../../../../apis/order-management/recycle/RevisedOfferApi';
import StatusBadge from '../../../../components/StatusBadge';
import { getRevisedOfferStatusByValue } from '../../util/common';
import EditableRow from './Editable/EditableRow';
import EditableCell from './Editable/EditableCell';

const { Text } = Typography;

const defaultColumns = [
  {
    title: 'Reason',
    dataIndex: 'reasons',
    key: 'reasons',
    render: text => <Text style={{ whiteSpace: 'break-spaces' }}>{text}</Text>,
    width: 700
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => {
      const statusObj = getRevisedOfferStatusByValue(value);
      return (<StatusBadge value={value} color={statusObj?.color} label={statusObj?.label} />);
    },
    editable: true,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format('DD/MM/YYYY')}</Text> : "-"),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format('DD/MM/YYYY')}</Text> : "-"),
  },
];

export default function list({ baseUrl, activeOrderId, revisedOfferKey, setRevisedOfferKey, setActiveOrderKey }) {
  const [revisedOffer, setRevisedOffer] = useState([]);
  const [loading, setLoading] = useState(false);

  const preFetch = async () => {
    setLoading(true);
    const revisedOfferList = await RevisedOfferApi.list(baseUrl, { recycle_order_id: activeOrderId });
    setRevisedOffer(revisedOfferList);
    setLoading(false);
  }

  useEffect(() => {
    preFetch();
  }, [revisedOfferKey]);

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
      },
      {
        title: 'Condition',
        dataIndex: 'condition',
        key: 'condition',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
      },
    ];

    return (
      <Table
        size='small'
        className='py-2'
        columns={columns}
        dataSource={record?.items.map((item => ({
          id: item.id,
          model: item.product.model,
          condition: item.condition.name,
          price: '£' + item.unit_price,
          quantity: item.quantity,
          total: '£' + (item.quantity * +item.unit_price)
        })))}
        pagination={false}
      />
    );
  };

  // Table - Editable Cell
  const handleSaveRevisedOfferStatus = async ({ id, status }) => {
    setLoading(true);
    try {
      if (!id)
        throw new Error('Something went wrong!');

      const updatedOrder = await RevisedOfferApi.updateOne(baseUrl, id, {
        status: status,
      });

      if (updatedOrder) {
        message.success(updatedOrder.message ?? 'Updated successfully!');
        setRevisedOfferKey(prevState => prevState + 1);
        setActiveOrderKey(prevState => prevState + 1);
        setLoading(false);
      }
    } catch (e) {
      message.error(e.message ?? 'Something went wrong!');
      setLoading(false);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveRevisedOfferStatus,
      }),
    };
  });

  return (
    <Table
      components={components}
      size='small'
      rowKey={(record) => record.id}
      dataSource={revisedOffer?.length > 0 ? revisedOffer : []}
      columns={columns}
      pagination={false}
      expandable={{
        expandedRowRender,
      }}
      loading={loading}
    />
  )
}
