import { Button, Col, Table, Drawer, Select, Form, Row, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OrdersApi from '../../../apis/order-management/OrdersApi';
import { deleteShippingOrder } from '../../../store/slices/shippingOrdersSlice';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getAllOrderStatus, getOrderStatusByValue } from '../util/common';
import StatusBadge from '../../../components/StatusBadge';
import { ConfirmModal } from '../../../components/ConfirmModal';

const { useForm } = Form;
const { confirm } = Modal;
const { Option } = Select;

const CollectionCreateForm = ({ visible, onCancel, orders, setKey }) => {
    const dispatch = useDispatch();

    const shippingOrders = useSelector((state) => state.shippingOrders.shippingOrders);
    const filterIds = shippingOrders.map(orders => orders.id);
    const filteredOrders = orders?.filter(order => filterIds.includes(order.id));

    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();


    const [selectedItems, setSelectedItems] = useState([]);

    const handleDelete = () => {
        selectedItems.forEach(orderId => {
            dispatch(deleteShippingOrder(orderId));
        });
    };

    const handleOrderStatusUpdate = async (status) => {
        let isGenerateLabel = false;
        if (status === 3) {
            try {
                await ConfirmModal({
                    title: 'Royal mail shipment label',
                    content: 'Do you want to generate the label for royal mail shipment?',
                    onOk: () => {
                        isGenerateLabel = true;
                    },
                    onCancel: () => {
                        // Handle cancel if needed
                    }
                });
            } catch (error) {
                // Handle modal rejection
            }
        }

        let loadingMessage = null;

        try {
            loadingMessage = message.loading('Order is updating..', 0);

            await Promise.all(selectedItems.map(async orderId => {
                await OrdersApi.updateOne(baseUrl, orderId, {
                    status: status,
                    is_active: 1,
                    order_type: 'repair'
                });
            })).then(() => {
                setKey(prevKey => prevKey + 1);
            });

            loadingMessage();

        } catch (error) {
            // Handle error
        } finally {
             if (loadingMessage) {
                loadingMessage();
            }
        }

        if (status === 3 && isGenerateLabel) {
            message.loading('Shipment label is generating..', 0);

            selectedItems.map(async orderId => {
                await OrdersApi.royalMailOrder(baseUrl, orderId);
            })

            selectedItems.forEach(orderId => {
                dispatch(deleteShippingOrder(orderId));
            });

            message.success('Order was exported to Royal Mail!');
        }


        try {
            await ConfirmModal({
                title: 'Remove items label',
                content: 'Are you sure want to remove these items ?',
                onOk: () => {
                    selectedItems.forEach(orderId => {
                        dispatch(deleteShippingOrder(orderId));
                    });
                    message.success(`Order is updated!`);
                    form.resetFields(['status']);
                },
                onCancel: () => {
                    message.success(`Order is updated!`);
                    form.resetFields(['status']);
                }
            });
        } catch (error) {
            // Handle modal rejection
        }
    };

    const handleRoyalMailUpdate = () => {
        confirm({
            title: 'Royal mail shipment label',
            icon: <ExclamationCircleFilled />,
            content: `Do you want to generate the label for royal mail shipment?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                selectedItems.forEach(async orderId => {
                    await OrdersApi.royalMailOrder(baseUrl, orderId);
                });
                form.resetFields(['status']);
            },
            onCancel() {
                form.resetFields(['status']);
                //
            },
        });

    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Order no',
            dataIndex: 'order_no',
            key: 'order_no',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => formatDate(text),
        },
        {
            title: 'Arrived At',
            dataIndex: 'date_of_arrival',
            key: 'date_of_arrival',
            render: (text) => formatDate(text),
        },
        {
            title: 'Full Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Postcode',
            dataIndex: 'postal_code',
            key: 'postal_code',
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (value) => {
                const statusObj = getOrderStatusByValue(value);
                return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
            },
        },
    ];

    const rowSelection = {
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRowKeys);
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Drawer
            open={visible}
            title="Shipping Orders"
            placement='left'
            onClose={onCancel}
            width={1000}
            destroyOnClose
        >
            <Row gutter={24}>
                <Col span={24}>
                    {selectedItems.length > 0 &&
                        <>

                            <div className='d-flex justify-start'>
                                <DeleteOutlined style={{ fontSize: '17px', color: 'red' }} className='mx-3' onClick={handleDelete} />
                                <div style={{ width: '200px' }} className='mr-2'>
                                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

                                        <Form.Item name="status" label="Status" >
                                            <Select onChange={(value) => handleOrderStatusUpdate(value)}>
                                                {getAllOrderStatus.slice(1).map((item, idx) => (
                                                    <Option key={idx} value={item.value} disabled={item.value === 6}>{item.label}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <Button
                                    type="primary"
                                    className="mx-1"
                                    ghost
                                    onClick={handleRoyalMailUpdate}
                                >
                                    Export to Royal Mail
                                </Button>
                                <Button
                                    type="primary"
                                    className="mx-1"
                                    ghost
                                >
                                    Export to DPD
                                </Button>

                            </div>
                        </>
                    }
                    <Table className='mt-2' rowKey={(record) => record.id} rowSelection={rowSelection} dataSource={filteredOrders} columns={columns} pagination={false} />

                </Col>
            </Row>
        </Drawer>
    );
};

const BulkRepairDC = ({ orders, setKey, setVisible, setVisibleBulkAction }) => {
    const [open, setOpen] = useState(false);
    const shippingOrders = useSelector((state) => state.shippingOrders.shippingOrders);

    useEffect(() => {
        if (setVisible) {
            setOpen(true);
        }
    }, [setVisible]);

    return (

        <div>
            {shippingOrders.length > 0 &&
                <Button
                    type="primary"
                    className="mx-2"
                    ghost
                    onClick={() => setOpen(true)}
                >
                    Bulk Actions ({shippingOrders.length})
                </Button>
            }
            <CollectionCreateForm
                visible={open}
                orders={orders}
                setKey={setKey}
                onCancel={() => {
                    setOpen(false);
                    setVisibleBulkAction(false);
                }}
            />
        </div>
    );
};
export default BulkRepairDC;