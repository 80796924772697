import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Upload, message } from 'antd';
import React, { useState } from 'react';
import NotificationTemplateApi from '../../../../apis/order-management/NotificationTemplateApi';

import styles from "../../Orders.module.scss";

import { TicketApi } from '../../../../apis/zendesk/TicketApi';
import { getAllWFCStatus } from '../../util/common';
import GeminiAiField from '../../../../components/components/fields/gemini-ai-field';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Option } = Select;

export default function GenerateTicket({ activeOrder, notificationTemplate, employees, baseUrl, handleReset }) {
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [loadingObj, setLoadingObj] = useState({
        generateTicket: false
    });
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [messages, setMessages] = useState([]);
    const [timestamp, setTimestamp] = useState(null);

    const [uploadFiles, setUploadFiles] = useState([]);
    const [loadingUpload, setLoadingUpload] = useState(false);

    const onTemplateChange = async (value) => {
        const template = await NotificationTemplateApi.getOne(baseUrl, value);
        if (template) {
            setSelectedTemplate(template);
            form.setFieldsValue({
                wfc_status: template.wfc_status ?? 2,
                wfc_note: template?.wfc_note,
                message: template.message ?? ""
            });
        }
    }

    const onReasonChange = async (value) => {
        if (!selectedTemplate) return;
        form.setFieldsValue({
            message: selectedTemplate.message.replaceAll("@_type_the_reason_inside_@", value)
        });
    }

    const onFinishNotificationTemplateFrm = async (values) => {
        setLoadingObj({ ...loadingObj, generateTicket: true });
        const formData = new FormData();
        formData.append('message', values.message);
        formData.append('order_id', activeOrder.id);
        formData.append('template_id', values.template_id);
        formData.append('wfc_status', values.wfc_status);
        formData.append('employee_id', values.employee_id);
        formData.append('wfc_note', values.wfc_note);
        formData.append('upload_tokens', JSON.stringify(uploadFiles.map(item => item.token)));
        formData.append('type', 'recycle');

        try {
            const generateTicket = await NotificationTemplateApi.createTicket(baseUrl, formData);
            if (generateTicket) {
                message.success('Ticket is created!');
                setLoadingObj({ ...loadingObj, generateTicket: false });
                setSelectedTemplate(null);
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            setLoadingObj({ ...loadingObj, generateTicket: false });
            message.error('Something went wrong!');
        }
    };

    const chatGPTContent = (content) => {
        setMessages(content)
        const { id: idOfCustomMail } = notificationTemplate.find(item => item.scenario === "Custom Mail")

        if (content.length > 0) {
            form.setFieldsValue({
                template_id: idOfCustomMail,
                message: content[content.length - 1].content || content[content.length - 1].parts,
                wfc_status: 2
            })
        }
    }

    const uploadButton = (
        <div>
            {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    console.log('uploadFiles', uploadFiles);

    return (
        <>
            {notificationTemplate &&
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={24}>
                        <GeminiAiField
                            content={messages}
                            setContent={chatGPTContent}
                            setTimestamp={setTimestamp}
                        />

                        <Form
                            {...layout}
                            form={form}
                            name="nest-messages"
                            onFinish={onFinishNotificationTemplateFrm}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={[12, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Template"
                                        name="template_id"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) => option.children.join('').toLowerCase().includes(inputValue.toLowerCase())}
                                            onChange={onTemplateChange}
                                            optionLabelProp="children"
                                        >
                                            {notificationTemplate.map((item) => (
                                                <Option key={item.id} value={item.id}>{item.scenario} {(item.instruction_for ? `(${item.instruction_for})` : '')}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {selectedTemplate &&
                                    selectedTemplate.reasons.length > 0 &&
                                    <Col span={12}>
                                        <Form.Item
                                            label="Reason"
                                            name="reason"
                                        >
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) => option.children.join('').toLowerCase().includes(inputValue.toLowerCase())}
                                                onChange={onReasonChange}
                                            >
                                                {selectedTemplate.reasons.map((item) => (
                                                    <Option key={item.id} value={item.message}>{item.message}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }

                                <Col span={24}>
                                    <Form.Item
                                        label="Message"
                                        name="message"
                                        tooltip={{ title: 'Message of notification', icon: <InfoCircleOutlined /> }}
                                        rules={[{ required: true }]}
                                    >
                                        <Input.TextArea
                                            readOnly={selectedTemplate && selectedTemplate.editable !== 1}
                                            autoSize
                                        />
                                    </Form.Item>
                                </Col>

                                {selectedTemplate &&
                                    selectedTemplate.allow_attachments === 1 &&
                                    <Col span={24}>
                                        <Form.Item
                                            label="Attachments"
                                            name="attachments"
                                            valuePropName='filelist'
                                        >
                                            <Upload
                                                listType="picture-card"
                                                multiple
                                                beforeUpload={(file) => {
                                                    const isLt50M = file.size / 1024 / 1024 < 50;
                                                    if (!isLt50M) {
                                                        message.error('A file size must smaller than 50MB!');
                                                    }
                                                    return isLt50M;
                                                }}
                                                customRequest={async ({ file, onSuccess, onError }) => {
                                                    setLoadingUpload(true);
                                                    try {
                                                        const { data: { upload: { token } } } = await TicketApi.upload(file);
                                                        if (token) {
                                                            setUploadFiles(prevTokens => [...prevTokens, { uid: file.uid, token }]);
                                                            onSuccess('ok');
                                                            message.success(`${file.name} uploaded successfully`);
                                                        }
                                                        setLoadingUpload(false);
                                                    } catch (error) {
                                                        message.error(`Error uploading file: ${file.name}`, error);
                                                        onError('error');
                                                        setLoadingUpload(false);

                                                    }
                                                }}
                                                onRemove={async (file) => {
                                                    // const deletableFile = uploadFiles.find(item => item.uid === file.uid);
                                                    // await TicketApi.delete(deletableFile.token);  // ability to delete attachments from Closed tickets
                                                    const newFileList = uploadFiles.filter(item => item.uid !== file.uid);
                                                    setUploadFiles(newFileList);
                                                }}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                }

                                <Col span={6}>
                                    <Form.Item
                                        label="WFC Status"
                                        name="wfc_status"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            optionLabelProp="children"
                                        >
                                            {(getAllWFCStatus.slice(1)).map((item, idx) => (
                                                <Option key={idx} value={item.value}>{item.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        label="handle By"
                                        name="employee_id"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            optionLabelProp="children"
                                        >
                                            {employees.map((item, idx) => (
                                                <Option key={idx} value={item.id}>
                                                    {item.emp_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="WFC Note"
                                        name="wfc_note"
                                        tooltip={{ title: 'Note of wfc', icon: <InfoCircleOutlined /> }}
                                        rules={[{ required: true }]}
                                    >
                                        <Input.TextArea autoSize />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className={styles.modelBtn}>
                                <Button className={styles.submitBtn} type="primary" htmlType="submit" loading={loadingObj.generateTicket}>
                                    Send
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            }
        </>
    )
}
