import qs from 'qs';
import appApi from "../index";
import axios from 'axios';

const endpoint = "orders/evidence";

const list = (url = null, order_type) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/list/${order_type}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const getFiles = (url = null, order_type, id) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/get-files/${order_type}/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const makeWrite = (url = null, order_type, id, formData) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/write/${order_type}/${id}`, formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const uploadOne = (url = null, order_type, id, formData, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/upload-one/${order_type}/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const uploadMany = (url = null, order_type, id, formData, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/upload-many/${order_type}/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const generatePresignedUrl = (url = null, order_type, id, fileType) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/generate-presigned-url/${order_type}/${id}`, { params: { file_type: fileType } })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
}

const uploadToS3 = (presignedUrl, file, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        axios
            .put(presignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
}

const deleteDirectory = (url = null, order_type, id) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .delete(`/${endpoint}/delete/directory/${order_type}/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const deleteFile = (url = null, formData) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .delete(`/${endpoint}/delete/file`, { data: formData })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const deleteFiles = (url = null, formData) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .delete(`/${endpoint}/delete/files`, { data: qs.stringify(formData) })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

const downloadFile = (fileUrl, filename) => {
    return axios
        .get(fileUrl, {
            responseType: 'blob',
            timeout: 8000,
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('An error occurred while downloading the file', error);
        });
}

export default {
    list,
    getFiles,
    makeWrite,
    uploadOne,
    uploadMany,
    generatePresignedUrl,
    uploadToS3,
    deleteDirectory,
    deleteFile,
    deleteFiles,
    downloadFile
};