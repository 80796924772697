import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { StyleProvider } from '@ant-design/cssinjs';

import { store } from "./store";
import App from "./App";

import 'antd-css-utilities/utility.min.css';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <StyleProvider hashPriority="low">
        <App />
      </StyleProvider>
    </BrowserRouter>
  </Provider>
);
