import React from 'react'
import dayjs from 'dayjs';

export default function ExpandedRowRender({ record }) {
    return (
        <>
            {
                <div>
                    <p style={{ margin: 0 }}>Arrival: {record.dateOfArrival && dayjs(record.dateOfArrival).format('llll')}</p>
                    <p style={{ margin: 0 }}>Dispatch: {record.dateOfDispatch && dayjs(record.dateOfDispatch).format('llll')}</p>
                    <p style={{ margin: 0 }}>Label: {record.label}</p>
                    <p style={{ margin: 0 }}>Note: {record.note}</p>
                    <p style={{ margin: 0 }}>Cancelled: {record.cancelled}</p>
                </div>
            }
        </>
    )
}
