import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, DatePicker, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';



import { useSelector } from "react-redux";
import WorkforceApi from '../../../apis/WorkforceApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeEmployee, handleReset, designation, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activeEmployee) {
            form.setFieldsValue({
                emp_name: activeEmployee?.emp_name,
                designation_id: activeEmployee?.designation_id,
                join_at: activeEmployee?.join_at ? dayjs(activeEmployee.join_at) : null,
            });
        }
    }, [activeEmployee, form]);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('emp_name', values.emp_name);
            formData.append('designation_id', values.designation_id);
            formData.append('join_at', dayjs(values.join_at).format('YYYY-MM-DD'));

            if (activeEmployee?.id) {
                formData.append('id', activeEmployee.id);
            }

            try {

                const updateEmployee = await WorkforceApi.createEmployee(baseUrl, formData);
                if (updateEmployee) {
                    message.success('Employee is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeEmployee.id]);

        try {
            const deleteEmployee = await WorkforceApi.deleteEmployee(baseUrl, formData);
            if (deleteEmployee) {
                message.success('Employee is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeEmployee.emp_name} - ${activeEmployee.emp_slug}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }



    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Employee Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeEmployee?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeEmployee && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>

                            <Col span={8}>
                                <Form.Item name="emp_name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Name' />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="designation_id" label="Designation" rules={[{ required: true }]}>

                                    <Select
                                        optionLabelProp="children"
                                    >
                                        {(designation?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="join_at" label="Join_at" rules={[{ required: true }]}>
                                    <DatePicker format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
