import React from 'react'
import { Card, Col, Row, Typography } from 'antd'

import styles from '../dashboard.module.scss'

import Loading from '../../../components/loading'

export default function CountCard({ c, loading, isAdmin }) {
    const { Title, Text } = Typography;

    return (
        <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
        >
            <Card bordered={false} className="criclebox">
                <div className="number">
                    {!loading ?
                        <>
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span className={styles.heading}>{c.heading}</span>
                                    <Title className={styles.subHeading}>{c.subHeading}</Title>
                                    {c.persent &&
                                        isAdmin &&
                                        <Title level={5} className='mt-0 mb-1'>
                                            <span className='mr-3 text-dark'>{c.title}</span> <small className={c.bnb}>{c.persent}</small>
                                        </Title>
                                    }
                                    {c.sum &&
                                        c.sum > 0 &&
                                        isAdmin &&
                                        <Text level={5} className={styles.amount}>£{c.sum}</Text>
                                    }
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box">{c.icon}</div>
                                </Col>
                            </Row>
                            {c.footerText &&
                                <Row justify='space-between' align='middle' className='mt-2 mb-1'>
                                    <Col>
                                        <Text className={styles.footer}>{c.footerText.left}</Text>
                                    </Col>
                                    <Col>
                                        <Text className={styles.footer}>{c.footerText.right}</Text>
                                    </Col>
                                </Row>
                            }
                        </>
                        :
                        <Loading />
                    }
                </div>
            </Card>
        </Col>
    )
}
