import React, { useMemo } from 'react'
import { Button, Col, List, Row, Typography } from 'antd';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';
import { getPayStatusByValue } from '../../util/common';
import StatusBadge from '../../../../components/StatusBadge';
import PayTypeBadgeAlt from '../../../../components/PayTypeBadgeAlt';

const { Text } = Typography;

export default function MoreInfo({ activeOrder }) {
    function addressText(obj) {
        return `${obj.address}, ${obj.address_alt ? obj.address_alt : ""}${(obj?.address_alt && obj?.address_alt.length === 0) ? "" : ", "} ${obj.postal_code},  ${obj.city},  ${obj?.country?.name}.`;
    }

    const statusObj = getPayStatusByValue(activeOrder.is_paid);

    const payInfo = useMemo(() => {
        if (activeOrder?.payment_details?.paypal_username) {
            return [
                {
                    keyText: 'Paypal Username',
                    value: activeOrder?.payment_details?.paypal_username,
                    valueCopyable: true,
                }
            ];
        }

        return [
            {
                keyText: 'Account Holder',
                value: activeOrder?.payment_details?.account_name,
                valueCopyable: true,
            },
            {
                keyText: 'Account Number',
                value: activeOrder?.payment_details?.account_no,
                valueCopyable: true,
            },
            {
                keyText: 'Sort Code',
                value: activeOrder?.payment_details?.sort_code,
                valueCopyable: true,
            },
        ]
    }, [activeOrder?.payment_details]);

    return (
        <Row gutter={16} className={styles.model_card_row}>
            <Col span={8}>
                <List
                    size="small"
                    header={<Text>Summery</Text>}
                    bordered
                    dataSource={[
                        <ListItem key={0}
                            keyText="Order # : "
                            valueText={activeOrder.order_no} valueCopyable />,
                        <ListItem key={1}
                            keyText="Payment method : "
                            valueText={<div className="d-flex">
                                <StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} />
                                <PayTypeBadgeAlt type={!activeOrder?.payment_details.paypal_username ? 'bank' : 'paypal'} />
                            </div>}
                        />,
                        ...payInfo.map((item, index) => (
                            <ListItem
                                key={index}
                                keyText={item.keyText + " : "}
                                valueText={item.value}
                                valueCopyable={item.valueCopyable}
                            />
                        ))
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={8}>
                <List
                    size="small"
                    header={<Text>Contact</Text>}
                    bordered
                    dataSource={[
                        <ListItem key={0}
                            keyText="Name : "
                            valueText={`${activeOrder?.user?.first_name} ${activeOrder?.user?.last_name ? activeOrder.user.last_name : ""}`}
                            valueCopyable
                        />,
                        <ListItem key={1}
                            keyText="E-Mail : "
                            valueText={activeOrder?.user?.email} valueCopyable
                        />,
                        <ListItem key={2}
                            keyText="Phone : "
                            valueText={activeOrder?.user?.phone_no} valueCopyable
                        />,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={8}>
                <List
                    size="small"
                    header={<Text>Shipping Address</Text>}
                    bordered
                    dataSource={[
                        ...addressText(activeOrder?.user).split(', '),
                    ]}
                    renderItem={item => (
                        <List.Item>
                            <ListItem
                                key={0}
                                valueText={item}
                                valueCopyable={(typeof item === 'string') && (activeOrder?.user?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                            />
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    )
}
