import appApi from "../index";

const endpoint = "service";

const getServices = (url = null) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/get-services`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

export default {
    getServices,
};
