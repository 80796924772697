import React from 'react'
import dayjs from 'dayjs';
import { Table } from 'antd';

export default function ExpandedRowRender({ record }) {

    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Unit Price',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <a>£{(+text).toFixed(2)}</a>,
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Amount',
            render: (_, record) => <a>£{(record.amount * +record.qty).toFixed(2)}</a>,
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
    ];

    return (
        <>
            {
                <div>
                    <p style={{ margin: 0 }}>Devices:</p>
                    <Table size="small" columns={columns} dataSource={record.devices} pagination={false} />
                    <br />
                    <p style={{ margin: 0 }}>Created At: {record.created_at && dayjs(record.created_at).format('llll')}</p>
                    <p style={{ margin: 0 }}>Updated At: {record.updated_at && dayjs(record.updated_at).format('llll')}</p>
                    <p style={{ margin: 0 }}>WFC Note: {record.note}</p>
                    <p style={{ margin: 0 }}>Order Note: {record.order_note}</p>
                </div>
            }
        </>
    )
}
