import { Button, Col, Table, Drawer, Select, Form, Row, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OrdersApi from '../../../apis/order-management/recycle/RecycleOrdersApi';
import { deleteShippingOrder } from '../../../store/slices/shippingOrdersRecycleSlice';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getAllOrderStatus, getOrderStatusByValue } from '../../Orders/util/common';
import StatusBadge from '../../../components/StatusBadge';
import { ConfirmModal } from '../../../components/ConfirmModal';

const { useForm } = Form;
const { confirm } = Modal;
const { Option } = Select;

const CollectionCreateForm = ({ visible, onCancel, orders, setKey }) => {
    const dispatch = useDispatch();

    const shippingOrders = useSelector((state) => state.shippingOrdersRecyle?.shippingOrdersRecycle);
    const filterIds = shippingOrders.map(orders => orders.id);
    const filteredOrders = orders?.filter(order => filterIds.includes(order.id));

    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();

    const [selectedItems, setSelectedItems] = useState([]);

    const handleDelete = () => {
        selectedItems.forEach(orderId => {
            dispatch(deleteShippingOrder(orderId));
        });

    };

    const handleOrderStatusUpdate = async (status) => {
 

        let loadingMessage = null;

        try {
            loadingMessage = message.loading('Order is updating..', 0);

            await Promise.all(selectedItems.map(async orderId => {
                await OrdersApi.updateOne(baseUrl, orderId, {
                    status: status,
                    is_active: 1,
                    order_type: 'recycle'
                });
            })).then(() => {
                setKey(prevKey => prevKey + 1);
            });

            loadingMessage();

        } catch (error) {
            // Handle error
        } finally {
             if (loadingMessage) {
                loadingMessage();
            }
        }
        try {
            await ConfirmModal({
                title: 'Remove items label',
                content: 'Are you sure want to remove these items ?',
                onOk: () => {
                    selectedItems.forEach(orderId => {
                        dispatch(deleteShippingOrder(orderId));
                    });
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                },
                onCancel: () => {
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                }
            });
        } catch (error) {
            // Handle modal rejection
        }
    };

    const handleReturnUpdate = async () => {
        try {
            await confirm({
                title: 'Return order label',
                icon: <ExclamationCircleFilled />,
                content: `Are you sure to return this order?`,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                async onOk() {
                    await Promise.all(selectedItems.map(async orderId => {
                        await OrdersApi.markAsReturn(baseUrl, orderId);
                    })).then(() => {
                        confirm({
                            title: 'Remove items label',
                            content: 'Are you sure want to remove these items ?',
                            onOk: () => {
                                selectedItems.forEach(orderId => {
                                    dispatch(deleteShippingOrder(orderId));
                                });
                                message.success(`Order is updated!`);
                                setKey(prevState => prevState + 1); 
                                form.resetFields(['status']);
                            },
                            onCancel: () => {
                                message.success(`Order is updated!`);
                                setKey(prevState => prevState + 1); 
                                form.resetFields(['status']);
                            }
                        });
                    });

                },
                onCancel() {
                    //
                },
            });
        } catch (error) {
            // Handle modal rejection
        }

    };

    const handleUnPaidUpdate = () => {
        confirm({
            title: 'Unpaid order label',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to unpaid this order?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                await Promise.all(selectedItems.map(async orderId => {
                    await OrdersApi.setAsUnPaid(baseUrl, orderId);
                })).then(() => {
                    confirm({
                        title: 'Remove items label',
                        content: 'Are you sure want to remove these items ?',
                        onOk: () => {
                            selectedItems.forEach(orderId => {
                                dispatch(deleteShippingOrder(orderId));
                            });
                            message.success(`Order is updated!`);
                            setKey(prevState => prevState + 1); 
                            form.resetFields(['status']);
                        },
                        onCancel: () => {
                            message.success(`Order is updated!`);
                            setKey(prevState => prevState + 1); 
                            form.resetFields(['status']);
                        }
                    });
                });

            },
            onCancel() {
                //
            },
        });

    };

    const handleCompleteUpdate = () => {
        confirm({
            title: 'Complete order label',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to complete this order?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {

                await Promise.all(selectedItems.map(async orderId => {
                    await OrdersApi.markAsCompleted(baseUrl, orderId);
                })).then(() => {
                    confirm({
                        title: 'Remove items label',
                        content: 'Are you sure want to remove these items ?',
                        onOk: () => {
                            selectedItems.forEach(orderId => {
                                dispatch(deleteShippingOrder(orderId));
                            });
                            message.success(`Order is updated!`); 
                            form.resetFields(['status']);
                            setKey(prevState => prevState + 1);
                        },
                        onCancel: () => {
                            message.success(`Order is updated!`);
                            setKey(prevState => prevState + 1); 
                            form.resetFields(['status']);
                        }
                    });
                });

            },
            onCancel() {
                //
            },
        });
 
    };

    const handleReminderUpdate = () => {
        selectedItems.forEach(async orderId => {
            await OrdersApi.sendOrderReminder(baseUrl, orderId);
        });

        try {
            confirm({
                title: 'Remove items label',
                content: 'Are you sure want to remove these items ?',
                onOk: () => {
                    selectedItems.forEach(orderId => {
                        dispatch(deleteShippingOrder(orderId));
                    });
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                },
                onCancel: () => {
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                }
            });
        } catch (error) {
            // Handle modal rejection
        }

 
    };

    const handleResendUpdate = () => {
        selectedItems.forEach(async orderId => {
            await OrdersApi.sendResendOrderConfirmation(baseUrl, orderId);
        });
        try {
            confirm({
                title: 'Remove items label',
                content: 'Are you sure want to remove these items ?',
                onOk: () => {
                    selectedItems.forEach(orderId => {
                        dispatch(deleteShippingOrder(orderId));
                    });
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                },
                onCancel: () => {
                    message.success(`Order is updated!`); 
                    form.resetFields(['status']);
                }
            });
        } catch (error) {
            // Handle modal rejection
        }

 
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Order no',
            dataIndex: 'order_no',
            key: 'order_no',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => formatDate(text),
        },
        {
            title: 'Full Name',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (_, { user }) => {
                let fullName = `${user.first_name} ${user.last_name ? user.last_name : ""}`;
                return fullName
            },
        },
        {
            title: 'Postcode',
            dataIndex: 'postal_code',
            key: 'postal_code',
            render: (_, { user }) => {
                let postal_code = `${user.postal_code}`;
                return postal_code
            },
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (value) => {
                const statusObj = getOrderStatusByValue(value);
                return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
            },
        },
    ];

    const rowSelection = {
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRowKeys);
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Drawer
            open={visible}
            title="Shipping Orders"
            placement='left'
            onClose={onCancel}
            width={1000}
            destroyOnClose
        >
            <Row gutter={24}>
                <Col span={24}>
                    {selectedItems.length > 0 &&
                        <>

                            <div className='d-flex justify-start'>

                                <DeleteOutlined style={{ fontSize: '17px', color: 'red' }} className='mx-3' onClick={handleDelete} />
                                <div style={{ width: '200px' }} className='mr-2'>
                                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

                                        <Form.Item name="status" label="Status" >
                                            <Select onChange={(value) => handleOrderStatusUpdate(value)}>
                                                {getAllOrderStatus.slice(1).map((item, idx) => (
                                                    <Option key={idx} value={item.value} disabled={item.value === 6}>{item.label}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <Button
                                    type="dashed"
                                    className="mx-1"
                                    danger
                                    onClick={handleResendUpdate}
                                >
                                    Resend Order Re. Mail
                                </Button>
                                <Button
                                    type="primary"
                                    className="mx-1"
                                    ghost
                                    onClick={handleReminderUpdate}
                                >
                                    Send Order Reminder
                                </Button>
                                <Button
                                    type="primary"
                                    className="mx-1"
                                    danger
                                    ghost
                                    onClick={handleReturnUpdate}
                                >
                                    Mark as Return
                                </Button>

                                <Button
                                    type="primary"
                                    className="mx-1"
                                    danger
                                    ghost
                                    onClick={handleCompleteUpdate}
                                >
                                    Mark as Completed
                                </Button>
                                <Button
                                    type="primary"
                                    className="mx-1"
                                    danger
                                    onClick={handleUnPaidUpdate}
                                >
                                    Set as Unpaid
                                </Button>

                            </div>
                        </>
                    }
                    <Table className='mt-2' rowKey={(record) => record.id} rowSelection={rowSelection} dataSource={filteredOrders} columns={columns} pagination={false} />

                </Col>
            </Row>
        </Drawer>
    );
};

const BulkRecycleDC = ({ orders, setKey, setVisible, setVisibleBulkAction }) => {

    const [open, setOpen] = useState(false);
    const shippingOrders = useSelector((state) => state.shippingOrdersRecyle?.shippingOrdersRecycle);

    useEffect(() => {
        if (setVisible) {
            setOpen(true);
        }
    }, [setVisible]);

    return (

        <div>
            {shippingOrders.length > 0 &&
                <Button
                    type="primary"
                    className="mx-2"
                    ghost
                    onClick={() => setOpen(true)}
                >
                    Bulk Actions ({shippingOrders.length})
                </Button>
            }
            <CollectionCreateForm
                visible={open}
                orders={orders}
                onCancel={() => {
                    setOpen(false);
                    setVisibleBulkAction(false);
                }}
                setKey={setKey}
            />
        </div>
    );
};
export default BulkRecycleDC;