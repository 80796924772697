import React, { useMemo, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Typography, DatePicker } from "antd";
import CenteredSpin from "../../../components/loading/CenteredSpin";

const { RangePicker } = DatePicker;
const { Title, Text, Paragraph } = Typography;

const EChart = ({ salesData, dateRange, handleDateChange }) => {
    const extractValues = useCallback((key) => {
        return salesData?.monthSales?.data?.map(item => item[key]) || [];
    }, [salesData]);

    const extractedValues1 = useMemo(() => extractValues('a'), [extractValues]);
    const extractedValues2 = useMemo(() => extractValues('b'), [extractValues]);
    const extractedValues3 = useMemo(() => extractValues('c'), [extractValues]);
    const extractedDates = useMemo(() => extractValues('y'), [extractValues]);

    const eChart = useMemo(() => ({
        series: [
            {
                name: "Paid Orders (£)",
                data: extractedValues1,
                color: "#fff", // Example color
            },
        ],
        options: {
            chart: {
                type: "bar",
                width: "100%",
                height: "auto",
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    borderRadius: 5,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
                colors: ["transparent"],
            },
            grid: {
                show: true,
                borderColor: "#ccc",
                strokeDashArray: 2,
            },
            xaxis: {
                categories: extractedDates,
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: "#fff",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: "#fff",
                    },
                },
            },
            tooltip: {
                enabled: true,
                followCursor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return `<div class="apexcharts-tooltip-custom p-15">
                                ${w.config.series[seriesIndex].name}: ${w.config.series[seriesIndex].data[dataPointIndex]}<br/>
                                Paid Count: ${extractedValues2[dataPointIndex] || 0}<br/>
                                Unpaid Count: ${extractedValues3[dataPointIndex] || 0}
                            </div>`;
                },
            },
        },
    }), [extractedValues1, extractedValues2, extractedValues3, extractedDates]);

    return (
        <Card bordered={false} className="criclebox mt-4">
            <div className="linechart">
                <div>
                    <Title level={5}>Sales Revenue</Title>
                    <Paragraph className="lastweek">
                        {dateRange.length === 2 && dateRange.every(date => date && date.isValid()) && (
                            <Text>
                                {`${dateRange[0].format('YYYY/MM/DD')} - ${dateRange[1].format('YYYY/MM/DD')}`}
                            </Text>
                        )}
                    </Paragraph>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <RangePicker
                            value={dateRange}
                            onChange={handleDateChange}
                            format="YYYY-MM-DD"
                        />
                    </div>
                </div>
            </div>
            <div id="chart">
                <ReactApexChart
                    className="bar-chart"
                    options={eChart.options}
                    series={eChart.series}
                    type="bar"
                    height={220}
                />
            </div>
        </Card>
    );
};

export default EChart;
