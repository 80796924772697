import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, message, Drawer, List, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import ListItem from '../../Orders/Components/ListItem';
import LogsApi from '../../../apis/order-management/LogsApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { confirm } = Modal;

const { Text } = Typography;
const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeLogs, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };
    const baseUrl = useSelector((state) => state.auth.base_url)

    const [form] = Form.useForm();

    const handleDeleteUser = async () => {
        try {
            const deleteLogs = await LogsApi.deleteOne(baseUrl, activeLogs.id);
            if (deleteLogs) {
                message.success('Logs is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }

    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeLogs.userOrName} - ${activeLogs.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="logs-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeLogs?.userOrName}</Col>

                    <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={700}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeLogs && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col> 
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="logs-update"
                name="nest-messages"
                validateMessages={validateMessages}
                initialValues={{
                    status: activeLogs?.sales_status ? parseInt(activeLogs.sales_status) : null,
                }}
            >
                <Row gutter={16} >
                    <Col span={14}>
                        <List
                            size="small"
                            header={<Text>Logs Details</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Log Name : "
                                    valueText={`${activeLogs?.log_name}`}
                                    valueCopyable
                                />,
                                <ListItem key={1}
                                    keyText="Description : "
                                    valueText={`${activeLogs?.description}`}
                                    valueCopyable
                                />,
                                <ListItem key={2}
                                    keyText="Subject Type : "
                                    valueText={`${activeLogs?.subject_type ?? ""}`}
                                    valueCopyable
                                />,
                                <ListItem key={3}
                                    keyText="UserName : "
                                    valueText={`${activeLogs?.user?.name}`}
                                    valueCopyable
                                />,
                                <ListItem key={4}
                                    keyText="created at : "
                                    valueText={dayjs.tz(activeLogs?.no_of_devices, "Europe/London").format("ddd, MMM DD, YYYY hh:mm A")}
                                    valueCopyable
                                />,

                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                </Row>
            </Form>
        </Drawer>
    )
}
