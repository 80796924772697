import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { getCurrentEmail, getCurrentPwd } from "../components/layout/util/common";
import { login } from "../store/slices/auth";

const useLogin = (setLoading) => {
  const dispatch = useDispatch();

  const handleChangeLogin = useCallback(async (value) => {
    setLoading(true);
    let currentPwd = getCurrentPwd();
    let emailAddress = getCurrentEmail(value);

    try {
      const auth = await dispatch(login({ url: value, email: emailAddress, password: currentPwd })).unwrap();
      if (auth) {
        setLoading(false);
        return { success: true, message: 'Login Successfully' };
      }
    } catch (e) {
      setLoading(false);
      return { success: false, message: 'Login Failed' };
    }
  }, [dispatch]);


  return {
    handleChangeLogin,
  };
}

export default useLogin;