import { configureStore } from '@reduxjs/toolkit'

import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import shippingOrdersReducer from './slices/shippingOrdersSlice';
import shippingOrdersRecycleReducer from './slices/shippingOrdersRecycleSlice';
import shippingDirectOrderReducer from './slices/shippingDirectOrderSlice';

const reducer = {
    auth: authReducer,
    shippingOrders: shippingOrdersReducer,
    shippingOrdersRecyle: shippingOrdersRecycleReducer,
    shippingDirectOrder: shippingDirectOrderReducer,
    message: messageReducer
}

export const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_ENV !== 'production',
});