import appApi from "../index";

const endpoint = "invoicing";

const create = (url = null, pay_type, formData) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/create-invoice/${pay_type}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
};

export default {
    create
};