import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';



import { useSelector } from "react-redux";
import UserApi from '../../../apis/UserApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeUser, handleReset, role, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };
    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activeUser) {
            form.setFieldsValue({
                name: activeUser?.name,
                email: activeUser?.email,
                roles: activeUser?.roles?.map(role => role.name),
            });
        }
    }, [activeUser, form]);

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('roles', values.roles);
            formData.append('password', values.password);

            if (activeUser?.id) {
                formData.append('id', activeUser.id);
            }

            try {
                const updateUser = await UserApi.createUser(baseUrl, formData);
                if (updateUser) {
                    message.success('User is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeUser.id]);

        try {
            const deleteUser = await UserApi.deleteUser(baseUrl, formData);
            if (deleteUser) {
                message.success('User is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeUser.name} - ${activeUser.email}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }



    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>User Form</Text>


                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeUser?.id ? "Update" : "Create"}
                        </Button>
                    </div>

                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={750}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeUser && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>

                <Col span={23}>
                    <Row gutter={[24, 0]}>

                        <Col span={8}>
                            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                <Input name="name" placeholder="Enter Name" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                                <Input name="email" placeholder="Enter Email" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="roles"
                                label="Roles"
                                rules={[{ required: true, message: 'Please select at least one role' }]}
                            >
                                <Select
                                    mode="tags"
                                    placeholder="Select or enter roles"
                                    style={{ width: '100%' }}
                                >
                                    {role.map(role => (
                                        <Select.Option key={role?.id} value={role?.name}>
                                            {role?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {!activeUser?.id && (
                            <>
                                <Col span={8}>
                                    <Form.Item 
                                        name="password" 
                                        label="Password" 
                                        rules={[{ required: true, message: 'Please enter your password' }]}
                                    >
                                        <Input.Password name="password" placeholder="Enter Password" />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item 
                                        name="confirm_password" 
                                        label="Confirm Password" 
                                        rules={[
                                            { required: true, message: 'Please confirm your password' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password name="confirm_password" placeholder="Confirm Password" />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        
                    </Row>
                </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
