import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Progress, Upload } from 'antd';
import { useLocation } from 'react-router-dom';

import EvidenceApi from '../../apis/order-management/EvidenceApi';
import { getValidOrderType } from '../../helpers/getBaseUrlByOrderId';

const { Dragger } = Upload;

const MediaUpload = ({ id, baseUrl }) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const { pathname } = useLocation();

    return (
        <Dragger
            name='file'
            multiple={false}
            beforeUpload={async (_, fileList) => {
                // const formData = new FormData();
                // fileList.forEach((file) => {
                //     formData.append('files[]', file);
                // });

                // EvidenceApi.uploadMany(baseUrl, id, formData, (progressEvent) => {
                //     setUploadProgress(0);
                //     const { loaded, total } = progressEvent;
                //     const percentage = Math.floor((loaded * 100) / total);
                //     setUploadProgress(percentage);
                // })
                //     .then(response => {
                //         if (response.success) {
                //             message.success(`Files uploaded successfully.`);
                //         } else {
                //             message.error(`Files upload failed.`);
                //         }
                //     })
                //     .catch(error => {
                //         message.error(`file upload failed.`);
                //     })

                // const file = fileList[0];
                // EvidenceApi.generatePresignedUrl(baseUrl, id, file)
                //     .then(response => {
                //         if (response.success) {
                //             const { url } = response;
                //             console.log('Presigned URL:', url);
                //             EvidenceApi.uploadToS3(url, file, (progressEvent) => {
                //                 setUploadProgress(0);
                //                 const { loaded, total } = progressEvent;
                //                 const percentage = Math.floor((loaded * 100) / total);
                //                 setUploadProgress(percentage);
                //             })
                //                 .then(response => {
                //                     console.log('Upload to S3:', response);
                //                     if (response.success) {
                //                         message.success(`File uploaded successfully.`);
                //                     } else {
                //                         message.error(`File upload failed.`);
                //                     }
                //                 })
                //                 .catch(error => {
                //                     message.error(`File upload failed.`);
                //                 })
                //         } else {
                //             message.error(`File upload failed.`);
                //         }
                //     })
                //     .catch(error => {
                //         message.error(`File upload failed.`);
                //     })

                try {
                    let orderType = getValidOrderType(pathname.split('/')[1]);
                    if (!orderType) {
                        return;
                    }

                    const file = fileList[0];
                    let regex = /(?:\.([^.]+))?$/;
                    let fileType = regex.exec(file.name)[1];

                    setUploadProgress(0);
                    const { data: responseData, success: successOfPreSignUrl } = await EvidenceApi.generatePresignedUrl(baseUrl, orderType, id, fileType);
                    if (successOfPreSignUrl) {
                        const response = await EvidenceApi.uploadToS3(responseData.url, file, (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            const percentage = Math.floor((loaded * 100) / total);
                            setUploadProgress(percentage);
                        });
                        if (response) {
                            message.success('File uploaded successfully.');
                        } else {
                            message.error('File upload failed.');
                        }
                    } else {
                        message.error('Temporary upload url failed.');
                    }
                }
                catch (error) {
                    message.error('Temporary upload url failed, Something went wrong.');
                }

                // Prevent the default upload
                return false;
            }}
            onChange={(info) => {
                const { status } = info.file;
                if (status !== 'uploading') {
                    // console.log(info.file, info.fileList);
                }
            }}
            onDrop={(e) => {
                // console.log('Dropped files', e.dataTransfer.files);
            }}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
            </p>

            {uploadProgress > 0 && <div className='py-4 px-6'><Progress percent={uploadProgress} /></div>}
        </Dragger>
    );
}
export default MediaUpload;
