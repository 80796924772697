import { Button, Modal } from "antd";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import { PhoneOutlined, WhatsAppOutlined, PrinterOutlined } from "@ant-design/icons";

import styles from "./ReceiptPrint.module.scss";

import logo from "../../assets/images/site-logo.png";

const Receipt = React.forwardRef(function ReceiptComponent({ activeOrder }, ref) {

  return (
    <div
      ref={ref}
      className={styles.printContainer}
    >
      <div className="text-center">
        <img
          src={logo}
          alt="logo"
          width='150px'
          className={[styles.logo, 'mb-1'].join(' ')}
        />
      </div>
      <h4 className="text-center">Fixmybrokenscreen.Co.Uk LTD</h4>
      <p className="text-center mb-1">
        23 Whitechapel Road, London, E1 1DU.
      </p>

      <div className="d-flex justify-between items-center flex-wrap mb-2">
        <p className="text-center"><PhoneOutlined /> 020 8064 2362</p>
        <p className="text-center"><WhatsAppOutlined /> 078 288 03645</p>
      </div>

      <div className={styles.smallText}>
        <div className="d-flex justify-between items-center flex-wrap">
          <p className="mb-1">Order No. {activeOrder?.order_no}</p>
          <p className="mb-1">Date: {new Date(activeOrder?.created_at).toLocaleDateString()}</p>
        </div>
        <p className="mb-1">Customer: {activeOrder?.user?.customer_name}</p>
      </div>
      <hr />

      {activeOrder?.items.map((item, index) => (
        <div key={index} >
          <p>{item?.model_name}</p>
          <div className="d-flex justify-between align-start">
            <p>{item?.fault_note || item?.quantity + ' X ' + parseFloat(item?.unit_price).toFixed(2)}</p>
            <p>£{(parseFloat(item?.quantity) * parseFloat(item?.unit_price)).toFixed(2)}</p>
          </div>

          {item?.est_collection_time &&
            <div className={[styles.smallText, 'd-flex justify-start'].join(' ')}>
              <p className="pr-1">Collection Time:</p>
              <p>{item.est_collection_time}</p>
            </div>
          }

          {item?.note &&
            <div className={[styles.smallText, 'd-flex justify-start'].join(' ')}>
              <p className="pr-1">Note:</p>
              <p>{item.note}</p>
            </div>
          }
          <hr />
        </div>
      ))}

      <div className="d-flex justify-between">
        <p>Total:</p>
        <p>£{activeOrder?.repair_cost}</p>
      </div>
      <div className="d-flex justify-between">
        <p>Deposit Amount:</p>
        <p>£{activeOrder?.deposit_amount}</p>
      </div>
      <div className="d-flex justify-between">
        <p>Due Balance:</p>
        <p>£{activeOrder?.due_amount}</p>
      </div>
      <hr />

      <p className="text-center mt-4">Thank you for choosing us. <br /><span className="font-semibold">Fixmybrokenscreen.Co.Uk</span></p>
      <div className="text-center my-3">
        <QRCode value={activeOrder?.order_no} size={50} />
      </div>
    </div>
  );
});

const ReceiptPrint = ({ activeOrder, isModalVisible, showModal, handleCancel, btnEnable }) => {
  const receiptRef = useRef();

  return (
    <>
      {btnEnable &&
        <Button type="primary" className="mr-1" onClick={showModal}>
          <PrinterOutlined /> Receipt
        </Button>
      }
      <Modal
        title="Receipt Preview"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        width="350px"
      >
        <ReactToPrint
          bodyClass="d-flex justify-content-center"
          trigger={() => (
            <Button
              type="primary"
              className="mt-3"
              ghost
            >
              <PrinterOutlined /> Print
            </Button>
          )}
          content={() => receiptRef.current}
        />
        <Receipt ref={receiptRef} activeOrder={activeOrder} />
      </Modal>
    </>
  );
};

export default ReceiptPrint;