import { PageHeader } from '@ant-design/pro-layout';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, PrinterTwoTone } from "@ant-design/icons";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from '../Deliveries.module.scss';

import DeliveriesApi from '../../../apis/DeliveriesApi';
import PostcodeApi from '../../../apis/PostcodeApi';
import ProductApi from '../../../apis/ProductApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { checkExtensionInstalled, doPrint } from '../../../util/doPrint';
import { getAllActualStatus, getAllSource, getStatusByValue } from '../util/common';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};

export default function CreateEditForm({ location, title, history }) {
  const [form] = Form.useForm();

  useDocumentTitle(title);
  const validateMessages = {
    required: '${label} is required!',
  };

  const [formOptions, setFormOptions] = useState({
    sources: getAllSource.map(item => ({ value: item })),
    devices: null,
    postcodes: null
  });
  const [loading, setLoading] = useState(false)
  const [loadingObj, setLoadingObj] = useState({
    testPrintLabel: false,
    printLabel: false
  });

  useEffect(() => {
    const fetchData = async () => {
      const products = await ProductApi.listAlias();
      setFormOptions(prevOptions => ({
        ...prevOptions,
        devices: products ? products.map(product => ({ label: product.model, value: product.alias })) : null,
      }));
    };

    fetchData();

    return () => {
      setFormOptions(prevOptions => ({
        ...prevOptions,
        devices: null,
      }));
    };
  }, []);


  const getPostcodeAutocomplete = async (searchText) => {
    if (!searchText) return;
    const { result } = await PostcodeApi.autocomplete(searchText);
    if (!result) return;

    if (typeof result === 'object') {
      setFormOptions(prevFormOptions => ({
        ...prevFormOptions,
        postcodes: result.map(r => ({ value: r })),
      }));
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (location.state) {
        await DeliveriesApi.update(location.state.key, {
          source: values.source,
          userOrName: values.userOrName,
          repairService: values.repairService,
          device: values.device,
          dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival, "MM-DD-YYYY Z").valueOf() : null,
          dateOfDispatch: values.dateOfDispatch ? dayjs(values.dateOfDispatch, "MM-DD-YYYY Z").valueOf() : null,
          postcode: values.postcode,
          note: values.note ? values.note : null,
          label: values.label,
          cancelled: values.cancelled ? values.cancelled : null,
          status: values.status.value ? values.status?.value : values.status
        });

        setLoading(false);
        message.success(`${values.userOrName} Delivery is updated`);
        history.goBack();
        return;
      }

      const delivery = await DeliveriesApi.create({
        source: values.source,
        userOrName: values.userOrName,
        repairService: values.repairService,
        device: values.device,
        dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival, "MM-DD-YYYY Z").valueOf() : null,
        dateOfDispatch: values.dateOfDispatch ? dayjs(values.dateOfDispatch, "MM-DD-YYYY Z").valueOf() : null,
        postcode: values.postcode,
        note: values.note ? values.note : null,
        label: values.label,
        cancelled: values.cancelled ? values.cancelled : null,
        status: values.status.value ? values.status?.value : values.status
      });

      if (delivery) {
        message.success('Delivery is added');
        if (values.isPrintable) {
          await printAFile(3, 'printLabel');
        }
        onReset();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong!');
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const printAFile = async (copies = 1, loadingState = 'testPrintLabel') => {
    if (!checkExtensionInstalled()) return;

    setLoadingObj({
      ...loadingObj,
      [loadingState]: true
    });

    try {
      await doPrint({
        source: form.getFieldValue('source'),
        dArrival: dayjs(form.getFieldValue('dateOfArrival')).format("DD-MM-YYYY"),
        username: form.getFieldValue('userOrName') + ' / ' + form.getFieldValue('repairService'),
        device: form.getFieldValue('device'),
        qrCode: form.getFieldValue('userOrName')
      }, copies);

      message.success('Label is printed!');
      setLoadingObj({
        ...loadingObj,
        [loadingState]: false
      });
    }
    catch (e) {
      message.error(e.message ?? 'Label printing is failed, something went wrong.');
      setLoadingObj({
        ...loadingObj,
        [loadingState]: false
      });
    }
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[8, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">
              <PageHeader
                ghost={false}
                className="site-page-header"
                onBack={() => history.goBack()}
                title={`${location.state ? "Edit" : "Add New"} Delivery`}
              />

              <Card
                bordered={false}
                className={`criclebox tablespace mb-24 ${styles.space}`}
              >
                <Form
                  {...layout}
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    source: location.state ? location.state.source : null,
                    userOrName: location.state ? location.state.userOrName : null,
                    repairService: location.state ? location.state.repairService : null,
                    device: location.state ? location.state.device : null,
                    dateOfArrival: location.state ? dayjs.tz(location.state.dateOfArrival, "Europe/London") : dayjs(),
                    dateOfDispatch: location.state ? (location.state.dateOfDispatch ? dayjs.tz(location.state.dateOfDispatch, "Europe/London") : null) : null,
                    postcode: location.state ? location.state.postcode : null,
                    note: location.state ? location.state.note : null,
                    label: location.state ? location.state.label : 'L',
                    cancelled: location.state ? location.state.cancelled : null,
                    status: location.state ? getStatusByValue(location.state.status) : getStatusByValue(1),
                    isPrintable: true
                  }}
                >
                  <Row gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item label="Source" name="source" rules={[{ required: true }]}>
                        <AutoComplete
                          allowClear
                          options={formOptions.sources}
                          filterOption
                        />
                      </Form.Item>
                    </Col>

                    <Col span={16} className='border pt-2 mb-4'>
                      <Row gutter={[24, 0]}>
                        <Col span={12}>
                          <Form.Item
                            label="Order Id / User Name"
                            name="userOrName"
                            rules={[{ required: true, message: 'Order Id / User Name is required!' }]}
                          >
                            <Input size='small' />
                          </Form.Item>
                          <Text type="secondary" strong>Eg: OR5411234</Text>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Repair Service"
                            name="repairService"
                            rules={[{ required: true, message: 'Repair Service is required!' }]}
                          >
                            <Input size='small'/>
                          </Form.Item>
                          <Text type="secondary" strong>Eg: FG + LCD + RG + Other</Text>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Device" name="device" rules={[{ required: true }]}>
                        <AutoComplete
                          allowClear
                          options={formOptions.devices}
                          placeholder="Select device"
                          loading={!formOptions.devices}
                          showSearch
                          filterOption
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Date of Arrival" name="dateOfArrival" rules={[{ required: true }]}>
                        <DatePicker format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Date of Dispatch" name="dateOfDispatch">
                        <DatePicker format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Post Code" name="postcode" rules={[{ required: true }]}>
                        <AutoComplete
                          allowClear
                          options={formOptions.postcodes}
                          onSearch={getPostcodeAutocomplete}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Note" name="note">
                        <Input.TextArea />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Label" name="label" rules={[{ required: true }]}>
                        <Select>
                          {['L', 'S', 'E', 'D'].map((item, idx) => (
                            <Select.Option key={idx} value={item}>{item}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Cancelled" name="cancelled" tooltip={{ title: 'Order of cancel note', icon: <InfoCircleOutlined /> }}>
                        <Input.TextArea />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                        <Select allowClear>
                          {getAllActualStatus.map((item, idx) => (
                            <Option key={idx} value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="isPrintable"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      Would you like to print the label once the record has been created?
                    </Checkbox>
                  </Form.Item>

                  <Form.Item label=" " wrapperCol={{ ...layout.wrapperCol }}>
                    <Button
                      className={styles.submitBtn}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                    <Button
                      type="dashed"
                      danger
                      onClick={() => printAFile()}
                      loading={loadingObj.testPrintLabel}
                      className={styles.submitBtn}
                    >
                      <PrinterTwoTone twoToneColor='#ff4d4f' />
                      Test
                    </Button>
                    <Button>
                      <Link to="/deliveries">Cancel</Link>
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}