import React from 'react'
import { FaCcPaypal } from 'react-icons/fa';
import { AiTwotoneBank } from "react-icons/ai";

import styles from './PayTypeBadge.module.scss';

export default function PayTypeBadgeAlt({ type }) {
    return (
        <>
            {type === 'paypal' ? <FaCcPaypal className={styles.icon_paypal} /> : (type === 'bank' ? <AiTwotoneBank className={styles.icon_klarna} /> : '-')}
        </>
    )
}
