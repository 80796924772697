import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Card, Col, Descriptions, Dropdown, message, Modal, Row, Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import DeliveriesApi from '../../../apis/DeliveriesApi';
import QuickEdit from './QuickEdit';
import StatusBadge from './StatusBadge';

import styles from "../Deliveries.module.scss";

const { confirm } = Modal;

const renderContent = (item) => (
    <Descriptions size="default" column={3}>
        <Descriptions.Item label="Source" className={styles.content}>{item?.source}</Descriptions.Item>
        <Descriptions.Item label="User/Name" className={styles.content}>{item?.userOrName}</Descriptions.Item>
        <Descriptions.Item label="Device" className={styles.content}>{item?.device}</Descriptions.Item>
        <Descriptions.Item label="Date of Arrival" className={styles.content}>{item?.dateOfArrival ? dayjs(item?.dateOfArrival).format("DD MMM YYYY hh:mm a") : "-"}</Descriptions.Item>
        <Descriptions.Item label="Date of Dispatch" className={styles.content}>{item?.dateOfDispatch ? dayjs(item?.dateOfDispatch).format("DD MMM YYYY hh:mm a") : "-"}</Descriptions.Item>
        <Descriptions.Item label="Postal Code" className={styles.content}>{item?.postcode}</Descriptions.Item>
        <Descriptions.Item label="Note" className={styles.content}>
            {item?.note}
        </Descriptions.Item>
        <Descriptions.Item label="Label" className={styles.content}>{item?.label}</Descriptions.Item>
        <Descriptions.Item label="Cancelled" className={styles.content}>
            {item?.cancelled}
        </Descriptions.Item>
        <Descriptions.Item label="Status" className={styles.content}><StatusBadge status={item?.status} /></Descriptions.Item>
    </Descriptions>
);

const Content = ({ children, extra }) => (
    <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
    </div>
);

export default function SingleView(props) {

    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleReset = () => {
        setVisible(false);
    }

    const handleCancel = () => {
        setVisible(false);
    };

    const items = [
        {
            label: 'Edit',
            key: '1',
            icon: <EditOutlined />,
            onClick: () => (
                props.history.push({
                    pathname: `/deliveries/edit/${props.location?.state.key}`,
                    state: props.location?.state
                })
            )
        },
        {
            label: 'Delete',
            key: '2',
            icon: <DeleteOutlined />,
            onClick: () => showConfirm(props.location?.state)
        },
    ];

    const handleDeleteUser = async (item) => {
        try {
            await DeliveriesApi.delete(item.id);
            message.success(`${item.userOrName} Delivery is deleted`);
            props.history.goBack();
        } catch (error) {
            message.error('Something went wrong!');
        }
    }
    const showConfirm = (item) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${item.userOrName} - ${item.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser(item);
            },
            onCancel() {
                //
            },
        });
    };

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <div className="site-page-header-ghost-wrapper">
                            <PageHeader
                                ghost={false}
                                className="site-page-header"
                                onBack={() => props.history.goBack()}
                                title="Delivery Details"
                                extra={[
                                    <Button key="1" type="primary" onClick={showModal}>
                                        Quick Edit
                                    </Button>,
                                    <Dropdown key="more" menu={{ items }} placement="bottomRight">
                                        <Button size='large' type="text" icon={<MoreOutlined style={{ fontSize: 20 }} />} />
                                    </Dropdown>,
                                ]}
                                footer={
                                    <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                            {
                                                key: 1,
                                                label: 'Details',
                                            }
                                        ]}
                                    />
                                }
                            />
                            <Card
                                bordered={false}
                                className={`criclebox tablespace mb-24 ${styles.space}`}
                            >
                                <Content>{renderContent(props.location?.state)}</Content>
                            </Card>
                        </div>
                    </Col>
                </Row>

                <QuickEdit
                    visible={visible}
                    handleCancel={handleCancel}
                    activeDelivery={props.location?.state}
                    handleReset={handleReset}
                    isNavigable
                />

            </div>
        </>
    )
}
