import axios from "axios";

import { ZENDESK_PASSWORD, ZENDESK_TOKEN, ZENDESK_USERNAME } from "../../constants/varibales";

const subdomain = "fixmybrokenscreen";
const host = "zendesk";

const axiosInstance = axios.create({
  baseURL: `https://${subdomain}.${host}.com/api/v2`,
  auth: {
    username: `${ZENDESK_USERNAME}/${ZENDESK_TOKEN}`,
    password: ZENDESK_PASSWORD,
  }
});

export const TicketApi = {
  upload: (file) => {
    let regex = /(?:\.([^.]+))?$/;
    let fileType = regex.exec(file.name)[1];

    return axiosInstance.post(`/uploads?filename=${file.uid}.${fileType}`, file, {
      headers: {
        "Content-Type": "application/binary",
      },
    });
  },
  delete: (token) => {
    return axiosInstance.delete(`/uploads/${token}`);
  }
}