import React from 'react';
import LazyLoad from 'react-lazyload';
import { Image } from 'antd';

const LazyImage = ({ src, alt, width, height, placeholder, ...rest }) => {
  return (
    <LazyLoad
      height={height}
      offset={100}
      placeholder={<Image preview={true} src={placeholder} alt={alt} style={{ width, height }} />}>
      <Image
        src={src}
        alt={alt}
        style={{ width, height, objectFit: 'cover', objectPosition: 'center' }}
        {...rest}
      />
    </LazyLoad>
  );
};

export default LazyImage;
