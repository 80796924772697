import appApi from "../../index";
import qs from 'qs';

const endpoint = "direct-orders";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const createOrder = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createNewPayment = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/create-new-payment`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const createNewItem = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/create-new-item`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const updateOne = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const getStatusCounts = (url = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-status/counts`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOneWithOrderRepair = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-one/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const ItemsChecker = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/items-checker/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


export default {
  list,
  updateOne,
  ItemsChecker,
  createOrder,
  createNewPayment,
  createNewItem,
  getOne,
  deleteOne,
  getOneWithOrderRepair,
  getStatusCounts,
};
