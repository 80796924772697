import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, message, InputNumber, Space, AutoComplete } from 'antd'
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from "../WaitingForConformation.module.scss";

import { getAllActualStatus, getAllSource, getStatusByValue } from '../util/common'
import WaitingForConformationApi from '../../../apis/WaitingForConformationApi';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeWaitingForConfirmation, handleReset, isNavigable, employees, roles }) {
    let history = useHistory();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();
    const [formOptions, setFormOptions] = useState({
        sources: getAllSource.map(item => ({ value: item })),
        devices: []
    });
    const [loading, setLoading] = useState(false);

    const handleDeleteUser = async () => {
        try {
            await WaitingForConformationApi.delete(activeWaitingForConfirmation.key);
            message.success(`${activeWaitingForConfirmation.userOrName} Waiting For Confirmation is deleted`);
            handleReset();
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeWaitingForConfirmation.userOrName} - ${activeWaitingForConfirmation.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await WaitingForConformationApi.update(activeWaitingForConfirmation.key, {
                source: values.source ? values.source : null,
                userOrName: values.userOrName ? values.userOrName : null,
                dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival, "MM-DD-YYYY Z").valueOf() : null,
                note: values.note ? values.note : null,
                order_note: values.order_note ? values.order_note : null,
                devices: values.devices ? values.devices : null,
                handle_by: values.handle_by.value ? values.handle_by?.value : values.handle_by,
                status: values.status.value ? values.status?.value : values.status
            });

            message.success(`${activeWaitingForConfirmation?.userOrName} Waiting For Confirmation is updated`);
            setLoading(false);
            handleReset();

            if (isNavigable) {
                history.goBack();
            }

        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Modal
            title={activeWaitingForConfirmation?.userOrName}
            open={visible}
            onOk={onFinish}
            confirmLoading={loading}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeWaitingForConfirmation && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                    <Col>
                        <Button
                            className={styles.submitBtn}
                            form='waiting-for-conformation-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Update
                        </Button>
                        <Button
                            key="cancel"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            ]}
            destroyOnClose
        >
            <Form
                {...layout}
                form={form}
                id="waiting-for-conformation-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    source: activeWaitingForConfirmation?.source ? activeWaitingForConfirmation.source : null,
                    userOrName: activeWaitingForConfirmation?.userOrName ? activeWaitingForConfirmation.userOrName : null,
                    dateOfArrival: activeWaitingForConfirmation?.dateOfArrival ? dayjs.tz(activeWaitingForConfirmation.dateOfArrival, "Europe/London") : null,
                    note: activeWaitingForConfirmation?.note ? activeWaitingForConfirmation.note : null,
                    order_note: activeWaitingForConfirmation?.order_note ? activeWaitingForConfirmation.order_note : null,
                    devices: activeWaitingForConfirmation?.devices ? activeWaitingForConfirmation.devices : null,
                    handle_by: activeWaitingForConfirmation?.handle_by ? activeWaitingForConfirmation.handle_by : null,
                    status: activeWaitingForConfirmation?.status ? getStatusByValue(activeWaitingForConfirmation.status) : getStatusByValue(1),
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item label="Source">
                            <Form.Item name="source" rules={[{ required: true }]}>
                                <AutoComplete
                                    allowClear
                                    options={formOptions.sources}
                                    filterOption
                                >
                                    <Input />
                                </AutoComplete>
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="User/Name">
                            <Form.Item name="userOrName" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="date of Arrival">
                            <Form.Item name="dateOfArrival">
                                <DatePicker format="DD-MM-YYYY" />
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Status">
                            <Form.Item name="status" rules={[{ required: true }]}>
                                <Select allowClear>
                                    {getAllActualStatus.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Handle">
                            <Form.Item name="handle_by" rules={[{ required: true }]}>
                                <Select allowClear>
                                    {employees.map((item) => (
                                        <Option key={item.id} value={item.emp_name}>{item.emp_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Note">
                            <Form.Item name="note">
                                <Input.TextArea autoSize />
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Order Note">
                            <Form.Item name="order_note">
                                <Input.TextArea autoSize />
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <p style={{ marginBottom: 8, }}>Devices:</p>
                        <Form.List name="devices">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align="start"
                                        >
                                            <Form.Item label="">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'model']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing model',
                                                        },
                                                    ]}
                                                    label=""
                                                >
                                                    <Input placeholder="Model Name" />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label="">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'service']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing service',
                                                        },
                                                    ]}
                                                    label=""
                                                >
                                                    <Input placeholder="Service Name" />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label="">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'amount']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing amount',
                                                        },
                                                    ]}
                                                    label=""
                                                >
                                                    <InputNumber size="large" placeholder="Amount" />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label="">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing qty',
                                                        },
                                                    ]}
                                                    label=""
                                                >
                                                    <InputNumber size="large" min={1} placeholder="Qty" />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label="">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'note']}
                                                    label=""
                                                >
                                                    <Input.TextArea placeholder="Note" autoSize />
                                                </Form.Item>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button size='middle' shape="round" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>

                </Row>
            </Form>
        </Modal>
    )
}
