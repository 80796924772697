const getAllStatus = [
    { value: 0, label: "All", color: "" },
    { value: 1, label: "New", color: "purple" },
    { value: 2, label: "Processing", color: "gold" },
    { value: 3, label: "Complete", color: "blue" },
    { value: 7, label: "Cancel", color: "#ccc" },
    { value: 8, label: "Return", color: "#000" },
];

const getStatusByValue = (id) => (getAllStatus.find(({ value }) => (value === id)));

const getAllActualStatus = getAllStatus.slice(1);

export {
    getAllStatus,
    getStatusByValue,
    getAllActualStatus,
};