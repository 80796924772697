import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  AutoComplete,
  message,
  Space,
  InputNumber
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from '../WaitingForConformation.module.scss';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import WaitingForConformationApi from '../../../apis/WaitingForConformationApi';
import { getAllActualStatus, getAllSource, getStatusByValue } from '../util/common';
import EmployeeApi from '../../../apis/EmployeeApi';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};

export default function CreateEditForm({ location, title, history }) {
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);

  useDocumentTitle(title);

  const validateMessages = {
    required: '${label} is required!',
  };

  const [formOptions, setFormOptions] = useState({
    sources: getAllSource.map(item => ({ value: item })),
    devices: []
  });
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    EmployeeApi.list()
      .then((employeeList) => {
        setEmployees(employeeList);
      })
      .catch(err => {
        //
      });
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {

      if (location.state) {
        await WaitingForConformationApi.update(location.state.key, {
          source: values.source,
          userOrName: values.userOrName,
          handle_by: values.handle_by,
          dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival).format("MM-DD-YYYY Z").valueOf() : null,
          note: values.note ? values.note : null,
          order_note: values.order_note ? values.order_note : null,
          status: values.status.value ? values.status?.value : values.status,
          devices: values.devices ? values.devices : null
        });

        setLoading(false);
        message.success(`${values.userOrName} Waiting For Confirmation is updated`);
        history.goBack();
        return;
      }

      const delivery = await WaitingForConformationApi.create({
        source: values.source,
        userOrName: values.userOrName,
        handle_by: values.handle_by,
        dateOfArrival: values.dateOfArrival ? dayjs(values.dateOfArrival, "MM-DD-YYYY Z").valueOf() : null,
        note: values.note ? values.note : null,
        order_note: values.order_note ? values.order_note : null,
        status: values.status.value ? values.status?.value : values.status,
        devices: values.devices ? values.devices : null
      });

      setLoading(false);
      if (delivery) {
        message.success('Waiting for Confirmation is added');
        onReset();
      }
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong!');
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">
              <PageHeader
                ghost={false}
                className="site-page-header"
                onBack={() => history.goBack()}
                title={`${location.state ? "Edit" : "Add New"} - Waiting for Confirmation`}
              />
              <Card
                bordered={false}
                className={`criclebox tablespace mb-24 ${styles.space}`}
              >
                <Form
                  {...layout}
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    source: location.state ? location.state.source : null,
                    userOrName: location.state ? location.state.userOrName : null,
                    handle_by: location.state ? location.state.handle_by : null,
                    dateOfArrival: location.state ? dayjs.tz(location.state.dateOfArrival, "Europe/London") : dayjs(),
                    note: location.state ? location.state.note : null,
                    order_note: location.state ? location.state.order_note : null,
                    status: location.state ? getStatusByValue(location.state.status) : getStatusByValue(1),
                    devices: location.state ? location.state.devices : [{}],
                  }}

                >
                  <Row gutter={[24, 0]}>
                    <Col span={6}>
                      <Form.Item label="Source">
                        <Form.Item name="source" rules={[{ required: true }]}>
                          <AutoComplete
                            allowClear
                            options={formOptions.sources}
                            filterOption
                          >
                            <Input />
                          </AutoComplete>
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="User/Name">
                        <Form.Item name="userOrName" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Date of Arrival">
                        <Form.Item name="dateOfArrival" >
                          <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Status">
                        <Form.Item name="status" rules={[{ required: true }]}>
                          <Select allowClear>
                            {getAllActualStatus.map((item, idx) => (
                              <Option key={idx} value={item.value}>{item.label}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Handle By">
                        <Form.Item name="handle_by" rules={[{ required: true }]}>
                          <Select allowClear>
                            {employees.map((item) => (
                              <Option key={item.id} value={item.emp_name}>{item.emp_name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Note">
                        <Form.Item name="note">
                          <Input.TextArea />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Order Note">
                        <Form.Item name="order_note">
                          <Input.TextArea />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <p style={{ marginBottom: 8, }}>Devices:</p>
                      <Form.List name="devices">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 8,
                                }}
                                align="start"
                              >
                                <Form.Item label="">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'model']}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing model',
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Model Name" />
                                  </Form.Item>
                                </Form.Item>
                                <Form.Item label="">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'service']}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing service',
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Service Name" />
                                  </Form.Item>
                                </Form.Item>
                                <Form.Item label="">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'amount']}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing amount',
                                      },
                                    ]}
                                  >
                                    <InputNumber size="large" placeholder="Amount" />
                                  </Form.Item>
                                </Form.Item>
                                <Form.Item label="">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'qty']}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing qty',
                                      },
                                    ]}
                                  >
                                    <InputNumber size="large" min={1} placeholder="Qty" />
                                  </Form.Item>
                                </Form.Item>
                                <Form.Item label="">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'note']}
                                    label=""
                                  >
                                    <Input.TextArea placeholder="Note" />
                                  </Form.Item>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}
                            <Form.Item label="">
                              <Button size='middle' shape="round" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add field
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>

                  <Form.Item label="" wrapperCol={{ ...layout.wrapperCol }}>
                    <Button className={styles.submitBtn} type="primary" htmlType="submit" loading={loading}>
                      Submit
                    </Button>
                    <Button>
                      <Link to="/waiting-for-conformation">Cancel</Link>
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}