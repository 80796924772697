import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthApi from "../../apis/auth/userApi";

const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));
const base_url = JSON.parse(localStorage.getItem("base_url"));

export const login = createAsyncThunk(
    "auth/login",
    async ({ url, email, password }, thunkAPI) => {
        try {
            const { data: responseData } = await AuthApi.login(url, email, password);
            if (responseData) {
                localStorage.setItem("user", JSON.stringify(responseData.user));
                localStorage.setItem("token", JSON.stringify(responseData.token));
                localStorage.setItem("base_url", JSON.stringify(responseData.base_url));
            }
            return { user: responseData.user, token: responseData.token, base_url: responseData.base_url };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async (url = null) => {
    await AuthApi.logout(url);
});

const initialState = user
    ? { isLoggedIn: true, user, token: token, base_url: base_url }
    : { isLoggedIn: false, user: null, token: null, base_url: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.base_url = action.payload.base_url;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.base_url = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.base_url = null;
        },
    },
});

const { reducer } = authSlice;
export default reducer;