import slugify from 'slugify';

const upperCase = (str) => {
    return str ? str.toUpperCase() : str;
}

const lowerCase = (str) => {
    return str ? str.toLowerCase() : str;
}

const sentenceCase = (str) => {
    return str ? str[0].toUpperCase() + str.slice(1) : str;
}

const titleCase = (str) => {
    return str ? str.split(' ').map(function (s) { return s.charAt(0).toUpperCase() + s.substring(1); }).join(' ') : str;
}

const slug = (str, options) => {
    return str ? slugify(str, options || { replacement: '-', lower: true }) : str;
}

const parseNum = (str) => {
    if (!str)
        return 0;
    if (typeof str == "number")
        return str;
    if (typeof str.replace == 'function')
        //eslint-disable-next-line
        str = Number(str.replace(/[^\d\.-]/g, ''));
    return parseFloat(str);
}

/**
 * @param  {any} arrayOrObject
 * @param  {Function} callback
 */
function loop(arrayOrObject, callback) {
    if (Array.isArray(arrayOrObject)) {
        const arr_1 = arrayOrObject;
        arr_1.map(function (value, index) {
            callback(value, index, arr_1);
        });
    }
    else if (typeof arrayOrObject === "object") {
        var obj_1 = arrayOrObject;
        const arr = Object.keys(obj_1);
        arr.map(function (key, index) {
            callback(obj_1[key], key, obj_1);
        });
    }
    else if (typeof arrayOrObject === "function") {
        var obj_2 = Object(arrayOrObject);
        const arr = Object.keys(obj_2);
        arr.map(function (key, index) {
            callback(obj_2[key], key, obj_2);
        });
    }
}

function textSlice(text, limit) {
    return text.length < limit ? text : text.slice(0, limit) + '...';
}

function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

function makeTitle(slug) {
    var words = slug.split('-');

    for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
}

function hasObjects(obj) {
    return obj && Object.keys(obj).length > 0;
}

function convertStringToNumber(stringValue) {
    let stringWithoutComma = stringValue.replace(/,/g, ''); // Remove commas
    let stringWithoutDecimal = stringWithoutComma.split('.')[0]; // Remove decimal part
    return Number(stringWithoutDecimal + stringWithoutComma.split('.')[1]); // Concatenate and convert to number
}

export { upperCase, lowerCase, sentenceCase, titleCase, slug, parseNum, loop, textSlice, copyToClipboard, makeTitle, hasObjects, convertStringToNumber };